import styled from 'styled-components';
import { Breakpoints, Colors } from 'theme';
import { Button } from 'components';
import { PageWrapper } from 'shared/styles';
import { Grid, colors } from '@material-ui/core';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    flex-direction: column;
  }
`;

export const Wrapper = styled(PageWrapper)` 
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    background: #FFF;
  }
`;

export const ContentWrapperInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
`;

export const SideWrapper = styled.div`
  flex-basis: 30%;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    flex-basis: unset;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const MainWrapper = styled.div`
  flex-basis: 65%;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    flex-basis: unset;
    width: 100%;
  }
`;

export const CancelarButton = styled(Button)`
  margin-left: auto;

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 12px;
  }
`;

export const ContentLoading = styled.div`
  background-color: rgb(255, 255, 255, 0.1);  
  display: flex;
  justify-content: center;
  align-items: center;  
  position: relative;
  height: 100%;
  width: 100%;
`;

export const MenuEstaticoMobile = styled(Grid)`
  margin-bottom: 1rem;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    margin-bottom: 0;
    border-top: 1px solid ${Colors.lighterGrey};
    position: fixed;
    bottom: 0;
    right: 0;
    background: #FFF;
    height: 130px;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 1rem;
  }
`;

export const DetalhesButton = styled(Button)`
  margin-left: 1rem;

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 0.8rem;
  }
`