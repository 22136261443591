import styled from 'styled-components'
import { Breakpoints } from 'theme'
import { PageWrapper } from 'shared/styles'

export const Wrapper = styled(PageWrapper)`
  @media (max-width: ${Breakpoints.maxSmall}) {  
    padding-bottom: 11rem;
  } 
`

export const ContentWrapperInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  
`
