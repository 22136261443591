import querystring from "querystring";
import differenceInDays from "date-fns/differenceInDays";
import { client } from "pagarme/browser";
import {
  PagamentoState,
  Item,
  QueryStringPagamentoLink,
  PagamentosRecebedor,
  QueryStringPagamentoRecebedor,
  ParamPagamentoRecebedor,
  BodyCancelamento,
  QueryStringRelatorioMovimentacao,
  FiltersQueryParams,
} from "store/pagamento/interfaces";
import { StateEnum } from "shared/enums";
import { Action } from "store/interfaces";
import { Dispatch } from "redux";
import { axiosInstance, Endpoints } from "api";
import Environment from "api/Environment";
import { createErrorMessage } from "utils";
import { prazoMaximoCancelamento } from "shared/constants";
import axios, { CancelTokenSource } from "axios";

const RETRY_DELAY_TITULO = 2000;

export enum PagamentoTypes {
  GET_PAGAMENTO = "pagamento/GET_PAGAMENTO",
  GET_PAGAMENTO_SUCCESS = "pagamento/GET_PAGAMENTO_SUCCESS",
  GET_PAGAMENTO_FAILED = "pagamento/GET_PAGAMENTO_FAILED",
  GET_PAGAMENTO_ERROR_HANDLED = "pagamento/GET_PAGAMENTO_ERROR_HANDLED",
  RESET_PAGAMENTO = "pagamento/RESET_PAGAMENTO",
  EFETUAR_PAGAMENTO = "pagamento/EFETUAR_PAGAMENTO",
  EFETUAR_PAGAMENTO_SUCCESS = "pagamento/EFETUAR_PAGAMENTO_SUCCESS",
  EFETUAR_PAGAMENTO_FAILED = "pagamento/EFETUAR_PAGAMENTO_FAILED",
  EFETUAR_PAGAMENTO_ERROR_HANDLED = "pagamento/EFETUAR_PAGAMENTO_ERROR_HANDLED",
  RESET_EFETUAR_PAGAMENTO = "RESET_EFETUAR_PAGAMENTO",
  EFETUAR_CANCELAMENTO = "pagamento/EFETUAR_CANCELAMENTO",
  EFETUAR_CANCELAMENTO_SUCCESS = "pagamento/EFETUAR_CANCELAMENTO_SUCCESS",
  EFETUAR_CANCELAMENTO_FAILED = "pagamento/EFETUAR_CANCELAMENTO_FAILED",
  EFETUAR_CANCELAMENTO_ERROR_HANDLED = "pagamento/EFETUAR_CANCELAMENTO_ERROR_HANDLED",
  RESET_EFETUAR_CANCELAMENTO = "pagament/RESET_EFETUAR_CANCELAMENTO",
  GET_PAGAMENTO_LINK = "pagamento/GET_PAGAMENTO_LINK",
  GET_PAGAMENTO_LINK_SUCCESS = "pagamento/GET_PAGAMENTO_LINK_SUCCESS",
  GET_PAGAMENTO_LINK_FAILED = "pagamento/GET_PAGAMENTO_LINK_FAILED",
  GET_PAGAMENTO_LINK_ERROR_HANDLED = "pagamento/GET_PAGAMENTO_LINK_ERROR_HANDLED",
  RESET_PAGAMENTO_LINK = "pagamento/RESET_PAGAMENTO_LINK",
  GET_PAGAMENTO_RECEBEDOR = "pagamento/GET_PAGAMENTO_RECEBEDOR",
  GET_PAGAMENTO_RECEBEDOR_SUCCESS = "pagamento/GET_PAGAMENTO_RECEBEDOR_SUCCESS",
  GET_PAGAMENTO_RECEBEDOR_FAILED = "pagamento/GET_PAGAMENTO_RECEBEDOR_FAILED",
  GET_PAGAMENTO_RECEBEDOR_ERROR_HANDLED = "pagamento/GET_PAGAMENTO_RECEBEDOR_ERROR_HANDLED",
  GET_MOTIVOS_CANCELAMENTO = "pagamento/GET_MOTIVOS_CANCELAMENTO",
  GET_MOTIVOS_CANCELAMENTO_SUCCESS = "pagamento/GET_MOTIVOS_CANCELAMENTO_SUCCESS",
  GET_MOTIVOS_CANCELAMENTO_FAILED = "pagamento/GET_MOTIVOS_CANCELAMENTO_FAILED",
  GET_MOTIVOS_CANCELAMENTO_ERROR_HANDLED = "pagamento/GET_MOTIVOS_CANCELAMENTO_ERROR_HANDLED",
  GET_RELATORIO_MOVIMENTACAO = "pagamento/GET_RELATORIO_MOVIMENTACAO",
  GET_RELATORIO_MOVIMENTACAO_SUCCESS = "pagamento/GET_RELATORIO_MOVIMENTACAO_SUCCESS",
  GET_RELATORIO_MOVIMENTACAO_FAILED = "pagamento/GET_RELATORIO_MOVIMENTACAO_FAILED",
  GET_RELATORIO_MOVIMENTACAO_ERROR_HANDLED = "pagamento/GET_RELATORIO_MOVIMENTACAO_ERROR_HANDLED",
  GET_DADOS_BOLETO = "pagamento/GET_DADOS_BOLETO",
  GET_DADOS_BOLETO_SUCCESS = "pagamento/GET_DADOS_BOLETO_SUCCESS",
  GET_DADOS_BOLETO_FAILED = "pagamento/GET_DADOS_BOLETO_FAILED",
  GET_DADOS_BOLETO_ERROR_HANDLED = "pagamento/GET_DADOS_BOLETO_ERROR_HANDLED",
  RESET_RELATORIO_MOVIMENTACAO = "pagamento/RESET_RELATORIO_MOVIMENTACAO",
  RESET_PAGAMENTO_RECEBEDOR = "pagamento/RESET_PAGAMENTO_RECEBEDOR",
  GET_DETALHES_PAGAMENTO = "pagamento/GET_DETALHES_PAGAMENTO",
  GET_DETALHES_PAGAMENTO_SUCCESS = "pagamento/GET_DETALHES_PAGAMENTO_SUCCESS",
  GET_DETALHES_PAGAMENTO_FAILED = "pagamento/GET_DETALHES_PAGAMENTO_FAILED"
}

const initialState: PagamentoState = {
  getPagamento: {
    state: StateEnum.IDLE,
    error: null,
  },
  pagamento: {
    cooperativa: null,
    id: null,
    nome: "",
    nomeBeneficiario: "",
    cpfCnpj: "",
    itens: [],
    total: null,
    ativo: null,
    expirado: null,
    pagamentosExcedidos: null,
    quantidadeMaxima: null,
    quantidadePagamentos: null,
    formaPagamento: {},
  },
  efetuarPagamento: {
    state: StateEnum.IDLE,
    error: null,
    errorCode: null,
    data: null,
  },
  efetuarCancelamento: {
    state: StateEnum.IDLE,
    error: null,
    data: null,
  },
  getPagamentoLink: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      pagamentos: [],
      dataTreeTable: [],
      totalElements: 0,
    },
  },
  getPagamentoRecebedor: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      pagamentos: [],
      totalElements: 0,
      totalPages: 0,
      size: 0,
      sort: null,
      numberOfElements: 0,
    },
  },
  getMotivosCancelamento: {
    state: StateEnum.IDLE,
    error: null,
    data: [],
  },
  getRelatorioMovimentacao: {
    state: StateEnum.IDLE,
    error: null,
    data: [],
  },
  getDadosBoleto: {
    state: StateEnum.IDLE,
    error: null,
    data: {},
  },
  detalhePagamento: {
    state: StateEnum.IDLE,
    error: null,
    data: null
  },
};

export default function reducer(
  state = initialState,
  action: Action
): PagamentoState {
  switch (action.type) {
    case PagamentoTypes.GET_PAGAMENTO:
      return {
        ...state,
        getPagamento: {
          ...state.getPagamento,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_SUCCESS:
      return {
        ...state,
        getPagamento: {
          ...state.getPagamento,
          state: StateEnum.IDLE,
        },
        pagamento: { ...action.payload.pagamento },
      };
    case PagamentoTypes.GET_PAGAMENTO_FAILED:
      return {
        ...state,
        getPagamento: {
          ...state.getPagamento,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_ERROR_HANDLED:
      return {
        ...state,
        getPagamento: {
          ...state.getPagamento,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.EFETUAR_PAGAMENTO:
      return {
        ...state,
        efetuarPagamento: {
          ...state.efetuarPagamento,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.EFETUAR_PAGAMENTO_SUCCESS:
      return {
        ...state,
        efetuarPagamento: {
          ...state.efetuarPagamento,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case PagamentoTypes.EFETUAR_PAGAMENTO_FAILED:
      return {
        ...state,
        efetuarPagamento: {
          ...state.efetuarPagamento,
          state: StateEnum.ERROR,
          error: action.payload.error,
          errorCode: action.payload.code,
        },
      };
    case PagamentoTypes.EFETUAR_PAGAMENTO_ERROR_HANDLED:
      return {
        ...state,
        efetuarPagamento: {
          ...state.efetuarPagamento,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.RESET_EFETUAR_PAGAMENTO:
      return {
        ...state,
        efetuarPagamento: initialState.efetuarPagamento,
      };
    case PagamentoTypes.EFETUAR_CANCELAMENTO:
      return {
        ...state,
        efetuarCancelamento: {
          ...state.efetuarCancelamento,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.EFETUAR_CANCELAMENTO_SUCCESS:
      return {
        ...state,
        efetuarCancelamento: {
          ...state.efetuarCancelamento,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case PagamentoTypes.EFETUAR_CANCELAMENTO_FAILED:
      return {
        ...state,
        efetuarCancelamento: {
          ...state.efetuarCancelamento,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.EFETUAR_CANCELAMENTO_ERROR_HANDLED:
      return {
        ...state,
        efetuarCancelamento: {
          ...state.efetuarCancelamento,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.RESET_EFETUAR_CANCELAMENTO:
      return {
        ...state,
        efetuarCancelamento: initialState.efetuarCancelamento,
      };
    case PagamentoTypes.GET_PAGAMENTO_LINK:
      return {
        ...state,
        getPagamentoLink: {
          ...state.getPagamentoLink,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_LINK_SUCCESS:
      return {
        ...state,
        getPagamentoLink: {
          ...state.getPagamentoLink,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
        pagamento: { ...action.payload.pagamento },
      };
    case PagamentoTypes.GET_PAGAMENTO_LINK_FAILED:
      return {
        ...state,
        getPagamentoLink: {
          ...state.getPagamentoLink,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_LINK_ERROR_HANDLED:
      return {
        ...state,
        getPagamentoLink: {
          ...state.getPagamentoLink,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.RESET_PAGAMENTO_LINK:
      return {
        ...state,
        getPagamentoLink: initialState.getPagamentoLink,
      };

    case PagamentoTypes.GET_PAGAMENTO_RECEBEDOR:
      return {
        ...state,
        getPagamentoRecebedor: {
          ...state.getPagamentoRecebedor,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_RECEBEDOR_SUCCESS:
      return {
        ...state,
        getPagamentoRecebedor: {
          ...state.getPagamentoRecebedor,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_RECEBEDOR_FAILED:
      return {
        ...state,
        getPagamentoRecebedor: {
          ...state.getPagamentoRecebedor,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.GET_PAGAMENTO_RECEBEDOR_ERROR_HANDLED:
      return {
        ...state,
        getPagamentoRecebedor: {
          ...state.getPagamentoRecebedor,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.RESET_PAGAMENTO_RECEBEDOR:
      return {
        ...state,
        getPagamentoRecebedor: initialState.getPagamentoRecebedor,
      };
    case PagamentoTypes.GET_MOTIVOS_CANCELAMENTO:
      return {
        ...state,
        getMotivosCancelamento: {
          ...state.getMotivosCancelamento,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.GET_MOTIVOS_CANCELAMENTO_SUCCESS:
      return {
        ...state,
        getMotivosCancelamento: {
          ...state.getMotivosCancelamento,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case PagamentoTypes.GET_MOTIVOS_CANCELAMENTO_FAILED:
      return {
        ...state,
        getMotivosCancelamento: {
          ...state.getMotivosCancelamento,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.GET_MOTIVOS_CANCELAMENTO_ERROR_HANDLED:
      return {
        ...state,
        getMotivosCancelamento: {
          ...state.getMotivosCancelamento,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.GET_DADOS_BOLETO:
      return {
        ...state,
        getDadosBoleto: {
          ...state.getDadosBoleto,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.GET_DADOS_BOLETO_SUCCESS:
      return {
        ...state,
        getDadosBoleto: {
          ...state.getDadosBoleto,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case PagamentoTypes.GET_DADOS_BOLETO_FAILED:
      return {
        ...state,
        getDadosBoleto: {
          ...state.getDadosBoleto,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.GET_DADOS_BOLETO_ERROR_HANDLED:
      return {
        ...state,
        getDadosBoleto: {
          ...state.getDadosBoleto,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.GET_RELATORIO_MOVIMENTACAO:
      return {
        ...state,
        getRelatorioMovimentacao: {
          ...state.getRelatorioMovimentacao,
          state: StateEnum.LOADING,
        },
      };
    case PagamentoTypes.GET_RELATORIO_MOVIMENTACAO_SUCCESS:
      return {
        ...state,
        getRelatorioMovimentacao: {
          ...state.getRelatorioMovimentacao,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case PagamentoTypes.GET_RELATORIO_MOVIMENTACAO_FAILED:
      return {
        ...state,
        getRelatorioMovimentacao: {
          ...state.getRelatorioMovimentacao,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case PagamentoTypes.GET_RELATORIO_MOVIMENTACAO_ERROR_HANDLED:
      return {
        ...state,
        getRelatorioMovimentacao: {
          ...state.getRelatorioMovimentacao,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case PagamentoTypes.RESET_RELATORIO_MOVIMENTACAO:
      return {
        ...state,
        getRelatorioMovimentacao: initialState.getRelatorioMovimentacao,
      };
    case PagamentoTypes.RESET_PAGAMENTO:
      return initialState;
    case PagamentoTypes.GET_DETALHES_PAGAMENTO:
      return {
        ...state,
        detalhePagamento: {
          state: StateEnum.LOADING,
          error: null,
          data: null
        }
      }
    case PagamentoTypes.GET_DETALHES_PAGAMENTO_SUCCESS:
      return {
        ...state,
        detalhePagamento: {
          state: StateEnum.COMPLETE,
          error: null,
          data: action.payload.data
        }
      }
    case PagamentoTypes.GET_DETALHES_PAGAMENTO_FAILED:
      return {
        ...state,
        detalhePagamento: {
          state: StateEnum.ERROR,
          error: action.payload.error,
          data: null
        }
      }
    default:
      return state;
  }
}

export class PagamentoActions {
  static getPagamentoStarted(): Action {
    return { type: PagamentoTypes.GET_PAGAMENTO };
  }

  static getPagamentoFailed(error: string): Action {
    return { type: PagamentoTypes.GET_PAGAMENTO_FAILED, payload: { error } };
  }

  static getPagamentoSuccess(pagamento: Item): Action {
    return {
      type: PagamentoTypes.GET_PAGAMENTO_SUCCESS,
      payload: { pagamento },
    };
  }

  static getPagamentoLinkStarted(): Action {
    return { type: PagamentoTypes.GET_PAGAMENTO_LINK };
  }

  static getPagamentoLinkFailed(error: string): Action {
    return {
      type: PagamentoTypes.GET_PAGAMENTO_LINK_FAILED,
      payload: { error },
    };
  }

  static getPagamentoLinkSuccess(pagamento: Item): Action {
    return {
      type: PagamentoTypes.GET_PAGAMENTO_LINK_SUCCESS,
      payload: { data: pagamento },
    };
  }

  static getPagamentoRecebedorStarted(): Action {
    return { type: PagamentoTypes.GET_PAGAMENTO_RECEBEDOR };
  }

  static getPagamentoRecebedorFailed(error: string): Action {
    return {
      type: PagamentoTypes.GET_PAGAMENTO_RECEBEDOR_FAILED,
      payload: { error },
    };
  }

  static getPagamentoRecebedorSuccess(pagamento: PagamentosRecebedor): Action {
    return {
      type: PagamentoTypes.GET_PAGAMENTO_RECEBEDOR_SUCCESS,
      payload: { data: pagamento },
    };
  }

  static getPagamento(id: string): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.getPagamentoStarted());

        const response = await axiosInstance.get(
          `${Endpoints.link.links()}/${id}`
        );

        dispatch(PagamentoActions.getPagamentoSuccess(response.data));
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(PagamentoActions.getPagamentoFailed(error));
      }
    };
  }

  static getPagamentoLink(query: QueryStringPagamentoLink): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.getPagamentoLinkStarted());

        const response = await axiosInstance.get(
          `${Endpoints.pagamento.pagamento(query)}`
        );

        const data = {
          ...response.data,
          dataTreeTable: response.data.dataTreeTable.map((pagamento: any) => {
            return {
              ...pagamento,
              isCancelamentoExpired: this.cancelamentoIsExpired(
                pagamento.createdDate
              ),
            };
          }),
        };

        dispatch(PagamentoActions.getPagamentoLinkSuccess(data));
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(PagamentoActions.getPagamentoLinkFailed(error));
      }
    };
  }

  static getPagamentoRecebedor(
    query: QueryStringPagamentoRecebedor,
    recebedor: ParamPagamentoRecebedor,
    params : FiltersQueryParams,
    cancelToken: CancelTokenSource
  ): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.getPagamentoRecebedorStarted());
 
        const response = await axiosInstance.get(
          `${Endpoints.pagamento.pagamento()}/recebedor/${
            recebedor.id
          }?${querystring.stringify(query)}`,{
            params,
            cancelToken: cancelToken.token
          }
        );

        const data = {
          ...response.data,
          pagamentos: response.data.pagamentos.map((pagamento: any) => {
            return {
              ...pagamento,
              isCancelamentoExpired: this.cancelamentoIsExpired(
                pagamento.createdDate
              ),
            };
          }),
        };

        dispatch(PagamentoActions.getPagamentoRecebedorSuccess(data));
      } catch (err) {
        if (axios.isCancel(err)) return;
        const error = createErrorMessage(err);
        dispatch(PagamentoActions.getPagamentoRecebedorFailed(error));
      }
    };
  }

  static efetuarPagamentoStarted(): Action {
    return { type: PagamentoTypes.EFETUAR_PAGAMENTO };
  }

  static efetuarPagamentoFailed(error: string, code: number): Action {
    return {
      type: PagamentoTypes.EFETUAR_PAGAMENTO_FAILED,
      payload: { error, code },
    };
  }

  static efetuarPagamentoSuccess(pagamento: any): Action {
    return {
      type: PagamentoTypes.EFETUAR_PAGAMENTO_SUCCESS,
      payload: { data: pagamento },
    };
  }

  static efetuarPagamento(data: any): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.efetuarPagamentoStarted());

        let cardHash = null;

        if (data.cartao) {
          cardHash = await PagamentoActions.getHash({
            cardNumber: data.cartao?.numero,
            cardHolder: data.cartao?.nome,
            cardExpiration: data.cartao?.validade,
            cardCvv: data.cartao?.cvv,
          });
        }

        // Usando fetch com credencials = omit para evitar o envio de cookies que estava causando bloqueio 
        // quando a requisição tinha um cookie específico da unicred + cardHash gerado pela pagarme. 
        // https://dev.azure.com/unicredbr/TI/_workitems/edit/388790
        const responseFetch = await fetch('/api' + Endpoints.pagamento.pagamento(), {
          headers: {
            'Content-Type': 'application/json',
          },
          method: "POST",
          credentials: 'omit',
          body: JSON.stringify({
            ...data,
            ...(cardHash
              ? {
                  cartao: {
                    ...data.cartao,
                    cardHash,
                  },
                }
              : {}),
          }),
        });

        
        if (!responseFetch.ok) {
          const error = new Error("");
          (error as any).status = responseFetch.status;
          throw error;
        }

        const response = await responseFetch.json();

        if (data.boleto) {
          await new Promise((resolve) =>
            setTimeout(() => {
              resolve("");
            }, RETRY_DELAY_TITULO)
          );
          await dispatch(
            PagamentoActions.getDadosBoleto(
              response.uuidBeneficiario,
              response.uuidTitulo
            )
          );
        }
        dispatch(PagamentoActions.efetuarPagamentoSuccess(response));
      } catch (err) {
        console.log(err);
        const error = createErrorMessage(err);
        dispatch(
          PagamentoActions.efetuarPagamentoFailed(error, (err as any)?.status)
        );
      }
    };
  }

  static cancelamentoIsExpired(createdDate: string): boolean {
    return (
      differenceInDays(Date.now(), new Date(createdDate)) >
      prazoMaximoCancelamento
    );
  }

  static async getHash({
    cardNumber,
    cardHolder,
    cardExpiration,
    cardCvv,
  }: {
    cardNumber: string;
    cardHolder: string;
    cardExpiration: string;
    cardCvv: string;
  }): Promise<any> {
    try {
      const response = await axiosInstance.post(
        `${Endpoints.pagarme.pagarme()}/card-hash`,
        { cardNumber, cardHolder, cardExpiration, cardCvv }
      );

      return response.data.card_hash;

      // return client
      //   .connect({ encryption_key: pagarmeKey })
      //   .then((client: any) => {
      //     return client.security.encrypt({
      //       card_number: cardNumber,
      //       card_holder_name: cardHolder,
      //       card_expiration_date: cardExpiration,
      //       card_cvv: cardCvv,
      //     });
      //   })
      //   .then((data: any) => {
      //     return data;
      //   });
    } catch (err) {
      return null;
    }
  }

  static efetuarCancelamentoStarted(): Action {
    return { type: PagamentoTypes.EFETUAR_CANCELAMENTO };
  }

  static efetuarCancelamentoFailed(error: string): Action {
    return {
      type: PagamentoTypes.EFETUAR_CANCELAMENTO_FAILED,
      payload: { error },
    };
  }

  static efetuarCancelamentoSuccess(pagamento: any): Action {
    return {
      type: PagamentoTypes.EFETUAR_CANCELAMENTO_SUCCESS,
      payload: { data: pagamento },
    };
  }

  static efetuarCancelamento(id: string, body: BodyCancelamento): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.efetuarCancelamentoStarted());

        const response = await axiosInstance.post(
          `${Endpoints.pagamento.pagamento()}/${id}/estorno`,
          body
        );

        dispatch(PagamentoActions.efetuarCancelamentoSuccess(response.data));
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(PagamentoActions.efetuarCancelamentoFailed(error));
      }
    };
  }

  static getMotivosCancelamentoStarted(): Action {
    return { type: PagamentoTypes.GET_MOTIVOS_CANCELAMENTO };
  }

  static getMotivosCancelamentoFailed(error: string): Action {
    return {
      type: PagamentoTypes.GET_MOTIVOS_CANCELAMENTO_FAILED,
      payload: { error },
    };
  }

  static getMotivosCancelamentoSuccess(motivos: any): Action {
    return {
      type: PagamentoTypes.GET_MOTIVOS_CANCELAMENTO_SUCCESS,
      payload: { data: motivos },
    };
  }

  static getMotivosCancelamento(): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.getMotivosCancelamentoStarted());

        const response = await axiosInstance.get(
          `${Endpoints.pagamento.pagamento()}/motivo`
        );

        dispatch(PagamentoActions.getMotivosCancelamentoSuccess(response.data));
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(PagamentoActions.getMotivosCancelamentoFailed(error));
      }
    };
  }

  static getRelatorioMovimentacaoStarted(): Action {
    return { type: PagamentoTypes.GET_RELATORIO_MOVIMENTACAO };
  }

  static getRelatorioMovimentacaoFailed(error: string): Action {
    return {
      type: PagamentoTypes.GET_RELATORIO_MOVIMENTACAO_FAILED,
      payload: { error },
    };
  }

  static getRelatorioMovimentacaoSuccess(data: any): Action {
    return {
      type: PagamentoTypes.GET_RELATORIO_MOVIMENTACAO_SUCCESS,
      payload: { data },
    };
  }

  static getRelatorioMovimentacao(
    query: QueryStringRelatorioMovimentacao,
    recebedor: ParamPagamentoRecebedor
  ): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(PagamentoActions.getRelatorioMovimentacaoStarted());

        const response = await axiosInstance.get(
          `${Endpoints.pagamento.pagamento()}/recebedor/${
            recebedor.id
          }/relatorioMovimentacao?${querystring.stringify(query)}`
        );

        dispatch(
          PagamentoActions.getRelatorioMovimentacaoSuccess(response.data)
        );
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(PagamentoActions.getRelatorioMovimentacaoFailed(error));
      }
    };
  }

  static getDadosBoleto(beneficiarioId: string, tituloId: string): any {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: PagamentoTypes.GET_DADOS_BOLETO,
      });

      const response = await axiosInstance.get(
        `${Endpoints.pagamento.pagamento()}/${beneficiarioId}/titulos/${tituloId}`,
        {
          "axios-retry": {
            retries: 5,
            retryDelay: () => RETRY_DELAY_TITULO,
          },
        }
      );

      dispatch({
        type: PagamentoTypes.GET_DADOS_BOLETO_SUCCESS,
        payload: { data: response.data },
      });
    };
  }

  static resetEfetuarCancelamento(): Action {
    return { type: PagamentoTypes.RESET_EFETUAR_CANCELAMENTO };
  }

  static resetPagamento(): Action {
    return { type: PagamentoTypes.RESET_PAGAMENTO };
  }

  static resetPagamentoLink(): Action {
    return { type: PagamentoTypes.RESET_PAGAMENTO_LINK };
  }

  static resetEfetuarPagamento(): Action {
    return { type: PagamentoTypes.RESET_EFETUAR_PAGAMENTO };
  }

  static resetRelatorioMovimentacao(): Action {
    return { type: PagamentoTypes.RESET_RELATORIO_MOVIMENTACAO };
  }

  static getDetalhePagamento(pagamentoId: string): any {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: PagamentoTypes.GET_DETALHES_PAGAMENTO
      });

      try {
        const response = await axiosInstance.get(
          `${Endpoints.pagamento.pagamento()}/${pagamentoId}`
        );

        dispatch({
          type: PagamentoTypes.GET_DETALHES_PAGAMENTO_SUCCESS,
          payload: {data: response.data}
        })
      }
      catch(error) {
        const errorMessage = createErrorMessage(error);
        dispatch({
          type: PagamentoTypes.GET_DETALHES_PAGAMENTO_FAILED,
          payload: {error: errorMessage}
        })
      }
    }
  }
}
