import styled from 'styled-components';
import { TemplateConstants } from 'theme';

export const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms linear;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in-out;
  }
`;

export const ContentSection = styled.section`
  height: calc(100vh - ${TemplateConstants.APP_BAR_HEIGHT});
`;