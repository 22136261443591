import styled from 'styled-components';
import { Colors } from 'theme';

interface WrapperProps {
  noPadding?: boolean;
  rounded?: boolean;
  shadowed?: boolean;
  marginTop?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  background: #fff;
  color: ${Colors.dark};
  padding: ${({ noPadding }) => (noPadding ? 'unset' : '1rem')};
  border-radius: ${({ rounded }) => (rounded ? '12px' : '0px')};
  box-shadow: ${({ shadowed }) =>
    shadowed
      ? '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);'
      : 'unset'};
  margin-top: ${({marginTop}) => (marginTop ? '20px' : '0')}
`;
