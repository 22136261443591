import { createGlobalStyle } from 'styled-components';
import { Colors } from 'theme';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
  }

  body,
  #root {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
    background: ${Colors.lightBackground}; 
  }

  table {
    white-space: nowrap; 
    font-size: 0.8rem; 
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
   }

   ::-webkit-scrollbar-thumb {
      background: ${Colors.lighterGrey};
      border-radius: 7px;
   }

   ::-webkit-scrollbar-thumb:hover{
      background: ${Colors.activeGrey};
   }

   ::-webkit-scrollbar-track{
      background: #e0e0e082;
      border-radius: 15px;
   }

   .MuiMenu-list {
    max-height: 300px;
    overflow-y: auto;
   }
`;

export default GlobalStyle;
