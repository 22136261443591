import styled from "styled-components";
import {
  HelpOutlineOutlined,
  PaymentOutlined,
  LinkOutlined,
  AttachMoneyOutlined,
  NotesOutlined,
  ErrorOutline,
  InsertInvitation,
  AvTimer,
  SettingsBackupRestore,
  HeadsetMic,
} from "@material-ui/icons";
import theme, { TemplateConstants, Breakpoints, Colors } from "theme";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Card  } from "components";

export const Wrapper = styled.div`
  min-height: calc(100vh - ${TemplateConstants.APP_BAR_HEIGHT});
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  box-sizing: border-box;
  opacity: 0;
  animation: Wrapper 0.5s forwards;

  @media (min-width: ${Breakpoints.minExtraLarge}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1980px;
  }

  @keyframes Wrapper {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;

export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1400px;

  @media (max-width: ${Breakpoints.maxMedium}) {
    flex-direction: column;
  }
`;

export const HelpIcon = styled(HelpOutlineOutlined)``;

export const CardCategorias = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: true,
}))`
  flex-basis: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  position: relative;
`;

export const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
`;

export const CategoriaList = styled(List)`
  max-width: 100%;
  max-height: 100%;
  padding: 0px;
`;

export const CategoriaListItem = styled(ListItem).attrs((props) => {})`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${Colors.lighterGrey};
  max-width: 100%;
  max-height: 100%;
  padding: 0px 20px;

  svg {
    color: ${Colors.unicredGoldish};
  }
`;

export const CategoriaListItemIcon = styled(ListItemIcon)``;
export const CategoriaListItemText = styled(ListItemText)`
  padding: 15px 0px;
  color: ${Colors.lightGrey};
  :hover {
    color: ${Colors.unicredGoldish};
  }
`;

export const TagNovo = styled.span`
  background: ${Colors.gold};
  border-radius: 10px;
  color: ${theme.palette.secondary.light};
  font-size: 0.725rem;
  height: max-content;
  line-height: 1rem;
  padding: .3rem .5rem 0.3rem .5rem;
  position: relative;
  bottom: 0.1rem;
  margin-left: .4rem;
`;

export const IconLink = styled(LinkOutlined)``;
export const IconPayment = styled(PaymentOutlined)``;
export const IconMoney = styled(AttachMoneyOutlined)``;
export const IconReverse = styled(SettingsBackupRestore)``;
export const IconSchedulePayment = styled(InsertInvitation)``;
export const IconAnticipation = styled(AvTimer)``;
export const IconSAC = styled(HeadsetMic)``;
export const IconDefault = styled(NotesOutlined)``;
export const IconError = styled(ErrorOutline)``;
