import styled from "styled-components";
import Calendar from "react-calendar"
import { Button, IconButton } from "@material-ui/core";
import { Modal } from "components";
import { ArrowDropDownOutlined } from "@material-ui/icons";
import { Breakpoints } from "theme";


export const StyledCalendarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;
`;

export const ModalStyled = styled(Modal)`
  display: flex; 
  align-items: center; 
  justify-content: center; 
  box-shadow: none; 
  height: 100%;
`;

export const IconButtonStyled = styled(IconButton)`
  @media (min-width: ${Breakpoints.maxSmall}) {
    padding: 1.2rem;
  }
`;

interface ArrowProps{
  rotated: string;
}

export const ArrowDropMonth = styled(ArrowDropDownOutlined)<ArrowProps>`
  transition: transform 0.3s ease-in-out;
  ${({ rotated }) => rotated=="true" ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);'}
`;

export const NavigationLabel = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
  height:3rem;
  justify-content: space-around;
  align-items:center;
`;

export const ButtonsLabel = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;  
  right: 10px;
  margin: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const MonthLabel = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 10px;
  font-size: 15px;
  font-weight:bolder;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 3rem;
`;

export const ButtonElement = styled(Button)`
  font-size: 10px;
  color: #0000ff;
  width: 20px;
  &:hover{
    background: none;
  }
`;

export const StyledCalendar = styled(Calendar)`
  overflow:hidden;
  margin: 0rem .5rem 0rem .5rem;  
  width: 200px;
  background-color: #fff;
  border-radius: 4px;
  border: none;
  font-size: 10px;

  .react-calendar__tile {
    padding: 6px;
    font-size:10px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #0275FF;
    }
  }
  
  .react-calendar__navigation__label__labelText {
    text-transform: capitalize;
    font-weight: bold;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    order: 1;
    width: 75%;
  }
  .react-calendar__navigation__prev-button {
    order: 2;
    justify-self: flex-end;
  }
  .react-calendar__navigation__next-button {
    order: 3;
    justify-self: flex-end;
  }



  .react-calendar__tile--active {
    background-color: #0275FF;
    border: 2px solid #015FCC;
    color: #fff;
  }
`;

export const CalendarioLabel = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;
