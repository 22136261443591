import React from 'react'
import { format } from 'date-fns'

import {
  Wrapper,
  TitleWrapper,
  TitleRow,
  Title,
  Subtitle,
  RightContent,
} from './styles'
import { IconButton } from '@material-ui/core'
import { ChevronLeft, CreditCard } from '@material-ui/icons'
import { useRedirect } from 'hooks'
import { dateFormat, hourFormat } from 'shared/constants'

interface HeaderProps {
  criadoEm?: Date
  title: string
  backAddress: string
  rightContent?: any
  dateText?: string
}

export default function Header(props: HeaderProps) {
  const {
    criadoEm,
    title,
    backAddress,
    rightContent,
    dateText = 'Criado em',
  } = props
  const redirect = useRedirect()

  return (
    <Wrapper>
      <IconButton onClick={() => redirect(backAddress)}>
        <ChevronLeft />
      </IconButton>
      <TitleWrapper>
        <TitleRow>
          <CreditCard />
          <Title>{title}</Title>
        </TitleRow>
        {criadoEm && (<Subtitle>
          {dateText} <strong>{format(criadoEm, dateFormat)}</strong> às
          <strong> {format(criadoEm, hourFormat)}</strong>
        </Subtitle>)}
      </TitleWrapper>
      <RightContent>{rightContent}</RightContent>
    </Wrapper>
  )
}
