import { axiosInstance, Endpoints } from "api";
import { Dispatch } from "redux";
import { StateEnum } from "shared/enums";
import { Action } from "store/interfaces";
import { createErrorMessage } from "utils";
import {
  PagamentoSolicitacaoPixOPF,
  ReciboSolicitacaoPixError,
  ReciboSolicitacaoPixOPFState,
  ReciboSolicitacaoPixQuery,
} from "./interfaces";

export enum ReciboSolicitacaoPixOPFType {
  GET_RECIBO = "recibo_solicitacao_pix_opf/GET_RECIBO",
  GET_RECIBO_SUCCESS = "recibo_solicitacao_pix_opf/GET_RECIBO_SUCCESS",
  GET_RECIBO_FAILED = "recibo_solicitacao_pix_opf/GET_RECIBO_FAILED",
  GET_RECIBO_ERROR_HANDLED = "recibo_solicitacao_pix_opf/GET_RECIBO_ERROR_HANDLED",
  GET_RECIBO_RESET = "recibo_solicitacao_pix_opf/GET_RECIBO_RESET",
  GET_LINK_PAGAMENTO = "recibo_solicitacao_pix_opf/GET_LINK_PAGAMENTO",
  GET_LINK_PAGAMENTO_SUCCESS = "recibo_solicitacao_pix_opf/GET_LINK_PAGAMENTO_SUCCESS",
  GET_LINK_PAGAMENTO_FAILED = "recibo_solicitacao_pix_opf/GET_LINK_PAGAMENTO_FAILED",
  GET_LINK_PAGAMENTO_ERROR_HANDLED = "recibo_solicitacao_pix_opf/GET_LINK_PAGAMENTO_ERROR_HANDLED",
}

const initialState: ReciboSolicitacaoPixOPFState = {
  getReciboSolicitacaoPixOPF: {
    state: StateEnum.IDLE,
    error: null,
    data: null,
  },
  getLinkPagamento: {
    state: StateEnum.IDLE,
    error: null,
    data: null,
  },
};

export function reducer(
  state = initialState,
  action: Action
): ReciboSolicitacaoPixOPFState {
  switch (action.type) {
    case ReciboSolicitacaoPixOPFType.GET_RECIBO:
      return {
        ...state,
        getReciboSolicitacaoPixOPF: {
          ...state.getReciboSolicitacaoPixOPF,
          state: StateEnum.LOADING,
        },
      };

    case ReciboSolicitacaoPixOPFType.GET_RECIBO_SUCCESS:
      return {
        ...state,
        getReciboSolicitacaoPixOPF: {
          ...state.getReciboSolicitacaoPixOPF,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };

    case ReciboSolicitacaoPixOPFType.GET_RECIBO_FAILED:
      return {
        ...state,
        getReciboSolicitacaoPixOPF: {
          ...state.getReciboSolicitacaoPixOPF,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case ReciboSolicitacaoPixOPFType.GET_RECIBO_ERROR_HANDLED:
      return {
        ...state,
        getReciboSolicitacaoPixOPF: {
          ...state.getReciboSolicitacaoPixOPF,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case ReciboSolicitacaoPixOPFType.GET_RECIBO_RESET:
      return {
        ...state,
        getReciboSolicitacaoPixOPF: initialState.getReciboSolicitacaoPixOPF,
      };
    case ReciboSolicitacaoPixOPFType.GET_LINK_PAGAMENTO:
      return {
        ...state,
        getLinkPagamento: {
          ...state.getLinkPagamento,
          state: StateEnum.LOADING,
        },
      };
    case ReciboSolicitacaoPixOPFType.GET_LINK_PAGAMENTO_SUCCESS:
      return {
        ...state,
        getLinkPagamento: {
          ...state.getLinkPagamento,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
          error: null,
        },
      };
    case ReciboSolicitacaoPixOPFType.GET_LINK_PAGAMENTO_FAILED:
      return {
        ...state,
        getLinkPagamento: {
          ...state.getLinkPagamento,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}
export class ReciboSolicitacaoPixOPFActions {
  static getRecebimento(params: ReciboSolicitacaoPixQuery) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(ReciboSolicitacaoPixOPFActions.getRecebimentoStarted());

        const response = await axiosInstance.get(
          `${Endpoints.openFinance.openFinance()}/recibo`,
          { params }
        );

        dispatch(
          ReciboSolicitacaoPixOPFActions.getReciboSuccess(response.data)
        );
      } catch (err) {
        ReciboSolicitacaoPixOPFActions.getLinkPagamento({
          state: params.state
        })(dispatch);
        dispatch(ReciboSolicitacaoPixOPFActions.getReciboFailed(err));
      }
    };
  }

  static getRecebimentoStarted(): Action {
    return { type: ReciboSolicitacaoPixOPFType.GET_RECIBO };
  }

  static getReciboSuccess(data: PagamentoSolicitacaoPixOPF): Action {
    return {
      type: ReciboSolicitacaoPixOPFType.GET_RECIBO_SUCCESS,
      payload: { data },
    };
  }
  static getReciboFailed(err: unknown): Action {
    const error = createErrorMessage(err);
    return {
      type: ReciboSolicitacaoPixOPFType.GET_RECIBO_FAILED,
      payload: { error },
    };
  }
  static reset(): Action {
    return { type: ReciboSolicitacaoPixOPFType.GET_RECIBO_RESET };
  }

  static getLinkPagamento(params: ReciboSolicitacaoPixError) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(ReciboSolicitacaoPixOPFActions.getLinkPagamentoStarted());

        if (!params.state) {
          dispatch(
            ReciboSolicitacaoPixOPFActions.getLinkPagamentoFailed({
              error: "empty state",
            })
          );
          return;
        }

        const response = await axiosInstance.get(
          `${Endpoints.openFinance.openFinance()}/linkPagamento`,
          { params: { requestId: params.state } }
        );

        dispatch(
          ReciboSolicitacaoPixOPFActions.getLinkPagamentoSuccess(response.data)
        );
      } catch (err) {
        dispatch(ReciboSolicitacaoPixOPFActions.getLinkPagamentoFailed(err));
      }
    };
  }

  static getLinkPagamentoStarted(): Action {
    return { type: ReciboSolicitacaoPixOPFType.GET_LINK_PAGAMENTO };
  }

  static getLinkPagamentoSuccess(data: string): Action {
    return {
      type: ReciboSolicitacaoPixOPFType.GET_LINK_PAGAMENTO_SUCCESS,
      payload: { data },
    };
  }

  static getLinkPagamentoFailed(err: unknown): Action {
    const error = createErrorMessage(err);
    return {
      type: ReciboSolicitacaoPixOPFType.GET_LINK_PAGAMENTO_FAILED,
      payload: { error },
    };
  }
}
