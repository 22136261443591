import { TablePagination } from "@material-ui/core";
import styled from "styled-components";
import { Colors } from "theme";

export const ContentMobileMovimentacao = styled.div`
  font-size: 14px;
  background-color: white;
  box-sizing: border-box;
  padding: .5rem 1rem;
  width: 100%;
  margin: 1rem 0;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background: ${Colors.lighterGrey};
  }
`;

export const MovimentacaoWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 1%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MovimentacaoData = styled.div`
  width: 60%;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MovimentacaoTitle = styled.div`
  display: flex;
  width: 40%;
  font-weight: bolder;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const NavigationTable = styled<any>(TablePagination)`
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
