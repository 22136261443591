import styled from 'styled-components'
import { DialogTitle, RadioGroup, FormControlLabel } from '@material-ui/core'
import { Colors } from 'theme';
import { Button } from 'components';

export const Message = styled(DialogTitle)`
    padding-left: 1rem;

    & .MuiTypography-h6 {
        font-size: 1rem;
    }   
`

export const MotivosWrapper = styled(RadioGroup)`
    margin: 0rem 1rem 1rem 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
`

export const ConfirmacaoWrapper = styled.span`
    display: block;
    padding: 0rem 1rem 1.5rem 1rem;
`;

export const Motivo = styled(FormControlLabel)`
  && .MuiButtonBase-root.Mui-checked {
    color: ${Colors.primary};
  }    
`

export const BotaoConfirmar = styled(Button)`
  min-width: 150px;
  margin-bottom: 0.6rem;
`;

export const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
`