import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionProps } from '@material-ui/core';
import { Colors } from 'theme';

interface AccordionStyledProps extends AccordionProps {
    margintop?: string;
}

export const AccordionStyled = styled(Accordion)<AccordionStyledProps>`
    margin-top: ${({margintop}) => margintop ? '20px' : 0};
    
    box-shadow: none;
    border-radius: 12px;
    padding: 6px 10px;

    &.Mui-expanded {
        margin-top: ${({margintop}) => margintop ? '20px' : 0};
        margin-bottom: 0;
    }

    &:before {
        background-color: rgba(0, 0, 0, 0);
    }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px;
    padding: 0;
    box-sizing: border-box;
`;

export const Title = styled.p`
    color: ${Colors.primary};
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
`;