import React from 'react';
import styled from 'styled-components';

import {
  IconButton as MUIIconButton,
  DialogContent as MUIDialogContent,
  DialogContentProps,
  DialogTitle as MUIDialogTitle,
} from '@material-ui/core';
import { Breakpoints, Colors } from 'theme';

export const IconButton = styled(MUIIconButton)`
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
`;

interface StyledDialogProps extends DialogContentProps {
  noPadding?: boolean;
}

const TransientDialogContent = ({ noPadding, ...rest }: StyledDialogProps) => (
  <MUIDialogContent {...rest} />
);

export const DialogContent = styled(TransientDialogContent)`
  &.MuiDialogContent-root {
    padding: ${(props) => (props.noPadding ? '0px' : '.5rem 1.5rem 0.5rem')};

    @media (max-width: ${Breakpoints.maxSmall}) {
      padding: ${(props) => (props.noPadding ? '0px' : '.5rem 1em 0.5rem')};
    }
  }
`;

export const DialogTitle = styled(MUIDialogTitle)`
  
  &.MuiDialogTitle-root {
    @media (max-width: ${Breakpoints.maxSmall}) {
      padding: .5rem 1rem 0.5rem;
    }
  }
  > h2.MuiTypography-h6 {
    font-weight: 700;
    color: ${Colors.darkTypography};
    
  }

  
`;
