import styled from 'styled-components'
import { Card } from 'components'
import { Colors, Breakpoints } from 'theme'

export const Wrapper = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: false,
}))`
  flex-basis: 100%;
  width: 100%;
  padding: 1.4rem;
  box-sizing: border-box;
`

export const Title = styled.span`
  display: block;
  color: ${Colors.lightGrey};
  font-size: 1rem;
`

export const Indicador = styled.span`
  color: ${(props) => props.color || Colors.primary};
  font-size: 2rem;
`
export const InfoWrapper = styled.div`
  padding-top: 0.2rem;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    margin-bottom: 2rem;
  }
`
export const Cifra = styled.span`
  color: ${Colors.lightGrey};
  font-size: 1.3rem;
`
export const Parcelamento = styled.span`
  color: ${Colors.lightGrey};
  font-size: 1rem;
  margin-left: 0.5rem;
`