import React, { useEffect, useState } from "react";
import {
  ContentMobileMovimentacao,
  MovimentacaoData,
  MovimentacaoTitle,
  MovimentacaoWrapper,
  NavigationTable,
  Wrapper,
} from "./styles";
import { RoutesPaths } from "routes";
import { useRedirect } from "hooks";
import { PagamentosRecebedorItem } from "store/pagamento/interfaces";
import StringUtils from "utils/StringUtils";
import { CircularProgress } from "@material-ui/core";
import { Pagination } from "../../interfaces";

interface TableMobileProps {
  pagamentos: PagamentosRecebedorItem[];
  loading: boolean;
  quantidadePagamentos: number;
  onChange: (rows: Pagination) => void;
}

export default function TableMobile(props: TableMobileProps) {
  const redirect = useRedirect();

  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const request: Pagination = { row: 5, pageNumber: page };
    props.onChange(request);
  }, [page]);

  return props.loading ? (
    <Wrapper>
      <CircularProgress data-testid="circular-progress" />
    </Wrapper>
  ) : (
    <>
      {props.pagamentos.map((pg, index) => {
        return (
          <ContentMobileMovimentacao
            data-testid="table-data"
            key={pg.id}
            onClick={() =>
              redirect(`${RoutesPaths.transacoes.detalhes}/${pg.id}`)
            }
          >
            <MovimentacaoWrapper data-testid={props["data-testid"]}>
              <MovimentacaoTitle>Nome</MovimentacaoTitle>
              <MovimentacaoData>{pg.nomePagador}</MovimentacaoData>
            </MovimentacaoWrapper>
            <MovimentacaoWrapper>
              <MovimentacaoTitle>Data</MovimentacaoTitle>
              <MovimentacaoData>{pg.dataCriacao}</MovimentacaoData>
            </MovimentacaoWrapper>
            <MovimentacaoWrapper>
              <MovimentacaoTitle>Valor</MovimentacaoTitle>
              <MovimentacaoData>
                {StringUtils.format.formatBRL(pg.valor)}
              </MovimentacaoData>
            </MovimentacaoWrapper>
            <MovimentacaoWrapper>
              <MovimentacaoTitle>Status</MovimentacaoTitle>
              <MovimentacaoData>
                {pg.modalidade === "PIX" && pg.tipoMovimentacao === "Estornado"
                  ? "Devolvido"
                  : pg.tipoMovimentacao}
              </MovimentacaoData>
            </MovimentacaoWrapper>
            <MovimentacaoWrapper>
              <MovimentacaoTitle>Modalidade</MovimentacaoTitle>
              <MovimentacaoData>{pg.modalidade}</MovimentacaoData>
            </MovimentacaoWrapper>
          </ContentMobileMovimentacao>
        );
      })}

      <NavigationTable
        data-testid="navegate"
        rowsPerPageOptions={[5]}
        count={props.quantidadePagamentos}
        labelDisplayedRows={({ from, to, count }: any) =>
          `${from}-${to} de ${count}`
        }
        rowsPerPage={5}
        page={page}
        onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
        onChangeRowsPerPage={() => {}}
        component="div"
      />
    </>
  );
}
