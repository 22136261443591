import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme';

export const P = styled.p`
  font-size: 18px;
  color: ${Colors.typography};
`;

export default function Paragraph(props: any) {
  return <P {...props} />;
}
