import React from 'react'
import { IconButton } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons';
import { useRedirect } from 'hooks';
import { RecebimentosRoutesPaths } from 'pages/Recebimentos';
import { Wrapper, Title, TitleWrapper, HeaderDivider, IconContent, RightContent } from './styles';

interface HeaderProps {
  title: string
  icon?: any
  rightText?: string
}

const Header = ({ title, icon, rightText }: HeaderProps) => {
  const redirect = useRedirect();

  return (
    <>
      <Wrapper>
        <IconButton onClick={() => { redirect(RecebimentosRoutesPaths.base) }}>
          <ChevronLeft />
        </IconButton>
        <TitleWrapper>
          {icon && <IconContent>{icon}</IconContent>}
          <Title> {title} </Title>
        </TitleWrapper>
        {rightText && (<RightContent>
          {rightText}
        </RightContent>)}
      </Wrapper>
      <HeaderDivider />
    </>
  )
}

export default Header;