import { createMuiTheme } from '@material-ui/core/styles'

export class Colors {
  private constructor() {}

  static primary = '#018B6A'
  static lightPrimary = '#129c8c'
  static lighterPrimary = '#bbf7f0'
  static hoverPrimary = '#0ca996'
  static secondary = '#48D2A0'
  static danger = '#fe4b61'
  static alert = '#e4a220'
  static info = '#4497b8'
  static disabled = '#d7d7d7'
  static light = '#f8f9fa'
  static dark = '#323538'
  static lightGrey = 'rgb(142, 142, 147)'
  static lighterGrey = 'rgba(0, 0, 0, 0.08)'
  static activeGrey = 'rgba(0, 0, 0, 0.14)'
  static lightTypography = 'rgba(0, 0, 0, 0.54)'
  static darkTypography = 'rgba(0, 0, 0, 0.87)'
  static typography = '#555555'
  static lightBorder = 'rgba(0, 0, 0, 0.23)'
  static lightYellow = 'rgb(244, 204, 97)'
  static lightBackground = '#F4F3F3'
  static success = '#3cba83'
  static lightPurple = '#8f7bbc'
  static orange = '#ee6c00'
  static yellow = '#ffe640'
  static unicredGoldish = '#a79450'
  static gold = '#958146'
  static basil = '#166649'
  static stone = '#026B91'
  static scarlet = '#910F2D'
  static grey = '#808080'
  static emerald = '#009168'
  static lemonade = '#f4c3cb'
}

export class Breakpoints {
  private constructor() {}

  static maxSmallTablet = '767px'
  static maxSmall = '959px'
  static maxMedium = '1280px'
  static minExtraLarge = '1920px'
}

export class TemplateConstants {
  private constructor() {}

  static APP_BAR_HEIGHT = '55px'
}

const theme = createMuiTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      light: `${Colors.lightPrimary}`,
      main: `${Colors.primary}`,
    },
    secondary: {
      light: '#fff',
      main: `${Colors.secondary}`,
    },
    error: {
      main: `${Colors.danger}`,
    }
  },
  spacing: 6,
})

export default theme
