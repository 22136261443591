
import { combineReducers } from 'redux'
import { AppState } from 'store/interfaces'

import usuario from './usuario'
import { authReducer as auth } from './auth'
import { reducer as linkPagamento } from './linkPagamento'
import { reducer as ui } from './ui'
import pagamento from './pagamento'
import { reducer as taxas } from './taxas'
import { reducer as cooperativaTaxas } from './cooperativaTaxas'
import { reducer as recebedor } from './recebedor'
import { reducer as recebimento } from './recebimento'
import { localidadeReducer as localidade } from './localidade'
import { reducer as ajuda } from './ajuda'
import { reducer as config } from "./config";
import { reducer as extratoRecebiveis } from './extratoRecebiveis'

import { reducer as openFinance } from './openFinance';
import { reducer as reciboSolicitacaoPixOPF } from "./reciboSolicitacaoPixOPF";
import { reducer as simulacao} from './simulacao';

export default combineReducers<AppState>({
  usuario,
  auth,
  ui,
  linkPagamento,
  pagamento,
  taxas,
  cooperativaTaxas,
  recebedor,
  localidade,
  recebimento,
  ajuda,
  config,
  extratoRecebiveis,
  openFinance,
  reciboSolicitacaoPixOPF,
  simulacao
});
