import styled from 'styled-components'
import { Breakpoints } from 'theme'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: ${Breakpoints.maxMedium}) {
    flex-direction: column;
  }
`
