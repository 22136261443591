import React from 'react'

import {
  Wrapper,
  Title,
  Indicador,
  InfoWrapper,
  Cifra,
  Parcelamento
} from './styles'
import StringUtils from 'utils/StringUtils'

interface IndicadorCardProps {
  titulo: string
  valor?: string
  color?: string
  cifra?: boolean
  parcelamento?: boolean
  content?: any
  casasDecimais?: 0 | 1 | 2
}

const InfoCard = ({
  titulo,
  valor,
  color,
  cifra,
  parcelamento,
  casasDecimais = 0,
  content,
}: IndicadorCardProps) => {

  let valorView = ''

  if (valor) {    
    switch (casasDecimais) {
      case 2:
        valorView = StringUtils.format.formatNumber(Number(valor))
        break
      case 1:
        valorView = StringUtils.format.formatNumber(Number(valor), 1, 1)
        break
      case 0:
        valorView = valor
        break
    }
  }

  return (
    <Wrapper>
      <Title>{titulo}</Title>
      <InfoWrapper>
        {content ?
          content
          :
          (
            <>
              {cifra && <Cifra> R$ </Cifra>}
              <Indicador color={color}>{valorView}</Indicador>
              {parcelamento && <Parcelamento> {parseInt(valorView) === 1 ? 'vez' : 'vezes'} </Parcelamento>}
            </>
          )}
      </InfoWrapper>
    </Wrapper>
  )
}

export default InfoCard