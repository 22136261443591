import styled from 'styled-components';
import { Colors } from 'theme';

import { InfoOutlined } from '@material-ui/icons';

export const Header = styled.header`
  height: 250px;
  width: 100%;
  background-color: ${Colors.primary};
  color: ${Colors.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    margin: 1rem 0 0 0;
  }
`;

export const Content = styled.section`
  margin-top: 1rem;
  padding: 1.5rem;
  text-align: center;

  p {
    margin: 0;
  }

  button {
    margin-left: auto;
    margin-top: 2rem;
  }
`;

export const InfoIcon = styled(InfoOutlined)`
  color: ${Colors.light};
  font-size: 60px;
`;
