import styled from 'styled-components';
import { Colors } from 'theme';

import { OutlinedInput as MUIOutlinedInput } from '@material-ui/core';

interface ErrorMsgProps {
  textAlign?: 'center' | 'left' | 'right' | 'justify';
}

export const ErrorMsg = styled.small<ErrorMsgProps>`
  margin-top: 0.25rem;
  color: ${Colors.danger};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'unset')};
`;

export const OutlinedInput = styled(MUIOutlinedInput)`
  background-color: #FFF;
  
  .Mui-disabled {
    cursor: not-allowed;
  }
`;
