import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMount, useRedirect, useTypedSelector } from "hooks";
import { useDispatch } from "react-redux";
import { AuthActions } from "store/auth";
import { StateEnum } from "shared/enums";
import { UiActions } from "store/ui";
import { hasError } from "utils";
import SessionExpiredModal from "./SessionExpiredModal";
import SessionUnauthorizedModal from "./SessionUnauthorizedModal";
import OnBoarding from "pages/OnBoarding";
import LoginUrlError from "./LoginUrlError";
import Routes, { RoutesPaths } from "routes";
import CenteredLoader from "components/CenteredLoader";
import KeycloakService from "KeycloakService";
import { useLocalForage } from "hooks/useLocalForage";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const redirect = useRedirect();
  const query = new URLSearchParams(location.search);
  const accessToken = useTypedSelector((state) => state.auth.accessToken);
  const isRecebedor = useTypedSelector((state) => state.auth.isRecebedor);
  const metodoLogin = useTypedSelector((state) => state?.auth?.metodoLogin);
  const [object, editObject, deleteObject, isObjectLoading] = useLocalForage<{}>("movimentacoes", {});

  const { state: sessionState } = useTypedSelector(
    (state) => state.auth.session
  );
  const { state: loginByUrlState } = useTypedSelector(
    (state) => state.auth.loginByUrl
  );
  const isAuth = !!accessToken;
  
  useEffect(() => {
    if(isRecebedor && isAuth && !location.pathname.includes(RoutesPaths.pagamento)){
      redirect(RoutesPaths.dashboard);
    }
  }, [isAuth])

  //Delete dos filtros da pagina movimentações guardados em memória caso utilize alguma rota fora do contexto de movimentacoes
  useEffect(() => {
    if (!isObjectLoading && location.pathname.indexOf('movimentacao') === -1) {
      deleteObject();
    }
  }, [location, object])

  const shouldRender =
    sessionState !== StateEnum.IDLE &&
    sessionState !== StateEnum.LOADING &&
    loginByUrlState !== StateEnum.LOADING

  const appContent = shouldRender ? (
    <>
      {isAuth && (
        <>
          <SessionExpiredModal />
          <SessionUnauthorizedModal />
        </>
      )}
      {hasError(loginByUrlState) ? (
        <LoginUrlError />
      ) : (
        <>
          {isAuth && <OnBoarding />}
          <Routes isAuth={isAuth} isRecebedor={isRecebedor} />
        </>
      )}
    </>
  ) : (
    <CenteredLoader />
  );
 
  useMount(() => {
    dispatch(AuthActions.resetLoginByUrl());

    if (location.pathname !== "login" && !metodoLogin?.keycloak && !isAuth) {
      dispatch(AuthActions.loginPorKeycloak(true))
    }

    if (
      query.get("token") &&
      query.get("cooperativa") &&
      query.get("usuario") &&
      query.get("conta") &&
      query.get("agencia") &&
      query.get("plataforma")
    ) {
      dispatch(AuthActions.reset());

      dispatch(
        AuthActions.loginByUrl({
          token: query.get("token"),
          cooperativa: query.get("cooperativa"),
          usuario: query.get("usuario"),
          conta: query.get("conta"),
          agencia: query.get("agencia"),
          plataforma: query.get("plataforma"),
        })
      );

      dispatch(UiActions.hideExit());
      dispatch(AuthActions.loginPorKeycloak(false));

    } else if (KeycloakService.isAuthenticated()) {
        const {cooperativa, usuario, agencia, conta} = KeycloakService.getUserAttributes();
        
        dispatch(AuthActions.loginByUrl({
          token: KeycloakService.getToken(),
          cooperativa ,
          usuario,
          agencia,
          conta
        }))
    } else {
      dispatch(AuthActions.checkSession(accessToken));
    } 
  });

  return appContent;
};

export default App;
