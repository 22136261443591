export const optionsStatus: string[] = [
  "Pago",
  "Pendente",
  "Recusado",
  "Estorno pendente",
  "Devolvido",
  "Chargeback",
  "Estornado"
];

export const optionsModalidades: string[] = [
  'Boleto',
  'Cartão',
  'Pix',
];

export const optionsDateRange: string[] = [
  "7 dias",
  "15 dias",
  "30 dias",
  "60 dias",
  "180 dias",
  "12 meses",
  "24 meses"
];

export const translateStatus = {
  Pago: 'PAID',
  Pendente: 'WAITING_PAYMENT',
  Recusado: 'REFUSED',
  'Estorno pendente': 'PENDING_REFUND',
  Devolvido: 'REFUNDED',
  Chargeback: 'CHARGEDBACK',
  Estornado: 'REFUNDED'
}

export const translateType = {
  'Cartão': 'CARTAO',
  Boleto: 'BOLETO',
  Pix: 'PIX'
};
