import React, { lazy, Suspense } from "react";
import { muiTableLocalization } from "shared/constants";
import tableIcons from "shared/tableIcons";
import Loader from "components/CenteredLoader";
const MaterialTable = lazy(() => import("@material-table/core"));

interface TransacoesTableProps {
  data?: any;
  isLoading?: boolean;
  onRowClick: (
    event?: React.MouseEvent,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void;
  showTitle?: boolean;
  search?: boolean;
  toolbar?: boolean;
  page?: number;
  totalElements?: number;
  onChangePage?: (page: number, pageSize: number) => void;
  onChangeRowsPerPage?: (perPage: number) => void;
  onChangeOrder?: (order: number, direction: string) => void;
  title?: string;
  columns: any[];
  actions?: any[];
  components?: any;
}

export default function TransacoesTable(props: TransacoesTableProps) {
  const {
    data,
    isLoading,
    onRowClick,
    showTitle = true,
    search = false,
    toolbar = true,
    title = "",
    page = 0,
    totalElements = 0,
    onChangePage,
    onChangeRowsPerPage,
    onChangeOrder,
    columns = [],
    actions,
    components,
  } = props;

  const options = {
    search,
    actionsColumnIndex: 2,
    showTitle,
    toolbar,
  };

  return (
    <Suspense fallback={<Loader />}>
      <MaterialTable
        icons={tableIcons}
        title={title}
        columns={columns}
        data={data}
        options={{
          ...options,
          actionsColumnIndex: -1,
          toolbarButtonAlignment: "left",
          headerStyle: {
            fontWeight: "bold",
          },
          emptyRowsWhenPaging: false,
          rowStyle: (rowData, index, level) => {
            if (
              rowData.tableData.isTreeExpanded === false &&
              rowData.tableData.path.length === 1
            ) {
              return {};
            }

            if (rowData.parentId) {
              const backgroundColor = "#fff";
              const color = "#a79450";
              return { backgroundColor, color };
            }
            return {};
          },
        }}
        localization={muiTableLocalization}
        isLoading={isLoading}
        style={{
          boxShadow: "none",
        }}
        onRowClick={onRowClick}
        page={page}
        totalCount={totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        actions={actions}
        onOrderChange={onChangeOrder}
        parentChildData={(row: any, rows) =>
          rows.find((a: any) => a.id === row.parentId)
        }
        components={components}
      />
    </Suspense>
  );
}
