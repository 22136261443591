import React from 'react'

import {
  ContentPosition,
} from './styles'
import { Header } from 'components'

interface SubHeaderProps {
  criadoEm: Date
  backAddress: string
  title: string
  rightContent: any
}
export default function SubHeader(props: SubHeaderProps) {
  const {
    criadoEm,
    backAddress,
    title,
    rightContent
  } = props
 
  return (
    <ContentPosition>
      <Header
        backAddress={backAddress}
        criadoEm={criadoEm}
        title={title}  
        rightContent={rightContent}    
      />
    </ContentPosition>
  )
}
