import React from "react";
import { useEffect, useRef, useState } from "react";
import { SelectAutoCompleteProps, SelectInputType } from "../../interfaces";
import axios, { CancelTokenSource } from "axios";
import SelectInput from "../SelectInput";
import { Endpoints, axiosInstance } from "api";
import { useTypedSelector } from "hooks";

export default function BuscaPagadores(props: SelectAutoCompleteProps) {
  const [searchedNames, setSearchedNames] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(
    props.defaultValue as string
  );
  const [isLoading, setIsLoading] = useState(false);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const auth = useTypedSelector((state) => state.auth);

	useEffect(() => {
		setInputValue(props.defaultValue as string)
	}, [props.defaultValue])

  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    setIsLoading(true);

    axiosInstance
      .get(
        `${Endpoints.pagamento.pagamento()}/recebedor/${
          auth.recebedor.id
        }/pagador?${inputValue.length > 0 ? `nome=${inputValue}` : ""}`,
        {
          cancelToken: cancelToken.current?.token,
        }
      )
      .then((data) => setSearchedNames(data.data))
      .catch((error) => {
        if (axios.isCancel(error)) return;
      })
      .finally(() => setIsLoading(false));

    return () => cancelToken.current?.cancel();
  }, [inputValue]);

  return (
    <SelectInput
      {...props}
      loading={isLoading}
      title={props.inputType === SelectInputType.mobile ?  props.title : "Buscar por nome"}
      placeholder={props.placeholder}
      inputType={props.inputType === SelectInputType.mobile ? SelectInputType.typeable : SelectInputType.mobile}
      options={searchedNames}
      defaultValue={props.defaultValue}
      onInputChange={(event) =>
        setInputValue(
          typeof event?.target?.value === "string" ? event?.target?.value : ""
        )
      }
      inputValue={inputValue}
      onChange={props.onChange}
    />
  );
}
