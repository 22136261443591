import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from './Dashboard'
import RecebimentosList from './RecebimentosList'
import DetalhesRecebimento from './DetalhesRecebimento'
import ExtratoSaldoFuturo from './ExtratoSaldoFuturo'

export default function Recebimentos() {
  return (
    <>
      <Switch>
        <Route
          path={RecebimentosRoutesPaths.base}
          component={Dashboard}
          exact
        />
        <Route
          path={`${RecebimentosRoutesPaths.detalhes}/:saqueId`}
          component={DetalhesRecebimento}
        />
        <Route
          path={`${RecebimentosRoutesPaths.listaSaques}`}
          component={RecebimentosList}
        />        
        <Route
          path={`${RecebimentosRoutesPaths.extratoSaldoFuturo}/:periodo`}
          component={ExtratoSaldoFuturo}
        />
      </Switch>
    </>
  )
}

export class RecebimentosRoutesPaths {
  static base = '/recebiveis'
  static listaSaques = `${RecebimentosRoutesPaths.base}/saques`
  static detalhes = `${RecebimentosRoutesPaths.listaSaques}/detalhes`
  static extratoSaldoFuturo = `${RecebimentosRoutesPaths.base}/saldo/extrato`
}
