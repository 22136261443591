import React from "react";
import {
  Wrapper,
  Title,
  TitleWrapper,
  ChartIcone,
  HeaderDivider,
  IconContent,
} from "./styles";

interface HeaderProps {
  showIcon: Boolean;
  title: string;
  Icon?: any;
  id: string;
}

export default function Header(props: HeaderProps) {
  const { showIcon, title, Icon, id } = props;
  return (
    <>
      <Wrapper id={id}>
        <TitleWrapper>
          {showIcon && !Icon && <ChartIcone />}
          {showIcon && <IconContent>{Icon}</IconContent>}
          <Title> {title} </Title>
        </TitleWrapper>
      </Wrapper>
      <HeaderDivider />
    </>
  );
}
