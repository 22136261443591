import React from "react";
import { Wrapper, Title, Paragraph, EntendiButton } from "./styles";
import { EstornoMessages } from "shared/messages";

interface ErroProps {
  titulo?: string | null;
  mensagem?: string | null;
  onClose: any
}
export default function Erro({
  titulo = EstornoMessages.cancelamentoNaoRealizadoTitulo,
  mensagem = EstornoMessages.cancelamentoNaoRealizadoTexto,
  onClose
}: ErroProps) {
  return (
    <Wrapper>
      <Title>{titulo}</Title>
      <Paragraph>{mensagem}</Paragraph>
      <EntendiButton
          variant="contained"
          onClick={() => onClose()}
        >
          Entendi
      </EntendiButton>
    </Wrapper>
  );
}
