import styled from 'styled-components';
import { Colors } from 'theme';

interface LabelProps {
  color?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
}

export const Label = styled.label<LabelProps>`
  color: ${({ color }) => (color ? color : Colors.typography)};
  font-size: 15px;
  display: flex;
  flex-direction: column;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'unset')};

  & > div {
    margin-top: 0.5rem;
  }
`;
