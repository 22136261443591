import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import { Button } from 'components';

export const BotaoSacar = styled(Button)`
  min-width: 150px;
  margin-bottom: 0.6rem;
`;

export const Valores = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;

  span {
    display: block;
  }
`;

export const Dados = styled.div`  
  padding: 1rem 0 1rem 0;

  span {
    display: block;
  }
`;

export const Prazo = styled.div`
  padding: 0.5rem 1rem 1rem 1rem; 
`;

export const SaqueProgramado = styled.div`
  padding: 0rem 1rem 1rem 1rem;
  font-weigth: 600;
`;

export const Descricao = styled.span`
  flex: 1;
`;

export const Valor = styled.span`
 flex: 1;  
`;

export const Item = styled.div`
  flex: 1;
  display: flex;
  padding-left: 1rem;
  padding-bottom: 0.3rem;
`;

export const Divisor = styled(Divider)`  
  margin: 0.4rem 1rem 0.4rem 1rem;
`;