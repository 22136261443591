import React from 'react'
import {
  Wrapper,
  Title,
  TitleWrapper,
  HeaderDivider,
  IconContent,
  RightContent
} from './styles'

interface HeaderProps {
  title: string
  icon?: any
  rightText?: string
}

const Header = ({ title, icon, rightText }: HeaderProps) => {
  return (
    <>
      <Wrapper>
        <TitleWrapper>
          {icon && <IconContent>{icon}</IconContent>}
          <Title> {title} </Title>
        </TitleWrapper>
        {rightText && (<RightContent>
          {rightText}
        </RightContent>)}
      </Wrapper>
      <HeaderDivider />
    </>
  )
}

export default Header;