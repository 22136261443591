import React, { lazy, Suspense, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AttachMoney } from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core'
import parseISO from 'date-fns/parseISO'
import { RecebimentoActions, ParamsRecebedor } from 'store/recebimento'
import { RecebimentosRoutesPaths } from '../Recebimentos'
import { useTypedSelector } from 'hooks'
import { isLoading } from 'utils'
import tableIcons from 'shared/tableIcons'
import { muiTableLocalization as localization } from 'shared/constants'
import StringUtils from 'utils/StringUtils'
import Loader from 'components/CenteredLoader';
import SubHeader from './SubHeader'
import Header from './Header'
import { PageWrapper } from 'shared/styles'
import { ContentLoading, ContentWrapperInfo, InfoWrapper, Cifra, Saldo, SmallSubtitle, SaldoWrapper } from './styles'
const MaterialTable = lazy(() => import('@material-table/core'));

export default function DetalhesRecebimento() {
  const params: ParamsRecebedor = useParams()
  const dispatch = useDispatch()
  const recebimento = useTypedSelector((state) => state.recebimento.recebimento)
  const recebedor = useTypedSelector((state) => state.auth.recebedor)

  const tableData = recebimento?.data?.recebiveis?.map((item: any) => {
    return { ...item }
  });

  useEffect(() => {
    dispatch(RecebimentoActions.getOneRecebimento({ ...params, id: recebedor.id || '' }))
  }, [dispatch, params, recebedor])

  const installmentFieldFromType = {
    chargeback: 'Chargeback',
    refund: 'Estorno',
  }

  const columns = useMemo(
    () => [
      { title: 'Nº operação', field: 'operacaoIdPagamento' },
      { title: 'Parcela', field: 'parcela', render: (row: any) => installmentFieldFromType[row.tipo] ?? row.parcela},
      { title: 'Nome do link', field: 'nomeLink' },
      { title: 'Nome', field: 'nomePagador' },
      { title: 'Data da Compra', field: 'dataHoraCompra' },
      { title: 'Valor da Parcela', field: 'valorParcela', render: (row: any) => StringUtils.format.formatBRL(row.valorParcela) },
      { title: 'Antecipação', field: 'valorAntecipacao', render: (row: any) => row.valorAntecipacao ? `- ${StringUtils.format.formatBRL(row.valorAntecipacao)}` : '' },
      { title: 'Valor Total', field: 'valorTotal', render: (row: any) => StringUtils.format.formatBRL(row.valorTotal) },
    ],
    []
  )

  const RightContent = () => {
    return (
      <SaldoWrapper>
        <InfoWrapper>
          <Cifra> R$ </Cifra>
          <Saldo>
            {StringUtils.format.formatNumber(recebimento.data?.valorSaldo)}
          </Saldo>
        </InfoWrapper>
        <SmallSubtitle>({StringUtils.format.formatBRL(recebimento.data?.valorLiquido)} + {StringUtils.format.formatBRL(recebimento.data?.valorTarifa)})</SmallSubtitle>
      </SaldoWrapper>)
  }

  return (
    <>
      {isLoading(recebimento.state) ? (
        <ContentLoading>
          <CircularProgress />
        </ContentLoading>
      ) : (
        <>
          <SubHeader
            backAddress={RecebimentosRoutesPaths.listaSaques}
            criadoEm={recebimento.data?.createdDate ? new Date(parseISO(recebimento.data?.createdDate)) : new Date()}
            title={`Saque ${recebimento.data?.saqueId}`}
            rightContent={<RightContent />}
          />
          <PageWrapper>
            <ContentWrapperInfo>
              <Header showIcon={true} Icon={<AttachMoney />} title={'Detalhes'} />
              <Suspense fallback={<Loader />}>
                <MaterialTable
                  title={'Detalhe Recebimento'}
                  icons={tableIcons}
                  columns={columns}
                  data={tableData}
                  options={{
                    draggable: false,
                    sorting: false,
                    search: false,
                    toolbar: false,
                    paging: false,
                    toolbarButtonAlignment: 'left',
                    showTitle: false,
                    headerStyle: {
                      fontWeight: 'bold',
                    },
                  }}
                  localization={localization}
                  isLoading={isLoading(recebimento.state)}
                  style={{
                    boxShadow: 'none',
                  }}
                />
              </Suspense>
            </ContentWrapperInfo>
          </PageWrapper>
        </>
      )}
    </>
  )
}
