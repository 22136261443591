import React, { useEffect, useRef, useState } from "react";
import { useRedirect, useTypedSelector } from "hooks";
import { useDispatch } from "react-redux";
import { PagamentoActions } from "store/pagamento/pagamento";
import { FilterOptions, Pagination, SelectInputType } from "./interfaces";
import { RoutesPaths } from "routes";
import { Chip, useMediaQuery } from "@material-ui/core";
import { isLoading } from "utils";
import { differenceInMinutes, format, isBefore } from "date-fns";
import { TransactionTable } from "shared/messages";
import { FiltersQueryParams } from "store/pagamento/interfaces";
import { useLocalForage } from "hooks/useLocalForage";
import { optionsDateRange, optionsModalidades, optionsStatus, translateStatus, translateType } from "./constants";
import axios, { CancelTokenSource } from "axios";
import Calendar from "./components/Calendar";
import theme from "theme";
import TableMobile from "./components/TableMobile";
import SelectInput from "./components/SelectInput";
import TableTransactions from "./components/Table";
import Header from "../Header";
import LembreteInformativo from "../LembreteInformativo";
import {
  Wrapper,
  ContentWrapperInfo,
  ContentWrapper,
  ContentTable,
  IconBack,
  ChipWrapper,
  ChipContent,
  DateWrapper,
  BlueCard,
  BlueCardContent,
  InfoIcon,
  ContentDateFilter
} from "./styles";
import BuscaPagadores from "./components/BuscaPagadores";
import GerarRelatorio from "./components/GerarRelatorio";

const initialStateFilter: FilterOptions = {
  page: 0,
  size: 5,
  sort: "",
  orderBy: "createdDate",
  orderDirection: "desc",
  name: "",
  status: "",
  modalidade: [],
  finalDate: new Date(),
  initialDate: (new Date()).setDate((new Date()).getDate() - 180) as unknown as Date,
  period: TransactionTable.periodTimeInitial
};

function getLimitDate(labelProp: string, finalDate: Date){
  const match = labelProp != null ? labelProp.match(/\d+/) : false;
  var limit: Date = new Date();
  if (match) {
      const elemento = Number(match);
      limit.setDate(new Date(finalDate).getDate() - (labelProp.includes("meses") ? elemento * 30 : elemento));
  }
  return limit;
}

export default function TransacoesList() {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const auth = useTypedSelector((state) => state.auth);
  const pagamentos = useTypedSelector((state) => state.pagamento.getPagamentoRecebedor);
  const [filters, editFilters, deleteFilter, isFiltersLoading] = useLocalForage<FilterOptions>("movimentacoes", {
    ...initialStateFilter,
    initialDate: getLimitDate(TransactionTable.periodTimeInitial, new Date()),
    finalDate: new Date(),
    requestedTime: new Date().toString(),
  });
  
  const [pagadores, setPagadores] = useState<string[]>([])
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const isFirstRendering = useRef(true);

  const setFilters = (newFilter: any) => {
    editFilters({
      ...filters,
      ...newFilter,
      requestedTime: new Date().toString()
    })
  } 

  const cleanFiltersStorage = () => {
    setFilters({
      ...filters,
      ...initialStateFilter,
    })
  }
  
  useEffect(() => {
    if (isFiltersLoading || !filters || !auth.recebedor.id) return;

    if (isFirstRendering.current && differenceInMinutes(new Date(), new Date(filters?.requestedTime ?? '')) > 5 ) {
      isFirstRendering.current = false;
      cleanFiltersStorage();
      return;
    }

    const sendFilters = {
      name: filters.name,
      initialDate: format(filters.initialDate, 'yyyy-MM-dd'),
      finalDate: format(filters.finalDate, 'yyyy-MM-dd'),
      modalidade: Array.isArray(filters.modalidade) ? filters.modalidade.map(type => translateType[type]).filter(type => Boolean(type)).toString() : '',
      status: Array.isArray(filters.status) ? filters.status.map(st => translateStatus[st]).filter(s => Boolean(s)).toString() : ''
    } as FiltersQueryParams;

    cancelToken.current = axios.CancelToken.source();
    dispatch(
      PagamentoActions.getPagamentoRecebedor(
        { sort: filters.sort, page: filters.page, size: filters.size },
        { id: auth?.recebedor.id },
        sendFilters,
        cancelToken.current
      )
    );

    return () => {
      cancelToken.current?.cancel();
    }
  }, [
    filters,
    isFiltersLoading,
    auth.recebedor.id
  ])

  const handleCleanDate = () => {
    setFilters({
      ...filters,
      initialDate: initialStateFilter.initialDate,
      finalDate: initialStateFilter.finalDate,
      period: TransactionTable.periodTimeInitial
    });
  }

  const handleDate = (data: Date[]) => {
    if (isBefore(data[1], data[0])) {
      return handleCleanDate();
    } 

    setFilters({ ...filters, initialDate: data[0], finalDate: data[1], period: TransactionTable.custom });
  }

  const handlePeriodSelectInput = (value: string) => {
    if (value === '' || value === null) handleCleanDate();
    if (!optionsDateRange.includes(value)) return;

    setFilters({
      ...filters,
      initialDate: getLimitDate(value, new Date()),
      finalDate: new Date(),
      period: value,
    });
  }

  if (isFiltersLoading) return (<div></div>)
  return (
    <Wrapper>

      <ContentWrapperInfo data-testid="header-icon-title">
        <Header showIcon={true}
          Icon={<IconBack onClick={() => redirect(`${RoutesPaths.dashboard}`)} />}
          title={TransactionTable.primaryTitle} />
      </ContentWrapperInfo>

      <ContentWrapper isMobile={isMobile.toString()} data-testid="content-select">
        <DateWrapper data-testid="calendar-desktop">
          <SelectInput title={TransactionTable.specificPeriod} placeholder={TransactionTable.selection}
            defaultValue={filters?.period ?? ''} inputType={SelectInputType.uniqueValue} options={optionsDateRange}
            onChange={handlePeriodSelectInput} />
          <Calendar onChange={(datas: Date[]) => { handleDate(datas) }} />
        </DateWrapper>
        <SelectInput
          title={TransactionTable.status}  placeholder={TransactionTable.selection} options={optionsStatus}
          onChange={(event: any) => setFilters({...filters, status: event})} defaultValue={filters?.status ?? ''} />
        {!isMobile &&
          <BuscaPagadores title={TransactionTable.nameTitle} placeholder={TransactionTable.nameTitle} inputType={SelectInputType.mobile}
          onChange={(event: any) => setFilters({ ...filters, name: event })} options={pagadores} defaultValue={filters?.name ?? ''} />
        }
        <SelectInput title={TransactionTable.modality} placeholder={TransactionTable.selection} options={optionsModalidades}
          onChange={(event: any) => setFilters({...filters, modalidade: event})} defaultValue={filters?.modalidade as string}/>
        
      </ContentWrapper>
        
        <ChipWrapper data-testid="chip-content" isMobile={isMobile.toString()}>
          <ChipContent >
            <ContentDateFilter>
              {filters?.initialDate && filters.finalDate &&
                <>
                  <Chip
                    label={format(filters.initialDate, 'dd/MM/yyyy')}
                    onDelete={filters.period !== 'Personalizado' ? undefined : handleCleanDate}
                    variant="outlined"
                  />
                  <Chip
                    label={format(filters.finalDate, 'dd/MM/yyyy')}
                    onDelete={filters.period !== 'Personalizado' ? undefined : handleCleanDate}
                    variant="outlined"
                  />
                </>
              }
            </ContentDateFilter>
          </ChipContent>
        </ChipWrapper>
      
        {isMobile &&
          <ContentWrapper isMobile={isMobile.toString()}>
            <BuscaPagadores title={TransactionTable.nameTitle} placeholder={TransactionTable.namePlaceholder} inputType={SelectInputType.typeable}
              onChange={(event: any) => setFilters({ ...filters, name: event })} options={pagadores} defaultValue={filters?.name ?? ''} />
          </ContentWrapper>
        }
       
      <ContentTable isMobile={isMobile.toString()}>
        {pagamentos?.data?.totalElements !== 0 || isLoading(pagamentos.state) ?
          isMobile ?
              <TableMobile
                data-testid="tableTransactionMobile"
                loading={isLoading(pagamentos.state)}
                pagamentos={pagamentos.data.pagamentos} 
                quantidadePagamentos={pagamentos?.data?.totalElements}
                onChange={(pagination: Pagination) => { setFilters({ ...filters, size: pagination.row, page: pagination.pageNumber }) }}
              />
            :
              <TableTransactions
                data-testid="tableTransaction"
                loading={isLoading(pagamentos.state)}
                pagamentos={pagamentos.data.pagamentos}
                quantidadePagamentos={pagamentos?.data?.totalElements}
                onChange={(pagination: Pagination) => { setFilters({ ...filters, size: pagination.row, page: pagination.pageNumber }) }}
              />
          :
          <BlueCard data-testid="content-blue">
            <BlueCardContent>
              <InfoIcon />
              {pagamentos.error != null ? TransactionTable.error : TransactionTable.noneTransaction}
            </BlueCardContent>
          </BlueCard>
        }

      </ContentTable>

      {filters && !isLoading(pagamentos.state) && <GerarRelatorio filters={filters} qtdMovimentacoes={pagamentos.data.totalElements}/>}
      
      <LembreteInformativo />
      
    </Wrapper>
  );
}