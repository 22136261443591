import { StateEnum } from "shared/enums";

export enum ExtratoRecebiveisOpened {
  SALDO_DISPONIVEL = "SALDO_DISPONIVEL",
  SALDO_A_RECEBER = "SALDO_A_RECEBER",
}
export interface ExtratoRecebiveisState {
  extratoOpened: ExtratoRecebiveisOpened;
  saldoAReceber: SaldoAReceberStore;
  saldoDisponivel: SaldoDisponivelStore;
}
export interface Sort {}

export interface ApiResult<T> {
  content: T[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export interface ApiResultSaldoDisponivel<T> {
  saldosDisponiveis: T[];
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}
export interface ApiResultSaldoAReceber {
  saldosFuturos: SaldoAReceberItem[];
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export interface LinkPagamento {
  idLink: string;
  nomeLink: string;
}

export interface SaldoAReceberItem {
  dataTransacaoSaldo: string;
  idTransacao: number;
  linkPagamento: LinkPagamento;
  nomePagador: string;
  dataVenda: string;
  valorBruto: number;
  parcela: string;
  valorParcela: number;
  valorAntecipacao: number;
  diasAntecipacao: number;
  valorLiquido: number;
}

export interface SaldoDisponivelItem {
  dataLiberacaoSaldo: string;
  idTransacao: number;
  linkPagamento: LinkPagamento;
  nomePagador: string;
  dataVenda: string;
  valorBruto: number;
  parcela: string;
  valorParcela: number;
  valorAntecipacao: number;
  diasAntecipacao: number;
  valorLiquido: number;
}

export interface IOptions {
  page: number;
  size: number;
  sort: string | null;
  orderBy: string;
  orderDirection: "asc" | "desc" | undefined;
}

export interface SaldoAReceberApiResult extends ApiResult<SaldoAReceberItem> {}
export interface SaldoDisponivelApiResult extends ApiResultSaldoDisponivel<SaldoDisponivelItem> {}

export interface SaldoAReceberStore {
  state: StateEnum;
  error: string | null;
  data: ApiResultSaldoAReceber;
}

export interface SaldoDisponivelStore {
  state: StateEnum;
  error: string | null;
  data: SaldoDisponivelApiResult;
}
export type QueryStringExtratoRecebiveis = {
  sort?: string | null
  totalPages?: number
  size?: number
  page?: number
}
