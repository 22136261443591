import styled from "styled-components";
import { Colors } from "theme";
import { TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

interface SelectAreaProps{
  textField?: string ;
}

export const SelectArea = styled.div<SelectAreaProps>`
  width: ${({textField}) => textField=="true" ? "120" : "80" }%;
  overflow: hidden;
`;

export const TextFieldStyled = styled(TextField)`
  white-space: nowrap;
`;

export const InputLabelElement = styled.div`
  font-size:0.7rem;
  color: ${Colors.lightTypography}
`;


export const CalendarArea = styled.div`
  font-size:0.7rem;
  color: ${Colors.lightTypography}
`;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: 250,
    },
  },
};


export const InputMobileName = styled.div`
  width:96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2% ;
  background-color: white;
  border-radius: 15px;
`;

interface InputProps{
  isMobile: string;
}

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiInput-underline:before {
    border-bottom: none; 
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none; 
  }
`;