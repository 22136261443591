import React from "react";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select
} from "@material-ui/core";
import { InputMobileName, SelectArea, TextFieldStyled, StyledAutocomplete } from "./styles";
import { SelectAutoCompleteProps, SelectInputType } from '../../interfaces';
import { Search } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";


export default function SelectInput(props: SelectAutoCompleteProps) {

    const renderSwitch = (param : SelectInputType) => {
        switch(param) {
            case 'Mobile':
                return (
                    <InputMobileName>
                        <Search data-testid="search-icon"/>
                        <StyledAutocomplete
                            freeSolo
                            style={{width:"100%"}}
                            options={props.options}
                            value={props.defaultValue}
                            onChange={(event: any, value: any) => props.onChange(value)}
                            onInputChange={props.onInputChange}
                            inputValue={props.inputValue}
                            renderInput={(params) => 
                                <TextFieldStyled {...params} placeholder={props.placeholder} 
                                />
                            }
                            loading={props.loading}
                            loadingText="Carregando"
                        />
                    </InputMobileName>
                );
            case "Typeable":
                return(
                    <SelectArea textField={"true"}>
                        <Autocomplete
                            freeSolo
                            options={props.options}
                            value={props.defaultValue as string}
                            getOptionLabel={(option) => option}
                            onChange={(event: any, value: any) => props.onChange(value)}
                            onInputChange={props.onInputChange}
                            inputValue={props.inputValue}
                            renderInput={(params) => 
                                <TextFieldStyled {...params} label={props.title} variant="standard"
                                />
                            }
                            loading={props.loading}
                            loadingText="Carregando"
                        />
                    </SelectArea>
                );
            case "UniqueValue":
                return(
                    <SelectArea>
                        <FormControl fullWidth >
                            <InputLabel>{props.title}</InputLabel>
                            <Select
                                data-testid="select-input"
                                value={[props.defaultValue]}
                                onChange={(event: any) => props.onChange(event.target.value)}
                                renderValue={(selected: any) => selected}
                                
                                >
                                {props.options.map((elemento) => (
                                    <MenuItem  key={elemento} value={elemento}>
                                        <ListItemText primary={elemento} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </SelectArea>
                );
            case "MultipleValue":
                return(
                    <SelectArea>
                    <FormControl fullWidth >
                        <InputLabel>{props.title}</InputLabel>
                        <Select
                            data-testid="select-input"
                            multiple
                            value={Array.isArray(props.defaultValue) ? props.defaultValue : []}
                            onChange={(event: any) => props.onChange(typeof event?.target?.value === 'string' ? event.target.value.split(",") : event.target.value)}
                            renderValue={(selected: any) => selected.join(', ')}
                        >
                            {props.options.map((elemento) => (
                                <MenuItem  key={elemento} value={elemento}>
                                    <Checkbox checked={props?.defaultValue?.includes(elemento)} />
                                    <ListItemText primary={elemento} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </SelectArea>
                );
            
            default:
                return(
                    <SelectArea>
                    <FormControl fullWidth >
                        <InputLabel>{props.title}</InputLabel>
                        <Select
                            data-testid="select-input"
                            multiple
                            value={Array.isArray(props.defaultValue) ? props.defaultValue : []}
                            onChange={(event: any) => props.onChange(typeof event?.target?.value === 'string' ? event.target.value.split(",") : event.target.value)}
                            renderValue={(selected: any) => selected.join(', ')}
                        >
                            {props.options.map((elemento) => (
                                <MenuItem  key={elemento} value={elemento}>
                                    <Checkbox checked={props?.defaultValue?.includes(elemento)} />
                                    <ListItemText primary={elemento} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </SelectArea>
                );
        }
      }

    return (
        <>  
            {renderSwitch(props.inputType as SelectInputType)}
        </>
    );
}
