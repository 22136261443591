import styled from 'styled-components';
import { Card } from 'components';

interface WrapperProps {
  backgroundColor?: string;
  color?: string;
  fullWidth?: boolean;
  onClick?: any;
}

export const Wrapper = styled(Card).attrs((_) => ({
  shadowed: false,
  rounded: true,
}))<WrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '140px')};
  height: 80px;
  color: ${({ color }) => (color ? color : '#fff')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  }

  span {
    font-size: 2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 1.15rem;
    font-weight: 600;
  }

  svg {
    font-size: 3rem;
    margin-right: 0.25rem;
  }
`;
