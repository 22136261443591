import { axiosInstance, Endpoints } from "api";
import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { StateEnum } from "shared/enums";
import { createErrorMessage } from "utils";
import { Action, ConsentimentoRequest, ConsentimentoResponse, InstituicaoParticipante, OpenFinanceState } from "./interfaces";

export enum OpenFinanceTypes {
    GET_INSTITUICOES_FINANCEIRAS = "openFinance/GET_INSTITUICOES_FINANCEIRAS",
    GET_INSTITUICOES_FINANCEIRAS_SUCCESS = "openFinance/GET_INSTITUICOES_FINANCEIRAS_SUCCESS",
    GET_INSTITUICOES_FINANCEIRAS_FAILED = "openFinance/GET_INSTITUICOES_FINANCEIRAS_FAILED",
    SELECT_INSTITUICAO_FINANCEIRA = "openFinance/SELECT_INSTITUICAO_FINANCEIRA",
    GET_INSTITUICAO_ESCOLHIDA = "openFinance/GET_INSTITUICAO_ESCOLHIDA",
    GET_INSTITUICAO_ESCOLHIDA_SUCCESS = "openFinance/GET_INSTITUICAO_ESCOLHIDA_SUCCESS",
    GET_INSTITUICAO_ESCOLHIDA_FAILED = "openFinance/GET_INSTITUICAO_ESCOLHIDA_FAILED",
    CREATE_CONSENTIMENTO = "openFinance/CREATE_CONSENTIMENTO",
    CREATE_CONSENTIMENTO_SUCCESS = "openFinance/CREATE_CONSENTIMENTO_SUCCESS",
    CREATE_CONSENTIMENTO_FAILED = "openFinance/CREATE_CONSENTIMENTO_FAILED"
};

const initialState: OpenFinanceState = {
    instituicoesFinanceiras: [],
    state: StateEnum.IDLE,
    instituicaoEscolhida: null,
    detalheInstituicaoEscolhida: {
        detalhe: null,
        state: StateEnum.IDLE,
    },
    consentimento: {
        state: StateEnum.IDLE,
        consentimento: null
    }
};

export function reducer(
    state = initialState,
    action: Action
): OpenFinanceState {
    switch (action.type) {
        case OpenFinanceTypes.GET_INSTITUICOES_FINANCEIRAS:
            return {
                ...state,
                state: StateEnum.LOADING
            };
        case OpenFinanceTypes.GET_INSTITUICOES_FINANCEIRAS_SUCCESS: 
            return {
                ...state,
                state: StateEnum.COMPLETE,
                instituicoesFinanceiras: action.payload
            };
        case OpenFinanceTypes.GET_INSTITUICOES_FINANCEIRAS_FAILED:
            return {
                ...state,
                state: StateEnum.ERROR,
            };
        case OpenFinanceTypes.SELECT_INSTITUICAO_FINANCEIRA:
            return {
                ...state,
                instituicaoEscolhida: action.payload
            }
        case OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA:
            return {
                ...state,
                detalheInstituicaoEscolhida: {
                    ...state.detalheInstituicaoEscolhida,
                    state: StateEnum.LOADING,
                }
            }
        case OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA_SUCCESS:
            return {
                ...state,
                detalheInstituicaoEscolhida: {
                    detalhe: action.payload,
                    state: StateEnum.COMPLETE
                }
            }
        case OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA_FAILED:
            return {
                ...state,
                detalheInstituicaoEscolhida: {
                    detalhe: null,
                    state: StateEnum.ERROR
                }
            }
        case OpenFinanceTypes.CREATE_CONSENTIMENTO: 
            return {
                ...state,
                consentimento: {
                    ...state.consentimento,
                    consentimento: null
                }
            }
        case OpenFinanceTypes.CREATE_CONSENTIMENTO_SUCCESS:
            return {
                ...state,
                consentimento: {
                    state: StateEnum.COMPLETE,
                    consentimento: null
                }
            }
        case OpenFinanceTypes.CREATE_CONSENTIMENTO_FAILED:
            return {
                ...state,
                consentimento: {
                    consentimento: null,
                    state: StateEnum.ERROR
                }
            }
        default: return state;
    }
}

export class OpenFinanceActions {
    static getInstituicoesFinanceiras() {
        return async (dispatch: Dispatch) => {
            try {
                dispatch({type: OpenFinanceTypes.GET_INSTITUICOES_FINANCEIRAS });
                const response = await axiosInstance.get(
                    `${Endpoints.openFinance.openFinance()}/instituicoes`
                )

                dispatch({
                    type: OpenFinanceTypes.GET_INSTITUICOES_FINANCEIRAS_SUCCESS,
                    payload: response.data
                })
            } catch(err) {
                const error = createErrorMessage(err);
                dispatch({
                    type: OpenFinanceTypes.GET_INSTITUICOES_FINANCEIRAS_FAILED,
                    payload: {error},
                });
            }
        }
    }

    static selectInstituicao(instituicao: InstituicaoParticipante|null) {
        return async(dispatch: Dispatch) => {
            dispatch({
                type: OpenFinanceTypes.SELECT_INSTITUICAO_FINANCEIRA,
                payload: instituicao
            });

            if (!instituicao) {
                dispatch({
                    type: OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA_SUCCESS,
                    payload: null
                })
                return;
            }

            dispatch({type: OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA});

            try {
                const response = await axiosInstance.get(`${Endpoints.openFinance.openFinance()}/instituicao/${instituicao?.idInstituicao}`)
                dispatch({
                    type: OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA_SUCCESS,
                    payload: response.data
                })
            } catch(err) {
                const error = createErrorMessage(err);
                dispatch({
                    type: OpenFinanceTypes.GET_INSTITUICAO_ESCOLHIDA_FAILED,
                    payload: error
                });
            }
        }
    }

    static createConsentimento(data: ConsentimentoRequest) {
        return async(dispatch: Dispatch) => {
            dispatch({
                type: OpenFinanceTypes.CREATE_CONSENTIMENTO,
            })

            try {
                const response: AxiosResponse<ConsentimentoResponse> = await axiosInstance.post(`${Endpoints.openFinance.openFinance()}/criar-consentimento`, data)
                dispatch({
                    type: OpenFinanceTypes.CREATE_CONSENTIMENTO_SUCCESS,
                    payload: response.data
                })

                const url = response.data.redirectUri;
                window.location.href = url;

            } catch(err) {
                const error = createErrorMessage(err);
                dispatch({
                    type: OpenFinanceTypes.CREATE_CONSENTIMENTO_FAILED,
                    payload: error
                });
            }
        }
    }
}