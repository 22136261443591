import { SelectOption } from 'components/SelectBase';

export interface PagamentoForm {
  pessoais: {
    nome: string;
    sobrenome: string;
    email: string;
    documento: string;
    tipoDocumento: string;
    ddd: string;
    telefone: string;
    aceiteTermo: boolean;
  },  
  endereco: {
    cep: string;
    rua: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
  };
  cartao: {
    numero: string;
    nome: string;
    validade: string;
    cvv: string;
    parcelas: SelectOption | undefined;
    brand: string;
  };
  formaPagamento: string;
}

export interface Params {
  id: string
}

export enum PaymentSteps {
  DETALHES_STEP = 0,
  PAGAMENTO_STEP = 1,  
}

export enum PaymentMethods {
  CARTAO = 'cartao',
  PIX = 'pix',
  BOLETO = 'boleto',  
}