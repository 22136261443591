import React from 'react';
import { Modal, Button } from 'components';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks';
import { AuthActions } from 'store/auth';

import { Header, Content, InfoIcon } from './styles';

export default function SessionUnauthorizedModal() {
  const dispatch = useDispatch();
  const isUnauthorized = useTypedSelector(
    (state) => state.auth.session.unauthorized,
  );

  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      disableCloseIcon
      onClose={() => null}
      open={isUnauthorized}
      noPadding
    >
      <Header>
        <InfoIcon />
        <h1>Sessão Não Autorizada</h1>
      </Header>
      <Content>
        <p>
          Você não possui acesso para acessar essa área com as credenciais
          informadas. Reveja elas e caso o problema persistir entre em contato
          com o suporte.
        </p>
        <Button onClick={() => dispatch(AuthActions.logout())} fullWidth>
          Entendi
        </Button>
      </Content>
    </Modal>
  );
}
