import * as React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Lista from "./Lista";

import { useTypedSelector } from "hooks";
import { AjudaActions } from "store/ajuda";
import { hasError, isLoading } from "utils";
import { IQuestoesProps, IParams } from "./interfaces";
import { PageWrapper } from "shared/styles";

const Questoes: React.FC<IQuestoesProps> = () => {
  const params: IParams = useParams();
  const dispatch = useDispatch();

  const questoes = useTypedSelector((state) => state.ajuda.questoes);

  React.useEffect(() => {
    dispatch(AjudaActions.getAjudaQuestoes({ categoria: params.id }));
  }, [dispatch, params.id]);

  return (
    <PageWrapper>
      <Lista
        title={`Ajuda com ${questoes?.data?.categoria?.descricao}`}
        loading={isLoading(questoes.state)}
        error={hasError(questoes.state)}
        msgError={questoes.error || ""}
        lista={questoes?.data?.questoes}
      />
    </PageWrapper>
  );
};

export default Questoes;
