import styled from 'styled-components'
import { Colors, Breakpoints } from 'theme'
import { Dialog } from '@material-ui/core'

export const Wrapper = styled(Dialog)`
  & .MuiPaper-root {
    max-width: ${Breakpoints.maxMedium};
  }  
`;

export const Header = styled.header`
  background-color: ${Colors.primary};
  color: ${Colors.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
  
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }  
`

export const Title = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 1rem;
`

export const ContentLoading = styled.section`  
  background-color: rgb(255, 255, 255, 0.1);  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  position: relative;
  height: 100%;
  width: 100%;
`

export const ContentButnError = styled.div`
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: 2rem;
    width: 100%;
  }
`

export const Brand = styled.img.attrs((props) => ({ draggable: false }))`
  height: 45px;
  width: auto;
  margin: 1rem 0 1rem 0;

  @media (max-width: ${Breakpoints.maxSmall}) {
    height: calc(45px * 0.8175);
  }
`

