import React from 'react';
import { Colors } from 'theme';

import ReactSelect, { ValueType, ActionMeta, components } from 'react-select';
import { CircularProgress } from '@material-ui/core';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CircularProgress size={15} />
    </components.DropdownIndicator>
  );
};

const Input = (dataTest?: string) => (props: any) => (
  <components.Input data-testid={dataTest} {...props} />
);

export interface SelectOption {
  value: any;
  label: any;
}

export interface SelectBaseProps {
  size?: 'normal' | 'large';
  value?: any;
  placeholder?: string;
  loading?: boolean;
  onChange: (
    value: ValueType<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ) => void;
  options: SelectOption[];
  noOptionsMessage?: string;
  'data-testid'?: string;
  error?: boolean;
  errorMsg?: string;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  [propType: string]: any;
}

export default function SelectBase(props: SelectBaseProps) {
  const {
    size,
    noOptionsMessage,
    loading,
    placeholder,
    isDisabled,
    error,
    menuPlacement = 'auto',
    ...rest
  } = props;

  const customComponents: any = loading
    ? {
        DropdownIndicator,
      }
    : { ...rest.components};
  customComponents.Input = Input(props['data-testid']);

  return (
    <ReactSelect
      {...rest}
      isDisabled={loading || isDisabled}
      placeholder={loading ? 'Carregando...' : placeholder}
      noOptionsMessage={() => noOptionsMessage || 'Sem opções'}
      styles={{        
        control: (s) => ({
          ...s,
          height: size === 'large' ? 56 : 40,
          borderColor: error ? Colors.danger : s.borderColor,      
          ...rest.styles?.control,
        }),
        menu: (provided) => ({...provided, zIndex: 999})      
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: `${Colors.primary}`,
          primary50: `${Colors.activeGrey}`,
          primary25: `${Colors.lighterGrey}`,
          danger: `${Colors.danger}`,
          neutral30: '#000',
          ...rest.theme?.colors,
        },
      })}
      components={customComponents}
      menuPlacement={menuPlacement}
    />
  );
}
