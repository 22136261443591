import styled from 'styled-components'
import { Colors } from 'theme'
import { AccountBalanceWallet } from '@material-ui/icons'
import { Divider } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  margin-top: 0.2rem;
`

export const Title = styled.span`
  color: ${Colors.lightTypography};
  font-size: 1.3rem;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
`

export const ChartIcone = styled(AccountBalanceWallet)`
  font-size: 1.7rem;
  margin-right: 0.5rem;
  color: ${Colors.unicredGoldish};
`

export const IconContent = styled.div`
  display: flex;
  font-size: 1.7rem;
  margin-right: 0.5rem;
  color: ${Colors.unicredGoldish};
`

export const HeaderDivider = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`
