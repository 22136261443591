import styled from "styled-components";
import { Breakpoints, Colors } from "theme";
import { Divider } from "@material-ui/core";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 0.2rem;
  align-items: center;

  //@media (max-width: ${Breakpoints.maxSmall}) {
  //  flex-direction: column;
  //}
`;

export const Title = styled.span`
  color: ${Colors.lightTypography};
  font-size: 1.3rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const IconContent = styled.div`
  display: flex;
  font-size: 1.7rem;
  margin-right: 0.5rem;
  color: ${Colors.unicredGoldish};
`;

export const HeaderDivider = styled(Divider)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const RightContent = styled.div`
  flex: 1;
  font-size: 0.7rem;
  padding-top: 0.8rem;
  color: #706f80;
  text-align: right;

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 0.6rem;
    text-align: left;
  }
`;
