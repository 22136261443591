import { ConfigActions } from "store/config";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { Dispatch } from "redux";
import {
  differenceInMilliseconds,
  differenceInSeconds,
  differenceInMinutes,
  fromUnixTime,
  isBefore,
} from "date-fns";
import { StateEnum } from "shared/enums";
import { Action } from "store/interfaces";
import {
  LoginSuccessParams,
  LoginParams,
  AuthState,
  Recebedor,
} from "store/auth/interfaces";
import { Endpoints } from "api";
import { JWT } from "shared/interfaces";
import { logger, createErrorMessage } from "utils";
import { RecebedorActions } from "store/recebedor";
import KeycloakService from "KeycloakService";

export enum AuthTypes {
  LOGIN = "auth/LOGIN",
  LOGIN_SUCCESS = "auth/LOGIN_SUCCESS",
  LOGIN_FAILED = "auth/LOGIN_FAILED",
  LOGIN_ERROR_HANDLED = "auth/LOGIN_ERROR_HANDLED",
  LOGIN_BY_URL = "auth/LOGIN_BY_URL",
  LOGIN_BY_URL_SUCCESS = "auth/LOGIN_BY_URL_SUCCESS",
  LOGIN_BY_URL_FAILED = "auth/LOGIN_BY_URL_FAILED",
  LOGIN_BY_URL_ERROR_HANDLED = "auth/LOGIN_BY_URL_ERROR_HANDLED",
  LOGIN_METODO = "auth/LOGIN_METODO",
  RESET_LOGIN_BY_URL = "auth/RESET_LOGIN_BY_URL",
  LOGOUT = "auth/LOGOUT",
  RESET = "auth/RESET",
  CHECK_SESSION = "auth/CHECK_SESSION",
  CHECK_SESSION_SUCCESS = "auth/CHECK_SESSION_SUCCESS",
  CHECK_SESSION_FAILED = "auth/CHECK_SESSION_FAILED",
  SESSION_EXPIRED = "auth/SESSION_EXPIRED",
  SESSION_UNAUTHORIZED = "auth/SESSION_UNAUTHORIZED",
  SET_RECEBEDOR = "auth/SET_RECEBEDOR",
  SET_RECEBEDOR_RAV = "auth/SET_RECEBEDOR_RAV",
}

const initialState: AuthState = {
  metodoLogin: {
    keycloak: true
  },
  login: {
    state: StateEnum.IDLE,
    error: null,
  },
  loginByUrl: {
    state: StateEnum.IDLE,
    error: null,
  },
  accessToken: "",
  recebedor: {
    id: "",
    nome: "",
    numConta: "",
    agencia: 0,
    cooperativa: 0,
    conta: "",
    configuracao: "",
    splitRule: {
      saque: 0,
    },
    splitRulesTable: [],
    uuidBeneficiario: "",
    dataAlertaChargeback: "",
    leituraChargeback: false,
    dataLeituraChargeback: "",
    renderAlert:false
  },
  isRecebedor: false,
  user: {
    nome: "",
    cooperativa: 0,
    agencia: "",
    conta: "",
  },
  cooperativa: 0,
  plataforma: "",
  session: {
    expired: false,
    unauthorized: false,
    state: StateEnum.IDLE,
  },
};

export function authReducer(state = initialState, action: Action): AuthState {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, login: { ...state.login, state: StateEnum.LOADING } };
    case AuthTypes.LOGIN_BY_URL_SUCCESS:
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginByUrl: { ...state.loginByUrl, state: StateEnum.IDLE },
        login: { ...state.login, state: StateEnum.IDLE },
        accessToken: action.payload.accessToken,
        recebedor: action.payload.recebedor,
        isRecebedor: action.payload.isRecebedor,
        cooperativa: action.payload.cooperativa,
        user: action.payload.user,
        plataforma: action.payload.plataforma,
      };
    case AuthTypes.LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case AuthTypes.LOGIN_ERROR_HANDLED:
      return {
        ...state,
        login: { ...state.login, state: StateEnum.IDLE, error: null },
      };
    case AuthTypes.LOGIN_BY_URL:
      return {
        ...state,
        loginByUrl: {
          ...state.loginByUrl,
          state: StateEnum.LOADING,
        },
      };
    case AuthTypes.LOGIN_BY_URL_FAILED:
      return {
        ...state,
        loginByUrl: {
          ...state.loginByUrl,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case AuthTypes.LOGIN_BY_URL_ERROR_HANDLED:
      return {
        ...state,
        loginByUrl: { ...state.loginByUrl, error: null },
      };
    case AuthTypes.LOGIN_METODO: 
      return {
        ...state,
        metodoLogin: {
          keycloak: action.payload
        }
      }
    case AuthTypes.LOGOUT:
      return {
        ...initialState,
        session: {
          ...initialState.session,
          state: StateEnum.COMPLETE,
        },
      };
    case AuthTypes.CHECK_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          state: StateEnum.LOADING,
        },
      };
    case AuthTypes.CHECK_SESSION_SUCCESS:
      return {
        ...state,
        session: {
          ...state.session,
          state: StateEnum.COMPLETE,
        },
      };
    case AuthTypes.CHECK_SESSION_FAILED:
      return {
        ...state,
        session: {
          ...state.session,
          state: StateEnum.ERROR,
        },
      };
    case AuthTypes.SESSION_EXPIRED:
      return {
        ...state,
        session: {
          ...state.session,
          expired: true,
        },
      };
    case AuthTypes.SESSION_UNAUTHORIZED:
      return {
        ...state,
        session: {
          ...state.session,
          unauthorized: true,
        },
      };
    case AuthTypes.SET_RECEBEDOR:
      return {
        ...state,
        recebedor: action.payload.recebedor,
        isRecebedor: action.payload.isRecebedor,
      };
    case AuthTypes.RESET_LOGIN_BY_URL:
      return {
        ...state,
        loginByUrl: initialState.loginByUrl,
      };
    case AuthTypes.RESET:
      return initialState;
    default:
      return state;
  }
}

export class AuthActions {
  static loginStarted(): Action {
    return { type: AuthTypes.LOGIN };
  }

  static loginFailed(error: string): Action {
    return {
      type: AuthTypes.LOGIN_FAILED,
      payload: { error },
    };
  }

  static reset(): Action {
    return { type: AuthTypes.RESET };
  }

  static resetLoginByUrl(): Action {
    return { type: AuthTypes.RESET_LOGIN_BY_URL };
  }
  static handleLoginError(): Action {
    return { type: AuthTypes.LOGIN_ERROR_HANDLED };
  }

  static logout(): any {
    return async (dispatch: Dispatch) => {
      dispatch({type: AuthTypes.LOGOUT})
      
      if (KeycloakService.isAuthenticated()) {
        KeycloakService.logout();
      }

    }
  }

  static sessionExpired(): Action {
    return { type: AuthTypes.SESSION_EXPIRED };
  }

  static sessionUnauthorized(): Action {
    return { type: AuthTypes.SESSION_UNAUTHORIZED };
  }

  static checkSessionSuccess(): Action {
    return { type: AuthTypes.CHECK_SESSION_SUCCESS };
  }

  static checkSessionFailed(error: string): Action {
    return { type: AuthTypes.CHECK_SESSION_FAILED, payload: { error } };
  }

  static checkSessionStarted(): Action {
    return { type: AuthTypes.CHECK_SESSION };
  }

  static setIsRecebedor(data: Recebedor, isRecebedor: boolean): Action {
    return {
      type: AuthTypes.SET_RECEBEDOR,
      payload: { recebedor: data, isRecebedor },
    };
  }

  static loginSuccess({
    accessToken,
    recebedor,
    isRecebedor,
    cooperativa,
    user,
  }: LoginSuccessParams): Action {
    return {
      type: AuthTypes.LOGIN_SUCCESS,
      payload: {
        accessToken,
        recebedor,
        isRecebedor,
        cooperativa,
        user,
      },
    };
  }

  static login({ cooperativa, senha, usuario, conta, agencia }: LoginParams) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(AuthActions.loginStarted());
        const { data } = await axios.post(Endpoints.auth.token(), {
          username: usuario,
          password: senha,
          conta,
          cooperativa,
          agencia,
        });

        clearInterval(AuthActions.checkSessionTimeoutID);
        dispatch(AuthActions.checkSession(data.token));
        dispatch(
          AuthActions.loginSuccess({
            accessToken: data.token,
            recebedor: data.recebedor,
            isRecebedor: data.isRecebedor,
            user: data.user,
            cooperativa,
          })
        );
        dispatch(ConfigActions.getConfig());
        dispatch(RecebedorActions.setRecebedor(data.recebedor));
      } catch (err) {
        dispatch(AuthActions.loginFailed(createErrorMessage(err)));
      }
    };
  }

  static checkSessionTimeoutID: number;

  static checkSession(token: string): any {
    return (dispatch: Dispatch) => {
      try {
        const decodedToken: JWT = jwtDecode(token);
        const now = new Date();
        const jwtDate = fromUnixTime(decodedToken.exp);

        if (isBefore(jwtDate, now)) {
          clearInterval(AuthActions.checkSessionTimeoutID);
          dispatch(AuthActions.checkSessionSuccess());
          dispatch(AuthActions.sessionExpired());
        } else {
          dispatch(AuthActions.checkSessionSuccess());
          AuthActions.checkSessionTimeoutID = setTimeout(
            () => dispatch(AuthActions.checkSession(token)),
            differenceInMilliseconds(jwtDate, now)
          );

          logger.info(
            `Tempo restante da sessão: ${differenceInSeconds(
              jwtDate,
              now
            )} segundos 
            ou ${differenceInMinutes(jwtDate, now)} minutos`
          );
        }
      } catch (err) {
        clearInterval(AuthActions.checkSessionTimeoutID);
        dispatch(AuthActions.checkSessionFailed(err.message));
      }
    };
  }

  static loginByUrl({
    token,
    cooperativa,
    usuario,
    conta,
    agencia,
    plataforma,
  }: any) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: AuthTypes.LOGIN_BY_URL });

        logger.info(`[loginByUrl] POST ${Endpoints.auth.login()}`);

        const { data } = await axios.post(Endpoints.auth.login(), {
          username: usuario,
          conta,
          cooperativa,
          agencia,
          token,
        });

        clearInterval(AuthActions.checkSessionTimeoutID);
        dispatch(AuthActions.checkSession(data.token));

        dispatch({
          type: AuthTypes.LOGIN_BY_URL_SUCCESS,
          payload: {
            accessToken: data.token,
            recebedor: data.recebedor,
            isRecebedor: data.isRecebedor,
            user: data.user,
            cooperativa: data.cooperativa,
            plataforma,
          },
        });

        dispatch(ConfigActions.getConfig());
        dispatch(RecebedorActions.setRecebedor(data.recebedor));
      } catch (err) {
        clearInterval(AuthActions.checkSessionTimeoutID);
        dispatch({
          type: AuthTypes.LOGIN_BY_URL_FAILED,
          payload: createErrorMessage(err),
        });
      }
    };
  }

  static loginPorKeycloak(value: boolean): Action {
    return {
      type: AuthTypes.LOGIN_METODO,
      payload: value
    }
  }
}
