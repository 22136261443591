import React, { useEffect, useRef, useState } from "react";
import { FilterOptions } from "../../interfaces";
import { Grid, useMediaQuery } from "@material-ui/core";
import { Button, Modal } from "components";
import { useTypedSelector } from "hooks";
import { format } from "date-fns";
import { translateStatus, translateType } from "../../constants";
import { FiltersQueryParams } from "store/pagamento/interfaces";
import { Endpoints, axiosInstance } from "api";
import { CsvExport } from "pages/Relatorios/styles";
import { CSVLink } from "react-csv";
import theme from "theme";

export interface GerarRelatorioProps {
  filters: FilterOptions;
	qtdMovimentacoes: number;
}

export default function GerarRelatorio({ filters, qtdMovimentacoes }: GerarRelatorioProps) {
  const plataforma = useTypedSelector((state) => state.auth.plataforma);
  const auth = useTypedSelector((state) => state.auth);
  const MAX_ITEMS = 1000;
  const [isLoading, setIsLoading] = useState(false);
  const [typeReport, setTypeReport] = useState<"" | "basic" | "advanced">("");
  const [reportData, setReportData] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const mediaQuery = useMediaQuery(theme.breakpoints.down("xs"));

  let csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const headersBasicReport = [
    { label: "Data", key: "dataCriacao" },
    { label: "Status", key: "tipoMovimentacao" },
    { label: "Nome", key: "nomePagador" },
    { label: "Modalidade", key: "modalidade" },
    { label: "Valor", key: "total" },
  ];

  const headersAdvancedReport = [
    { label: "Data", key: "dataCriacao" },
    { label: "Nome Cadastro", key: "nomePagador" },
    { label: "Portador do Cartão", key: "nomeCartao" },
    { label: "Documento", key: "documento" },
    { label: "Modalidade", key: "modalidade" },
    { label: "Status", key: "tipoMovimentacao" },
    { label: "Valor Pago", key: "valor" },
    { label: "Desconto", key: "tarifaUnicred" },
    { label: "Valor a receber", key: "total" },
    { label: "Link", key: "link" },
    { label: "Operacao", key: "operacaoId" },
    { label: "Bandeira", key: "bandeira" },
    { label: "Parcelamento", key: "parcelas" },
    { label: "Descrição pagamento", key: "descricao" },
    { label: "Email", key: "email" },
    { label: "Telefone", key: "telefone" },
    { label: "Endereço", key: "endereco" },
    { label: "Numero / Complemento", key: "numeroComplemento" },
    { label: "CEP", key: "cep" },
    { label: "Cidade", key: "cidade" },
    { label: "Estado", key: "estado" },
  ];

  function handleBasicReport() {
    getReport("basic");
  }

  function handleAdvancedReport() {
    getReport("advanced");
  }

  async function getReport(type: typeof typeReport) {

		if (qtdMovimentacoes > 1000) {
			setShowModal(true);
			return;
		} 
    const requestFilters = {
      name: filters.name,
      initialDate: format(filters.initialDate, "yyyy-MM-dd"),
      finalDate: format(filters.finalDate, "yyyy-MM-dd"),
      modalidade: Array.isArray(filters.modalidade)
        ? filters.modalidade
            .map((type) => translateType[type])
            .filter((type) => Boolean(type))
            .toString()
        : "",
      status: Array.isArray(filters.status)
        ? filters.status
            .map((st) => translateStatus[st])
            .filter((s) => Boolean(s))
            .toString()
        : "",
      page: 0,
      size: MAX_ITEMS,
    } as FiltersQueryParams;

    setIsLoading(true);
    setTypeReport(type);

    axiosInstance
      .get(
        `${Endpoints.pagamento.pagamento()}/recebedor/${auth.recebedor.id}`,
        {
          params: requestFilters,
        }
      )
      .then((data) => setReportData(data.data.pagamentos))
      .catch(() => {setError(true); setShowModal(true)})
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (Array.isArray(reportData) && reportData.length > 0) {
      setTimeout(() => {
        csvLinkRef.current?.link?.click();
        setShowModal(true);
      });
    }
  }, [reportData]);

  const handleCloseModal = () => {
    setShowModal(false);
    setError(false);

    setTimeout(() => {
      setTypeReport("");
      setReportData([]);
    }, 500);
  };

  function getModalTitle() {
    const text = {
      excedido: "Período de relatório excedido",
      basic: "Relatório resumido",
      advanced: "Relatório completo",
    };

    return text[typeReport] ? text[typeReport] : text["excedido"];
  }

  function getModalDescription() {
    const text = {
      excedido:
        "Foram identificados muitos registros nessa consulta, informe um período menor para gerar o relatório escolhido.",
      basic: "Seu relatório foi gerado com sucesso",
      advanced: "Seu relatório foi gerado com sucesso",
			error: 'Não foi possível gerar o relatório no período selecionado. Tente novamente mais tarde'
    };

    return text[typeReport] 
			? error ? text['error'] : text[typeReport] 
			: text["excedido"];
  }

  if (Boolean(plataforma)) return <></>;

  return (
    <>
      <Grid
        container
        justify="flex-end"
        style={{ marginTop: "1.6rem", gap: "1rem" }}
      >
        <CsvExport
          style={{ visibility: "hidden" }}
          data={reportData}
          headers={
            typeReport === "basic" ? headersBasicReport : headersAdvancedReport
          }
          ref={csvLinkRef}
          filename={`relatorio_movimentacoes_${typeReport === 'basic' ? 'resumido' : 'completo'}_${format(
            filters.initialDate,
            "yyyy-MM-dd"
          )}_${format(filters.finalDate, "yyyy-MM-dd")}`}
        />
        <Button
          id="relatorio-resumido"
          data-testid="gerar-relatorio-resumido"
          disabled={isLoading}
          onClick={handleBasicReport}
          variant="outlined"
          loading={isLoading && typeReport === "basic"}
        >
          Relatório Resumido
        </Button>

        <Button
          id="relatorio-completo"
          data-testid="gerar-relatorio-completo"
          disabled={isLoading}
          onClick={handleAdvancedReport}
          loading={isLoading && typeReport === "advanced"}
        >
          Relatório Completo
        </Button>
      </Grid>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        title={getModalTitle()}
        fullScreen={mediaQuery}
				disableCloseIcon
      >
        <Grid container direction="column">
          <p>{getModalDescription()}</p>

          <Button style={{ marginLeft: "auto" }} onClick={handleCloseModal}>
            Entendi
          </Button>
        </Grid>
      </Modal>
    </>
  );
}
