import React from 'react'

import {
  DetalhesButton,
  MobileButton,
  DetalhesIcon,
  ContentMobile,
  PaymentIcon,
  TooltipUi,
  ContentPosition,
} from './styles'
import { Header } from 'components'
import { useMediaQuery, useTheme } from '@material-ui/core'

interface SubHeaderProps {
  criadoEm: Date
  backAddress: string
  title: string
  onClickDetalhes: () => void
  onClickPagamentos: () => void
  active: string
}
export default function SubHeader(props: SubHeaderProps) {
  const {
    criadoEm,
    backAddress,
    title,
    onClickDetalhes,
    onClickPagamentos,
    active,
  } = props
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ContentPosition>
      <Header
        backAddress={backAddress}
        criadoEm={criadoEm}
        title={title}
        rightContent={
          <>
            {mobile ? (
              <ContentMobile>
                <TooltipUi title="Detalhes">
                  <MobileButton onClick={onClickDetalhes} aria-label="Detalhes">
                    <DetalhesIcon
                      color={active === 'detalhe' ? 'primary' : 'disabled'}
                    />
                  </MobileButton>
                </TooltipUi>
                <TooltipUi title="Pagamentos">
                  <MobileButton
                    onClick={onClickPagamentos}
                    aria-label="Pagamentos"
                  >
                    <PaymentIcon
                      color={active === 'pagamento' ? 'primary' : 'disabled'}
                    />
                  </MobileButton>
                </TooltipUi>
              </ContentMobile>
            ) : (
              <>
                <DetalhesButton
                  onClick={onClickDetalhes}
                  variant={active === 'detalhe' ? 'contained' : 'outlined'}
                >
                  Detalhes
                </DetalhesButton>
                <DetalhesButton
                  onClick={onClickPagamentos}
                  variant={active === 'pagamento' ? 'contained' : 'outlined'}
                >
                  Pagamentos
                </DetalhesButton>
              </>
            )}
          </>
        }
      />
    </ContentPosition>
  )
}
