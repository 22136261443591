declare global {
  interface Window {
    config: {
      REACT_APP_ENVIRONMENT: string;
      REACT_APP_STORAGE_VERSION: string;
    };
  }
}

class Environment {
  getConfigs(): any {
    return {
      env: window.config?.REACT_APP_ENVIRONMENT,
      version: window.config?.REACT_APP_STORAGE_VERSION,
    };
  }
}

export default new Environment();
