import React from 'react';
import { useRedirect } from 'hooks';
import { RoutesPaths } from 'routes';

import { Wrapper, BrandUnicred, NavItems, ContentWrapper, BurgerButton } from './styles';
import ProfileMenu from './ProfileMenu';
import { Menu } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { UiActions } from 'store/ui';

const AppBar = () => {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <ContentWrapper>
        <BrandUnicred onClick={() => redirect(RoutesPaths.dashboard)} />
        <BurgerButton onClick={() => dispatch(UiActions.showDrawer())}>
          <Menu />
        </BurgerButton>
        <NavItems>
          <ProfileMenu />
        </NavItems>
      </ContentWrapper>
    </Wrapper>
  );
}

export default AppBar