import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";


export const Wrapper = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const TableRowStyled = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background-color: #F5F5F5;
  }
`;

interface TableCellProps {
  columntotal?: string ;
}

export const TableCellStyled  = styled(TableCell)<TableCellProps>`
  padding-right: ${({columntotal}) => columntotal=="true" ? "3rem" : "0rem"};
  text-align: ${({columntotal}) => columntotal=="true" ? "right" : "left"};
`;