import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { HelpOutlineOutlined } from "@material-ui/icons";
import { Accordion } from "components";
import { Button } from 'components'

import {
  ListaItem,
  ListaItemText,
  ListaItemIcon,
  IconError,
  ContentOverlay,
  WrapperContainer,
  IconBack,
  ButtonWrapper,
  BlueCard,
  InfoIcon
} from "./styles";

import Header from "../../Header";
import { useRedirect, useTypedSelector } from "hooks";
import { AjudaRoutesPaths } from "pages/Ajuda/Ajuda";
import { IQuestao } from "../interfaces";
import Questao from "pages/Ajuda/Questao";
import { useDispatch } from "react-redux";
import { RecebedorActions } from "store/recebedor";
import { format } from "date-fns";

export interface IListaProps {
  lista: Array<IQuestao>;
  loading: boolean;
  error: boolean;
  msgError: string;
  title: string;
}

const Lista: React.FC<IListaProps> = (props: IListaProps) => {

  const recebedor = useTypedSelector((state) => state.recebedor.recebedor);
  const { lista, loading, error, msgError, title } = props;
  const [isLoading, setIsLoading] = useState<boolean>(props.loading)
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const handleConfirmRead = async  () => {
    setIsLoading(true)
    await dispatch(RecebedorActions.confirmarLeituraAjuda(recebedor.id));
    setIsLoading(false)
  }

  const dataFormatada = format(new Date(recebedor?.dataLeituraChargeback), 'dd/MM/yy \'às\' HH:mm');

  return (
    <WrapperContainer id="wrapper_container_questoes">
      <Header
        id="wrapper_container_header_questoes"
        title={title}
        showIcon={true}
        Icon={
          <>
            <IconBack onClick={() => redirect(`${AjudaRoutesPaths.base}`)} />
            <HelpOutlineOutlined />
          </>
        }
      />
      <>
        {lista?.length > 0 ? (
          lista.map((questao) => (
            <Accordion
              key={questao.id}
              marginTop={false}
              titulo={<div>{questao.pergunta}</div>}
              detailStyles={{ margin: 0 }}
            >
              <Questao id={`${questao.id}`} />
            </Accordion>
          ))
        ) : (
          <ListaItem id="lista_questoes_item_not_found" dense>
            <ListaItemIcon id="lista_questoes_icon_not_found">
              <IconError />
            </ListaItemIcon>
            <ListaItemText
              primary="Nenhum item encontrado."
              id="lista_questoes_item_text_not_found"
            />
          </ListaItem>
        )}
        {lista?.find((questao) => questao.idCategoria === "chargeback") ? (
          <>
          {!recebedor?.leituraChargeback ? 
            (<ButtonWrapper>
                Li e entendi as informações.
                <Button
                  name="confirmar_chargeback"
                  type="submit"
                  loading={isLoading}
                  onClick={handleConfirmRead}
                >
                  Confirmar
                </Button>
            </ButtonWrapper>)
            : 
            (<BlueCard>
              <InfoIcon/>
              Informações lidas em {dataFormatada} 
            </BlueCard>)
          }
          </>
        ) : (
          <></>
        )}
        {loading  && (
          <ContentOverlay>
            <CircularProgress />
          </ContentOverlay>
        )}
        {error && (
          <ContentOverlay>
            <Alert severity="error">{msgError} </Alert>
          </ContentOverlay>
        )}
      </>
    </WrapperContainer>
  );
};

export default Lista;
