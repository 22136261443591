import styled from 'styled-components';
import { Colors, Breakpoints } from 'theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 58px;

  @media (max-width: ${Breakpoints.maxSmall}) {
    min-height: 58px;
    height: auto;
  }
`;

export const Title = styled.h1`
  margin: 0;
  color: ${Colors.dark};
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
`;

export const Divider = styled.div`
  height: 2px;
  background-color: ${Colors.lightGrey};
  width: 100%;
  margin-top: auto;
`;
