import React from 'react'
import { Button } from 'components'

import { Header, Content, InfoIcon } from './styles'
import { DialogActions, DialogTitle } from '@material-ui/core'

import { DeleteModal } from '../Interfaces'
import { hasError, isLoading } from 'utils'
import { Alert } from '@material-ui/lab'
import { useTypedSelector } from 'hooks'

export default function Delete(props: DeleteModal) {
  const { onClose, title, question, onConfirm } = props
  const stateDelete = useTypedSelector((state) => state.linkPagamento.delete)
  return (
    <>
      <Header>
        <InfoIcon />
        <DialogTitle>{title}</DialogTitle>
      </Header>
      <Content>
        {hasError(stateDelete.state) ? (
          <Alert severity="error">{stateDelete.error} </Alert>
        ) : (
          <p>{question}</p>
        )}
      </Content>
      <DialogActions>
        <Button onClick={() => onClose()} fullWidth color={'default'}>
          Cancelar
        </Button>
        <Button
          onClick={() => onConfirm()}
          loading={isLoading(stateDelete?.state)}
          loadingMessage={'Aguarde..'}
          fullWidth
        >
          Confirmar
        </Button>
      </DialogActions>
    </>
  )
}
