import { InfoOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { Colors } from "theme";

export const Container = styled.div`
    width: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem auto;

    p {
        color: ${Colors.typography};
    }
`;

export const Info = styled(InfoOutlined) `
    color: ${Colors.info};
    font-size: 6rem;
`;

export const Title = styled.h2`
    font-style: bold;
    font-size: .875rem;
    margin-top: 0;
    color: ${Colors.typography};
`;