import React from 'react';
import { AccordionDetailsStyled, AccordionStyled, Title } from './styles';
import { AccordionProps as AccordionMuiProps, AccordionSummary } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';

interface AccordionProps extends AccordionMuiProps{
    marginTop?: boolean;
    titulo?: string | JSX.Element;
    children: JSX.Element | JSX.Element[];
    expandControl?: () => void;
    detailStyles?: any;
}

export function Accordion({marginTop = false, titulo, children, expandControl, detailStyles, ...props}: AccordionProps) {

    return (
        <AccordionStyled margintop={marginTop.toString()} {...props}>
            <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{padding: '0 5px'}}
                  onClick={() => {if (expandControl) expandControl()}}
                >
                  {titulo && typeof titulo != "string" ? titulo : <Title>{titulo}</Title>}
                </AccordionSummary>
                <AccordionDetailsStyled style={detailStyles}>
                    {children}
                </AccordionDetailsStyled>
        </AccordionStyled>
    )
}