import { addDays, differenceInDays, endOfDay, format, isAfter, isSameDay, isValid, startOfDay } from "date-fns";
import { formatarComBarra } from "./inputDate";

export const validadeParaData = (validade: string|number, dataCriacao?: string) => {
    const numberValidade = Number(validade);

    if(isNaN(numberValidade) || (dataCriacao && !isValid(new Date(dataCriacao)))) return;

    const dataCriacaoLink = dataCriacao ? 
        new Date(formatarComBarra(dataCriacaoPraDataValida(dataCriacao))) 
        : new Date();

    return format(addDays(dataCriacaoLink, numberValidade), 'yyyy-MM-dd');
}

export const dataParaValidade = (dataFormatada: string, dataCriacao?: string) => {
    if (!isValid(new Date(dataFormatada))) return;

    const dataSelecionada = endOfDay(new Date(formatarComBarra(dataFormatada)));

    const dataCriacaoLink = dataCriacao ? 
        new Date(formatarComBarra(dataCriacaoPraDataValida(dataCriacao))) 
        : new Date();

    return differenceInDays(dataSelecionada, startOfDay(dataCriacaoLink));
}

export const isDataValida = (data: string) => {
    if (!isValid(new Date(data))) return false;

    const dataSelecionada = endOfDay(new Date(formatarComBarra(data)));
    const diaAtual = startOfDay(new Date());

    return isAfter(dataSelecionada, diaAtual) || isSameDay(dataSelecionada, diaAtual);
}

export const dataCriacaoPraDataValida = (data: string): string => {
    if (!data && !isValid(data)) return '';

    const diaMesAno = data.replace(/(^\d{4}-\d{2}-\d{2}).*$/, '$1');

    return isValid(new Date(diaMesAno)) ? diaMesAno : '';
}