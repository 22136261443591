import React, {useState, useEffect, useMemo} from "react";
import { AttachMoney } from "@material-ui/icons";
import { PageHeader, TransacoesTable } from "components";
import { Container } from "./styles";
import {useTypedSelector} from "hooks";
import {useDispatch} from "react-redux";
import {ExtratoRecebiveisActions} from "../../../../../store/extratoRecebiveis";
import {isLoading} from "../../../../../utils";
import {Link} from "react-router-dom";
import StringUtils from "../../../../../utils/StringUtils";

interface IOptions {
    page: number;
    size: number;
    sort: string | null;
    orderBy: string;
    orderDirection: "asc" | "desc" | undefined;
}

const optionsStateInitial: IOptions = {
    page: 0,
    size: 5,
    sort: "dataPagamentoPagarMe,asc",
    orderBy: "dataPagamentoPagarMe",
    orderDirection: "asc",
};

const SaldoDisponivel: React.FC = () => {
    const dispatch = useDispatch();

    const auth = useTypedSelector((state) => state.auth);
    const recebedorId = auth.recebedor.id || "";

    const saldoDisponivel = useTypedSelector(
        (state) => state.extratoRecebiveis.saldoDisponivel
    );

    const [options, setOptions] = useState(optionsStateInitial);

    const tableData = saldoDisponivel?.data?.saldosDisponiveis.map((item: any, index: number) => {
        return {
            id: index,
            ...item
        };
    });

    useEffect(() => {
        dispatch(
            ExtratoRecebiveisActions.getSaldoDisponivel(recebedorId, options)
        );
    }, [dispatch, options]);

    const getOrderBy = (field: string) => {
        const direction: 'asc' | 'desc' | undefined = options.orderBy === field ? options.orderDirection : undefined;

        return direction;
    }

    const columns = useMemo(() => [
        'dataPagamentoPagarMe',
        'idTransacaoPagarMe',
        'nomeLink',
        'nomePagador',
        'dataHoraCompra',
        'valorTotal',
        'parcela',
        'valorParcela',
        'valorAntecipacao',
        'diasAntecipacao',
        'valorLiquido'
      ], [])

    const onChangeOrder = (orderBy: number, orderDirection: string ) => {
        const columnName = columns[orderBy];

        if (columnName) {
          setOptions((prev) => ({
            ...prev,
            sort: `${columnName},${orderDirection}`,
            orderBy: columnName,
            orderDirection: orderDirection as  'asc'| 'desc' | undefined
          }))
        } else {
          setOptions((prev: any) => ({
            ...prev,
            sort: 'dataEstimadaPagamento,asc',
            orderBy: 'dataEstimadaPagamento',
            orderDirection: 'asc'
          }))
        }
    }
    return (
        <Container>
            <PageHeader
                Icon={<AttachMoney />}
                showIcon={true}
                title={"Saldo Disponível"}
            />
            <TransacoesTable
                data={tableData}
                onRowClick={(_, rowData: any) => null}
                columns={[
                    {
                        title: "Data liberação saldo",
                        field: "dataLiberacaoSaldo",
                        defaultSort: getOrderBy('dataPagamentoPagarMe'),
                        customSort: () => 0
                    },
                    {
                        title: "ID transação",
                        field: "idTransacao",
                        defaultSort: getOrderBy('idTransacaoPagarMe')
                    },
                    {
                        title: "Nome do link",
                        field: "linkPagamento.nomeLink",
                        defaultSort: getOrderBy('nomeLink'),
                        render: (rowData: any) => <Link to={`/links/detalhes/${rowData.linkPagamento?.idLink}`}>{rowData.linkPagamento?.nomeLink}</Link>
                    },
                    {
                        title: "Nome do pagador",
                        field: "nomePagador",
                        defaultSort: getOrderBy('nomePagador')
                    },
                    {
                        title: "Data da venda",
                        field: "dataVenda",
                        defaultSort: getOrderBy('dataHoraCompra'),
                        customSort: () => 0
                    },
                    {
                        title: "Valor bruto",
                        field: "valorBruto",
                        defaultSort: getOrderBy('valorTotal'),
                        render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorBruto)}`
                    },
                    {
                        title: "Parcela",
                        field: "parcela",
                        defaultSort: getOrderBy('parcela'),
                    },
                    {
                        title: "Recebível por parcela",
                        field: "valorParcela",
                        defaultSort: getOrderBy('valorParcela'),
                        render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorParcela)}`,
                    },
                    {
                        title: "Valor antecipação",
                        field: "valorAntecipacao",
                        defaultSort: getOrderBy('valorAntecipacao'),
                        render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorAntecipacao)}`,
                    },
                    {
                        title: "Dias antecipados",
                        field: "diasAntecipacao",
                        defaultSort: getOrderBy("diasAntecipacao"),
                        sorting: false,
                    },
                    {
                        title: "Valor líquido",
                        field: "valorLiquido",
                        defaultSort: getOrderBy('valorLiquido'),
                        render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorLiquido)}`,
                        sorting: false,
                    }
                ]}
                page={options.page}
                totalElements={saldoDisponivel.data.totalElements}
                onChangePage={(page, pageSize) =>
                    setOptions((prev) => ({ ...prev, page: page, size: pageSize }))
                }
                isLoading={isLoading(saldoDisponivel.state)}
                onChangeOrder={onChangeOrder}
                toolbar={false}
            />
        </Container>
    );
};

export default SaldoDisponivel;
