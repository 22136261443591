import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CategoriasQuestoes from './CategoriasQuestoes'
import Questoes from './Questoes'
import Questao from './Questao'

export interface AjudaProps {}

const Ajuda: React.FC<AjudaProps> = () => { 
  return (
    <>  
        <Switch>
          <Route
            key="route_categorias_questoes"
            path={AjudaRoutesPaths.base}
            component={CategoriasQuestoes}
            exact
          />
          <Route
            key="route_questoes"
            path={`${AjudaRoutesPaths.detalhes}/:id`}
            component={Questoes}
            exact
          />
          <Route
            id="route_questao"
            path={`${AjudaRoutesPaths.detalhes}/:idCategoria/questao/:idQuestao`}
            component={Questao}
            exact
          />
        </Switch>   
    </>
  )
}

export class AjudaRoutesPaths {
  static base = '/ajuda'
  static detalhes = `${AjudaRoutesPaths.base}/detalhes`
}

export default Ajuda
