import React, { ChangeEvent, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import StringUtils from "utils/StringUtils";
import { TableCellStyled, TableRowStyled, Wrapper } from "./styles";
import { useRedirect } from "hooks";
import { TransacoesRoutesPaths } from "../../../Transacoes";
import { Pagination, Column, TableProps } from "../../interfaces";
import { CircularProgress } from "@material-ui/core";

const columns: Column[] = [
  {
    id: "dataCriacao",
    label: "Data",
  },
  {
    id: "tipoMovimentacao",
    label: "Status",
  },
  {
    id: "nomePagador",
    label: "Nome Cadastro",
  },
  {
    id: "modalidade",
    label: "Modalidade",
  },
  {
    id: "valor",
    label: "Total",
  },
];

export default function TableTransactions(props: TableProps) {
  const redirect = useRedirect();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  useEffect(() => {
    const request: Pagination = { row: rowsPerPage, pageNumber: page };
    props.onChange(request);
  }, [rowsPerPage, page]);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <>
      {props.loading ? (
        <Wrapper>
          <CircularProgress data-testid="circular-progress" />
        </Wrapper>
      ) : (
        <>
          <TableContainer data-testid={props["data-testid"]}>
            <Table>
              <TableHead>
                <TableRowStyled>
                  {columns.map((column) => (
                    <TableCellStyled
                      key={column.id}
                      columntotal={(column.id === "valor").toString()}
                      style={{"fontWeight":"bold"}}
                    >
                      {column.label}
                    </TableCellStyled>
                  ))}
                </TableRowStyled>
              </TableHead>
              <TableBody>
                {props.pagamentos?.map((row) => {
                  return (
                    <TableRowStyled
                      key={row.id}
                      data-testid="data-element-table"
                      onClick={() =>
                        redirect(`${TransacoesRoutesPaths.detalhes}/${row.id}`)
                      }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (typeof value === "number") {
                          return (
                            <TableCellStyled key={column.id} columntotal={"true"}>
                              {StringUtils.format.formatBRL(value as number)}
                            </TableCellStyled>
                          );
                        }else{
                          return (
                            <TableCellStyled key={column.id}>
                              { column.id === "tipoMovimentacao" &&  row.modalidade === "PIX" && row.tipoMovimentacao === "Estornado" ? "Devolvido" : value}
                            </TableCellStyled>
                          );
                        }
                      })}
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={props.quantidadePagamentos}
            labelDisplayedRows={({ from, to, count }) =>`${from}-${to} de ${count}`}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"Linhas por página:"}
            page={page}
            onChangePage={(event: unknown, newPage: number) => setPage(newPage)}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
