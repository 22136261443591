import styled from 'styled-components';
import { Colors, TemplateConstants, Breakpoints } from 'theme';
import { IconButton } from '@material-ui/core';

import LogoUnicredHorizontal from 'assets/logos/logo_unicredpay_horizontal_branco.svg';
import Img from 'components/Img';

export const Wrapper = styled.nav`
  height: ${TemplateConstants.APP_BAR_HEIGHT};
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: linear-gradient(
    120deg,
    rgba(2, 0, 36, 1) 0%,
    ${Colors.primary} 0%,
    ${Colors.lightPrimary} 100%
  );
  color: #fff;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    margin-left: 1rem;

    &:last-of-type {
      margin-right: unset;
    }

    svg {
      color: #fff;
    }
  }  
`;

export const ContentWrapper = styled.div`
  display: flex; 
  align-items: center; 
  width: 100%;  
  max-width: ${Breakpoints.maxMedium};
`;

export const BrandUnicred = styled(Img).attrs((props) => ({
  src: LogoUnicredHorizontal,
}))`
  max-height: 35px;
  width: auto;
  padding-top: 0.1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const NavItems = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  & > button {
    margin-right: 1rem;

    @media (max-width: ${Breakpoints.maxSmall}) {
      margin-right: 0.25rem;
    }

    &:last-of-type {
      margin-right: unset;
    }

    svg {
      color: #fff;
    }
  }
`;

export const BurgerButton = styled(IconButton)`
  color: #fff;

  @media (min-width: ${Breakpoints.maxSmall}) {
    display: none;
  }
`;
