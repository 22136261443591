import React from 'react';

import { Wrapper, Title, Paragraph } from './styles';
import { ErrorOutline } from '@material-ui/icons';

export default function Erro() {
  return (
    <Wrapper>
      <ErrorOutline />
      <Title>Saque não realizado</Title>
      <Paragraph>Seu saque não foi realizado. Tente novamente.</Paragraph>
    </Wrapper>
  );
}
