import styled from 'styled-components';
import { Colors, Breakpoints } from 'theme';
import { Card } from 'components';

export const Wrapper = styled(Card)`
  display: flex;
  padding: 1rem 0.2rem;
  min-height: 85px;
  background: #fff;
  box-sizing: border-box;
  align-items: center;
  box-shadow: none;
  margin: 0.3rem 1rem 0 1rem;
  border-radius: 5px;  

  @media (max-width: ${Breakpoints.maxSmall}) {
    padding: 1.5rem 0rem;
    margin: 0; 
    border-bottom: solid 1px #ccc;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.lightTypography};

  svg {
    height: 30px;
    width: auto;
    margin-right: 0.5rem;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 1rem;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px; 

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 0.8rem;
    max-width: 165px;    
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  color: ${Colors.lightTypography};
  font-size: 0.8rem;

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 0.7rem;
    max-width: 180px;
  }
`;

export const RightContent = styled.div`
  margin-left: auto;
`;
