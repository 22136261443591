import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks';
import { Modal } from 'components';
import { hasError } from 'utils/hasError';
import { isSuccess } from 'utils/isSuccess';
import ResumoSaque from './ResumoSaque';
import Erro from './Erro';
import Sucesso from './Sucesso';
import { RecebedorActions } from 'store/recebedor/recebedor';
import { Wrapper } from './styles';

interface SaqueModalProps {
    isOpen: boolean;
    onClose: any;
}

export default function SaqueModal({ isOpen, onClose }: SaqueModalProps) {
    const dispatch = useDispatch();
    const novoSaque = useTypedSelector(state => state.recebedor.novoSaque);
    const auth = useTypedSelector(state => state.auth);

    const closeModal = () => {
        dispatch(RecebedorActions.getSaldo(auth.recebedor?.id || ''));
        dispatch(RecebedorActions.resetNovoSaque());
        onClose();
    }

    return (
        <Modal
            open={isOpen}
            onClose={closeModal}
        >
            <Wrapper>
                {isSuccess(novoSaque.state) && <Sucesso />}
                {hasError(novoSaque.state) && <Erro />}
                {!hasError(novoSaque.state) && !isSuccess(novoSaque.state) && <ResumoSaque />}
            </Wrapper>
        </Modal>
    );
}
