import { isValid } from "date-fns";

/**
 * Quando se faz um New Date('yyyy-MM-dd') o timezone é levado em consideração. 
 * Então, new Date(2023-01-01) = 31 de dez de 2022 às 21h se for no BR
 * enquanto que new Date(2023/01/01), o resultado será 0h do dia 01/01/2023.
 */
export const formatarComBarra = (data: string) => {
    if (!isValid(new Date(data))) return '';

    return data.replace(/-/g, '/');
}