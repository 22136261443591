import styled from 'styled-components'
import { Breakpoints } from 'theme'
import { PageWrapper } from 'shared/styles'

export const Wrapper = styled(PageWrapper)` 
  @media (max-width: ${Breakpoints.maxSmall}) {  
    padding-bottom: 11rem;
  } 
`
export const ContentLoading = styled.div`
  background-color: rgb(255, 255, 255, 0.1);  
  display: flex;
  justify-content: center;
  align-items: center;  
  position: relative;
  height: 100%;
  width: 100%;
`
