import { store } from 'store'
import { AuthActions } from 'store/auth/auth'
import axiosInstance from './instance'
import axios, { AxiosRequestConfig } from 'axios'

export function createInterceptors(): void {
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const state = store.getState()
    const accessToken = state?.auth?.accessToken
    const cooperativa = state?.auth?.cooperativa
    const agencia = state?.auth.user.agencia
    const conta = state?.auth.user.conta
    const recebedorId = state?.auth?.recebedor.id
    const headers = { ...config.headers }
    if (accessToken) headers.authorization = `Bearer ${accessToken}`
    if (cooperativa) headers.cooperativa = cooperativa
    if (agencia) headers.agencia = agencia
    if (conta) headers['conta-corrente'] = conta
    if (recebedorId) headers['recebedor'] = recebedorId

    config.headers = headers

    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) store.dispatch(AuthActions.logout())
      return Promise.reject(error)
    }
  )
}
