import styled from 'styled-components';
import { Breakpoints, Colors } from 'theme';
import { PageWrapper } from 'shared/styles';
import { Divider, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

export const Wrapper = styled(PageWrapper)`
  width: 100%;
  
  padding-bottom: 0;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    padding: 0;
    margin-bottom: 135px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
  padding: 1.5rem;
`;

export const SubContentWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  display: grid;
  background-color: white;
  justify-content: space-between;
  grid-template-rows: auto;
  grid-template-columns: 33% 34% 33%;
  grid-row-gap: 1.2rem;
  grid-column-gap: 0px;

  > div {
    border-bottom: 1px solid ${Colors.lighterGrey};
    padding: 0rem 0 .75rem 0;
  }


  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    grid-template-columns: 50% 50%;

    > div {
      &:nth-last-child(-n + 1) {
        border-bottom: none;
      }
    }
  } 

  @media (min-width: ${Breakpoints.maxSmallTablet}) {
    > div {
      &:nth-last-child(-n + 3) {
        border-bottom: none;
      }
    }
  }
`;

export const TitleStyled = styled(Typography)`
  color: rgba(0, 0, 0, .6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
  padding-bottom: 0.25rem;
`;

export const DataInfo = styled(Typography)`
  font-size: 14px;
`;

export const DividerStyled = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const DivStyled = styled.div`
  width: 33%;
`;

export const BlueCard = styled.div`
  padding: .7rem 2rem 0.7rem 1rem;
  margin: 0;
  margin-top: 1rem;
  width:100%;
  border-radius: 4px;
  color: #48a9dd;
  background-color: #e6f3fa;
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    margin-top: 0;
  }
`;

export const InfoIcon = styled(InfoOutlined)`
  color: #48a9dd;
  font-size: 18px;
  margin-right: .3rem;
`;
