import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "components";
import { useTypedSelector } from "hooks";
import { hasError } from "utils/hasError";
import { isSuccess } from "utils/isSuccess";
import { DadosEstorno } from "./interfaces";
import { PagamentoActions } from "store/pagamento/pagamento";
import Erro from "./Erro";
import Sucesso from "./Sucesso";
import Content from "./Content";
import { Wrapper } from "./styles";

interface ModalEstornoProps {
  onClose: any;
  open: boolean;
  dadosEstorno: DadosEstorno;
}

const ModalEstorno = ({ onClose, open, dadosEstorno }: ModalEstornoProps) => {
  const dispatch = useDispatch();
  const cancelamento = useTypedSelector((state) => state.pagamento.efetuarCancelamento);

  const closeModal = () => {
    dispatch(PagamentoActions.resetEfetuarCancelamento());
    onClose();
  };

  return (
    <Modal onClose={closeModal} open={open}>
      <Wrapper>
        {isSuccess(cancelamento.state) && (
          <Sucesso metodoId={dadosEstorno.metodoId} onClose={closeModal} />
        )}
        {hasError(cancelamento.state) && <Erro mensagem={cancelamento.error} onClose={closeModal} />}
        {!hasError(cancelamento.state) && !isSuccess(cancelamento.state) && (
          <Content dadosEstorno={dadosEstorno} />
        )}
      </Wrapper>
    </Modal>
  );
};

export default ModalEstorno;
