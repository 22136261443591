import styled from 'styled-components';
import { Select as SelectMui, FormControl as FormControlMui } from '@material-ui/core';


export const FormControl = styled(FormControlMui)`
    label {
        transform: translate(.5rem, .8rem) scale(1);
    }

`

export const Select = styled(SelectMui)`
    background: #fff;
    font-size: 1rem;
    z-index: 0;

    > div{
        padding: .8rem .5rem .6rem .5rem;
    }
`;