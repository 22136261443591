import React from 'react';

import { FormControl } from '@material-ui/core';
import SelectBase, { SelectBaseProps } from '../SelectBase/SelectBase';
import { Label, ErrorMsg } from './styles';

interface SelectProps extends SelectBaseProps {
  label?: any;
}

export default function Select(props: SelectProps) {
  const {
    label,
    fullWidth = true,
    margin = 'dense',
    error = false,
    disabled,
    errorMsg,
    menuPlacement,
    ...rest
  } = props;

  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <Label>
        {label && label}
        <SelectBase
          menuPlacement={menuPlacement}
          isDisabled={disabled}
          error={error}
          {...rest}
        />
      </Label>
      {error && <ErrorMsg>{errorMsg}</ErrorMsg>}
    </FormControl>
  );
}
