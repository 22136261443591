import styled from 'styled-components'
import { TemplateConstants, Breakpoints, Colors } from 'theme'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media (min-width: ${Breakpoints.minExtraLarge}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1980px;
  }
`

export const AlertSnackBar = styled(Snackbar)``
export const AlertContent = styled(MuiAlert)`
  background-color: ${Colors.lighterGrey};
  color: ${Colors.unicredGoldish};
  svg {
    color: ${Colors.unicredGoldish};
  }
`
