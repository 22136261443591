import React from 'react';
import { Alert } from '@material-ui/lab';
import { Wrapper, Container, Logo } from './styles';

const LoginUrlError = () => {
  return (
    <Wrapper>
      <Container>
          <Logo />
          <Alert severity="error"> Houve um erro ao realizar a autenticação. Tente novamente. </Alert>       
      </Container>
    </Wrapper>
  );
}

export default LoginUrlError;