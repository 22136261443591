import styled from 'styled-components';

interface WrapperProps {
  color: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  color: ${({ color }) => color};
  font-weight: 600;
  align-items: center;
`;

interface CircleIconProps {
  color: string;
  large: boolean;
}

export const CircleIcon = styled.div<CircleIconProps>`
  border-radius: 50%;
  padding: 0.25rem;
  display: flex;
  color: inherit;
  border: 2px solid ${({ color }) => color};
  margin-right: 1rem;

  svg {
    font-size: ${({ large }) => (large ? '2.5rem' : '1.5rem')};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  small,
  span {
    font-weight: 400;
  }

  strong {
    font-size: x-large;
    margin-top: -3px;
  }
`;
