import { Action } from 'store/interfaces'
import {
  RecebimentoState,
  ParamsRecebedor,
  QueryStringRecebedor,
} from 'store/recebimento/interfaces'
import { StateEnum } from 'shared/enums'
import { Dispatch } from 'redux'
import { axiosInstance, Endpoints } from 'api'
import querystring from 'querystring'
import { createErrorMessage } from 'utils'

export enum RecebimentoTypes {
  GET_ALL_RECEBIMENTO = 'recebimento/GET_ALL_RECEBIMENTO',
  GET_ALL_RECEBIMENTO_SUCCESS = 'recebimento/GET_ALL_RECEBIMENTO_SUCCESS',
  GET_ALL_RECEBIMENTO_FAILED = 'recebimento/GET_ALL_RECEBIMENTO_FAILED',
  GET_ALL_RECEBIMENTO_ERROR_HANDLED = 'recebimento/GET_ALL_RECEBIMENTO_ERROR_HANDLED',
  GET_ALL_RECEBIMENTO_RESET = 'recebimento/GET_ALL_RECEBIMENTO_RESET',
  GET_ONE_RECEBIMENTO = 'recebimento/GET_ONE_RECEBIMENTO',
  GET_ONE_RECEBIMENTO_SUCCESS = 'recebimento/GET_ONE_RECEBIMENTO_SUCCESS',
  GET_ONE_RECEBIMENTO_FAILED = 'recebimento/GET_ONE_RECEBIMENTO_FAILED',
  GET_ONE_RECEBIMENTO_ERROR_HANDLED = 'recebimento/GET_ONE_RECEBIMENTO_ERROR_HANDLED',
  GET_ONE_RECEBIMENTO_RESET = 'recebimento/GET_ONE_RECEBIMENTO_RESET',
}

const initialState: RecebimentoState = {
  recebimentos: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      extratos: [],
      totalElements: 0,
    },
  },
  recebimento: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      recebiveis: [],
    },
  },
}

export function reducer(
  state = initialState,
  action: Action
): RecebimentoState {
  switch (action.type) {
    case RecebimentoTypes.GET_ALL_RECEBIMENTO:
      return {
        ...state,
        recebimentos: {
          ...state.recebimentos,
          state: StateEnum.LOADING,
        },
      }
    case RecebimentoTypes.GET_ALL_RECEBIMENTO_SUCCESS:
      return {
        ...state,
        recebimentos: {
          ...state.recebimentos,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case RecebimentoTypes.GET_ALL_RECEBIMENTO_FAILED:
      return {
        ...state,
        recebimentos: {
          ...state.recebimentos,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case RecebimentoTypes.GET_ALL_RECEBIMENTO_ERROR_HANDLED:
      return {
        ...state,
        recebimentos: {
          ...state.recebimentos,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case RecebimentoTypes.GET_ALL_RECEBIMENTO_RESET:
      return {
        ...state,
        recebimentos: initialState.recebimentos,
      }
    case RecebimentoTypes.GET_ONE_RECEBIMENTO:
      return {
        ...state,
        recebimento: {
          ...state.recebimento,
          state: StateEnum.LOADING,
        },
      }
    case RecebimentoTypes.GET_ONE_RECEBIMENTO_SUCCESS:
      return {
        ...state,
        recebimento: {
          ...state.recebimento,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case RecebimentoTypes.GET_ONE_RECEBIMENTO_FAILED:
      return {
        ...state,
        recebimento: {
          ...state.recebimento,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case RecebimentoTypes.GET_ONE_RECEBIMENTO_ERROR_HANDLED:
      return {
        ...state,
        recebimento: {
          ...state.recebimento,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case RecebimentoTypes.GET_ONE_RECEBIMENTO_RESET:
      return {
        ...state,
        recebimento: initialState.recebimento,
      }
    default:
      return state
  }
}

export class RecebimentoActions {
  static getAllRecebimentos(
    params: ParamsRecebedor,
    query: QueryStringRecebedor
  ): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebimentoTypes.GET_ALL_RECEBIMENTO })

        const response = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/${
            params.id
          }/extrato?${querystring.stringify(query)}`
        )

        dispatch({
          type: RecebimentoTypes.GET_ALL_RECEBIMENTO_SUCCESS,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: RecebimentoTypes.GET_ALL_RECEBIMENTO_FAILED,
          payload: { error },
        })
      }
    }
  }

  static getOneRecebimento(params: ParamsRecebedor): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebimentoTypes.GET_ONE_RECEBIMENTO })

        const response = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/${params.id}/extrato/${
            params.saqueId
          }`
        )

        dispatch({
          type: RecebimentoTypes.GET_ONE_RECEBIMENTO_SUCCESS,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: RecebimentoTypes.GET_ONE_RECEBIMENTO_FAILED,
          payload: { error },
        })
      }
    }
  }

  static resetAllRecebimento(): Action {
    return { type: RecebimentoTypes.GET_ALL_RECEBIMENTO_RESET }
  }
}
