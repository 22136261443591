import { Button, ButtonProps } from '@material-ui/core';
import styled from 'styled-components'
import { Breakpoints } from 'theme'
import AddIcon from '@material-ui/icons/Add'

export const Wrapper = styled.aside`
  flex: 0 0 240px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #fff;  
  padding-top: 1rem;

  & .MuiPaper-root {
    position: relative;
    border: none;
    overflow: hidden;
    z-index: 0;
  }

  @media (max-width: ${Breakpoints.maxSmall}) {
    display: none;
  }
`

interface NovoLinkProps extends ButtonProps{
  movimentacoes?: string;
}

export const NovoLink = styled(Button)<NovoLinkProps>` 
  margin: 1rem auto; 
  border-radius: 5px;
  border: 2px solid;
  width: 100%;

  &:hover {
    border: 2px solid;
  }

  @media (max-width: ${Breakpoints.maxSmall}) {
    display: ${({movimentacoes}) => movimentacoes === "true" ? 'flex' : 'none'};
  } 
`;

export const Adicionar = styled(AddIcon)`
  padding-right: 0.5rem;
`

export const NovoLinkItem = styled.div`
  width: 90%;
  margin: auto;
`;