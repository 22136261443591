import React from 'react';
import { ParagrafosIniciais, Title } from './styles';

interface OnboardingTermoProps {
    termo: any;
    nome: string;
}

const OnboardingTermo = ({ termo, nome }: OnboardingTermoProps) => {
    const renderParagrafos = (path: any) =>
        <ParagrafosIniciais>
            {path.paragrafosIniciais?.map((paragrafo: string) =>
                <p key={`${nome}-paragrafo${Math.random()}`}> {paragrafo} </p>
            )}
        </ParagrafosIniciais>

    const renderTitulo = (path: any) =>
        <Title key={`${nome}-titulo`}>
            {path.titulo}
        </Title>

    const renderClausulas = (path: any) =>
        path.clausulas?.map((clausula: any) => {
            let elements: React.ReactElement[] = [];
            elements.push(<p key={`${nome}-clausula${Math.random()}`}><b> {clausula.titulo} </b></p>);
            elements.push(clausula.items.map((item: any) => {
                return <span key={`${nome}-clausula-item${Math.random()}`}>
                    <p> {item.titulo} </p>
                    {item.subItems?.map((subItem: string) =>
                        <p key={`${nome}-clausula-subItem${Math.random()}`}> {subItem} </p>
                    )}
                </span>
            }))

            return elements;
        }).reduce((prev: any, curr: any) => [prev, curr])

    return <>
        {renderTitulo(termo)}
        {renderParagrafos(termo)}

        {termo.clausulas.length > 0 && renderClausulas(termo)}       
        {termo.anexos?.titulo && (
            <>
                <p><b>{termo.anexos?.titulo}</b></p>
                {renderParagrafos(termo.anexos)}
                {renderClausulas(termo.anexos)}
            </>
        )}
    </>
}

export default OnboardingTermo;