import { StateEnum } from 'shared/enums';
import { Dispatch } from 'redux';
import {
  IGetUsuariosSuccess,
  UsuarioState,
} from './interfaces';
import { createErrorMessage } from 'utils';
import { axiosInstance, Endpoints } from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import createGenericSlice from 'store/createGenericSlice';

// Exported actions
interface IActions {
  getUsuariosSuccess: any
  getUsuariosStarted?: any
  getUsuariosFailed?: any
  getUsuariosErrorHandled?: any
  reset: any
}

const initialState: UsuarioState = {
  getUsuarios: {
    state: StateEnum.IDLE,
    error: null,
    usuarios: [],
    cooperativa: 0,
  },
};

const usuarioSlice = createGenericSlice({
  name: 'usuario',
  initialState,
  reducers: {
    getUsuariosSuccess(state: UsuarioState, action: PayloadAction<IGetUsuariosSuccess>) {
      state.getUsuarios.state = StateEnum.IDLE
      state.getUsuarios.usuarios = action.payload.usuarios
      state.getUsuarios.cooperativa = action.payload.cooperativa
    },
    reset(state: UsuarioState) {
      state = initialState
    }
  }
})

export const actions: IActions = usuarioSlice.actions
export default usuarioSlice.reducer

// Thunk actions
export function getUsuarios(agencia: string, conta: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getUsuariosStarted());

      const { data } = await axiosInstance.get(`${Endpoints.usuario.usuarios()}/${agencia}/${conta}`);

      dispatch(actions.getUsuariosSuccess({ ...data }));
    } catch (err) {
      const error = createErrorMessage(err);
      dispatch(actions.getUsuariosFailed({ error }));
    }
  };
}