import styled from 'styled-components'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ErrorOutline, InfoOutlined, ReplyOutlined } from '@material-ui/icons'
import { Colors, Breakpoints } from 'theme'
import { Card } from 'components'

export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1400px;
  opacity: 0;
  transform: translateX(-20px);
  animation: WrapperContainer 0.3s forwards;
  @media (max-width: ${Breakpoints.maxMedium}) {
    flex-direction: column;
  }
  @keyframes WrapperContainer {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;

export const CardLista = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: true,
}))`
  flex-basis: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  position: relative;
`;

export const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
`;

export const ListaQuestoes = styled(List)`
  max-width: 100%;
  max-height: 100%;
  padding: 0px;
`;

export const ListaItem = styled(ListItem).attrs((props) => {})`
  background-color: white;
  position: relative;
  border-bottom: 1px solid ${Colors.lighterGrey};
  max-width: 100%;
  max-height: 100%;
  padding: 0px 20px;

  svg {
    color: ${Colors.unicredGoldish};
  }
`;

export const ListaItemIcon = styled(ListItemIcon)``
export const ListaItemText = styled(ListItemText)`
  padding: 15px 0px;
  color: ${Colors.lightGrey};
  :hover {
    color: ${Colors.unicredGoldish};
  }
`;

export const IconError = styled(ErrorOutline)``
export const IconBack = styled(ReplyOutlined)`
  color: ${Colors.unicredGoldish};
  padding-right: 4px;
  border-radius: 50px;

  :hover {
    cursor: pointer;
    background-color: ${Colors.lighterGrey};
    padding: 1px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 1rem;
  align-items:center;
  width:98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
`;

export const BlueCard = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0.4rem;
  border-radius:10px;
  display: flex;
  color: #48a9dd;
  flex-direction: row;
  justify-content: flex-start;
  width:99%;
  background-color: #e6f3fa;
`;

export const InfoIcon = styled(InfoOutlined)`
  color: #48a9dd;
  font-size: 18px;
`;