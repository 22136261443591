import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Loader } from './styles';

const CenteredLoader = () => {
    return (
        <Loader>
            <CircularProgress />
        </Loader>
    )
}

export default CenteredLoader;