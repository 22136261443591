import React from 'react'
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { useRedirect, useTypedSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { UiActions } from 'store/ui'
import {
  Home,
  Link,
  AccountBalanceWallet,
  AccountBalance,
  AttachMoney,
  ContactSupportOutlined,
  AssessmentOutlined,
} from '@material-ui/icons'

import { RoutesPaths } from 'routes'
import { Wrapper, NovoLink, Adicionar, NovoLinkItem } from './styles'
import NavItem from './NavItem'
import theme from 'theme'
import { LinkPagamentoActions } from 'store/linkPagamento'

export default function NavDrawer() {
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const redirect = useRedirect()
  const { isOpen } = useTypedSelector((state) => state.ui.drawer) 
  const plataforma =  useTypedSelector((state) => state.auth.plataforma) 
  const dispatch = useDispatch()
  const isMovimentacoes = window.location.href.indexOf('movimentacao') > -1;

  const resetLayoutNewLink = () => {
    dispatch(LinkPagamentoActions.resetNovoLink())
    redirect(RoutesPaths.novoLink)
  }

  return (
    <Wrapper>
      <Drawer variant={mobile ? 'temporary' : 'permanent'} open={isOpen} onClose={() => dispatch(UiActions.hideDrawer())}>
        <List style={{ flex: 1 }}>
          <NavItem to={RoutesPaths.dashboard}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Início" />
          </NavItem>
          <NavItem to={RoutesPaths.links.base}>
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText primary="Link de pagamento" />
          </NavItem>
          <NavItem to={RoutesPaths.transacoes.base}>
            <ListItemIcon>
              <AccountBalanceWallet />
            </ListItemIcon>
            <ListItemText primary="Movimentação" />
          </NavItem>
          <NavItem to={RoutesPaths.recebimentos.base}>
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
            <ListItemText primary="Recebíveis" />
          </NavItem>
          <NavItem to={RoutesPaths.taxasmdr}>
            <ListItemIcon>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary="Taxas e tarifas" />
          </NavItem>
          {/* {!Boolean(plataforma) && <NavItem to={RoutesPaths.relatorios}>
            <ListItemIcon>
              <AssessmentOutlined />
            </ListItemIcon>
            <ListItemText primary="Relatórios" /> */}
          {/* </NavItem>} */}
          <NavItem to={RoutesPaths.ajuda.base}>
            <ListItemIcon>
              <ContactSupportOutlined />
            </ListItemIcon>
            <ListItemText primary="Ajuda" />
          </NavItem>
          <NovoLinkItem>
            <NovoLink
              movimentacoes={isMovimentacoes.toString()}
              data-testid="app_novo_link"
              color="primary"
              variant="outlined"
              aria-label="Novo Link"
              onClick={resetLayoutNewLink}>
              <Adicionar /> Novo Link
            </NovoLink>
          </NovoLinkItem>
        </List>
      </Drawer>
    </Wrapper>
  )
}
