import React from "react";
import {
  Wrapper,
  Title,
  TitleWrapper,
  LinkIcon,
  HeaderDivider,
  IconContent,
} from "./styles";

interface PageHeaderProps {
  showIcon: Boolean;
  title: string;
  Icon?: any;
}

export default function PageHeader(props: PageHeaderProps) {
  const { showIcon, title, Icon } = props;
  return (
    <>
      <Wrapper>
        <TitleWrapper>
          {showIcon && !Icon && <LinkIcon />}
          {showIcon && <IconContent>{Icon}</IconContent>}
          <Title> {title} </Title>
        </TitleWrapper>
      </Wrapper>
      <HeaderDivider />
    </>
  );
}
