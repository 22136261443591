import { StateEnum } from 'shared/enums';
import { Action } from 'store/interfaces';
import { Dispatch } from 'redux';
import {
    GetTaxasSuccess,
    TaxaState
} from './interfaces'
import { ErrorMessages } from 'shared/messages';

export enum TaxasTypes {
    GET_TAXAS = 'taxas/GET_TAXAS',
    GET_TAXAS_SUCCESS = 'taxas/GET_TAXAS_SUCCESS',
    GET_TAXAS_FAILED = 'taxas/GET_TAXAS_FAILED',
    GET_TAXAS_ERROR_HANDLED = 'taxas/GET_TAXAS_ERROR_HANDLED',
    RESET = 'taxas/RESET'
}
const initialState: TaxaState = {
    getTaxas: {
        state: StateEnum.IDLE,
        error: null,
        taxas: [{
            tipo: 'CARTAO',
            taxa: 2,
            valor: 1.2,
            taxaParcelamentos: [{ min: 1, max: 1, taxa: 3.79 },
            { min: 2, max: 6, taxa: 4.19 },
            { min: 7, max: 12, taxa: 4.50 }]
        },
        ],
        fetchedAt: null,
    }
}
export function reducer(
    state = initialState,
    action: Action,
): TaxaState {
    switch (action.type) {
        case TaxasTypes.GET_TAXAS:
            return {
                ...state,
                getTaxas: {
                    ...state.getTaxas, state: StateEnum.LOADING
                },
            }
        case TaxasTypes.GET_TAXAS_SUCCESS:
            return {
                ...state,
                getTaxas: {
                    ...state.getTaxas,
                    state: StateEnum.IDLE,
                    taxas: action.payload.taxas,
                    fetchedAt: action.payload.fetchedAt
                },
            }
        case TaxasTypes.GET_TAXAS_FAILED:
            return {
                ...state,
                getTaxas: {
                    ...state.getTaxas,
                    state: StateEnum.ERROR,
                    error: action.payload.error,
                },
            }
        case TaxasTypes.GET_TAXAS_ERROR_HANDLED:
            return {
                ...state,
                getTaxas: {
                    ...state.getTaxas,
                    state: StateEnum.IDLE,
                    error: null,
                },
            }
        case TaxasTypes.RESET:
            return initialState;
        default:
            return state;
    }
}

export class TaxasActions {

    static getTaxasStart(): Action {
        return { type: TaxasTypes.GET_TAXAS }
    }
    static getTaxas() {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(TaxasActions.getTaxasStart());
                //const data = await axiosInstance.get(Endpoints.taxa.taxas())
                const data = [{
                    tipo: 'CARTAO',
                    taxa: 2, //remover 
                    valor: 1.2,
                    taxaParcelamentos:
                        [
                            { min: 1, max: 1, taxa: 3.79 },
                            { min: 2, max: 6, taxa: 4.19 },
                            { min: 7, max: 12, taxa: 4.50 }
                        ]
                },
                ]
                dispatch(
                    this.getTaxasSuccess({
                        taxas: data,
                        fetchedAt: new Date(),
                    })
                )
            } catch (woof) {
                dispatch({
                    type: TaxasTypes.GET_TAXAS_FAILED,
                    payload: { error: ErrorMessages.defaultError },
                });
            }
        }
    }
    static getTaxasSuccess(data: GetTaxasSuccess): Action {
        return {
            type: TaxasTypes.GET_TAXAS_SUCCESS,
            payload: { taxas: data.taxas, fetchedAt: data.fetchedAt }
        }
    }
    static getTaxasFailed(error: string): Action {
        return {
            type: TaxasTypes.GET_TAXAS_FAILED,
            payload: { error }
        };
    }
    static handleGetTaxasError(): Action {
        return { type: TaxasTypes.GET_TAXAS_ERROR_HANDLED }
    }
}

