import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Wrapper, AlertContent, AlertSnackBar } from './styles'
import { useTypedSelector } from 'hooks'
import { AjudaActions } from 'store/ajuda'
import { IParamsQuestao } from '../Questoes/interfaces'
import Painel from './Painel'
import { hasError } from 'utils'
import { axiosInstance, Endpoints } from 'api'

export interface IQuestaoProps {
  id: string
}

const Questao: React.FC<IQuestaoProps> = (props: IQuestaoProps) => {
  const [openSnack, setOpenSnack] = React.useState(true);
  const params: IParamsQuestao = useParams();
  const dispatch = useDispatch();

  const [questao, setQuestao] = useState<any> ({});
  const recebedor = useTypedSelector((state) => state.auth.recebedor);
  const updateLike = useTypedSelector((state) => state.ajuda.likes);
  const [isLoading, setIsLoading] = useState<boolean> (true);
  const [error, setError] = useState<string> ("");

  React.useEffect(() => {
    axiosInstance.get(
      `${Endpoints.cooperativaTaxas.taxas()}/ajuda/answer/${props.id}`
    ).then(response => { setQuestao(response.data)}).catch((err) => {setError(err.toString())})
    .finally(() => setIsLoading(false))

  }, [params, updateLike.state])

  const onChangeLike = (like: boolean, questaoId: string) => {
    setOpenSnack(true)
    dispatch(
      AjudaActions.updateLikeQuestoes({
        like,
        questao_id: questaoId,
        recebedor_id: recebedor.id || '',
      })
    )
  }

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  return (
    <Wrapper>
      <Painel
        questao={questao}
        onChangeLike={onChangeLike}
        loading={isLoading}
        error={Boolean(error)}
        msgError={error}
        categoriaId={params.idCategoria}
      />
      <AlertSnackBar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnack}
        onClose={handleClose}
        key="snack_top"
        autoHideDuration={2000}
      >
        <AlertContent>
          {hasError(updateLike.state)
            ? updateLike?.error || ''
            : 'Obrigado pela sua opinião!'}
        </AlertContent>
      </AlertSnackBar>
    </Wrapper>
  )
}

export default Questao
