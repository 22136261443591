export class ErrorMessages {
  static defaultError = "Serviço indisponível. Tente novamente mais tarde.";
}

export class LinkMessages {
  static expirado = "O prazo para pagamento expirou.";
  static inativo = "Indisponível para pagamento.";
  static inexistente = "Este link para pagamento não existe.";
  static quantidadeExcedida =
    "Este link excedeu a quantidade máxima de pagamentos.";
}

export class LoginMessages {
  static agenciaContaError = "Agência ou conta não existem.";
  static usuarioSenhaError = "Usuário ou senha inválidos.";
  static avisoBloqueio = `Digite a mesma senha utilizada para acessar o seu Internet Banking. Em caso de 3 tentativas erradas o acesso ao 
                          Unicred Pay e ao Internet Banking poderá ser bloqueado.`;
}

export class CooperativaTaxas {
  static inexistente =
    "Taxas da cooperativa não existem. Favor entrar em contato com sua cooperativa.";
}

export class PagamentoMessages {
  static avisoFechamento =
    "Antes de sair, recomendamos que você salve ou imprima o comprovante de sua transação.";
}

export class EstornoMessages {
  static motivoCancelamento = "Qual o motivo para cancelar o pagamento:";
  static confirmacaoCancelamento =
    "Tem certeza que deseja cancelar este pagamento? Essa operação é irreversível.";
  static cancelamentoAgendadoTitulo = "Estorno solicitado";
  static cancelamentoAgendadoTexto =
    "A solicitação de estorno de pagamento foi realizada com sucesso. Aguarde o retorno da emissora do cartão.";
  static cancelamentoRealizadoTitulo = "Cancelamento aprovado";
  static cancelamentoRealizadoTexto =
    "O cancelamento foi aprovado e o valor total será transferido para a conta do pagador.";
  static cancelamentoSolicitadoTitulo = "Devolução solicitada";
  static cancelamentoSolicitadoTexto =
    "A devolução de PIX foi solicitada com sucesso.";
  static cancelamentoNaoRealizadoTitulo = "Cancelamento não realizado";
  static cancelamentoNaoRealizadoTexto =
    "Não foi possível cancelar este pagamento. Tente novamente.";
  static estornoBoleto =
  "As transações de modalidade boleto não podem ser estornadas.";    
}

export class RelatoriosMessages {
  static dataInicialMaiorFinal =
    "A data de início não pode ser maior do que a data de fim.";
  static datasInvalidas = "Data de início ou data de fim não são válidas.";
  static periodoMaximo = (maximo: number) =>
    `O período máximo para gerar o relatório é de até ${maximo} dias.`;
  static dataFinalPosteriorAtual =
    "A data de fim não pode ser posterior ao dia atual.";
  static semMovimentacoes =
    "Sem movimentações no período informado. Escolha outro período para gerar o relatório.";
  static erroRequisicao =
    "Ocorreu um erro ao gerar o relatório. Tente novamente.";
}

export class AntecipacaoMessages {
  static prazoAntecipacao = "15";
  static taxaAntecipacao = "1,35";
  static title = "Ativar antecipação de recebíveis";
  static tooltipText = `Receba em ${AntecipacaoMessages.prazoAntecipacao} dias o valor de cada pagamento, independente do número de parcelas.`;
  static ativacaoInfoHeader =
    "Tudo certo! Sua antecipação de recebíveis foi habilitada com sucesso.";
  // static ativacaoInfoText = `Os pagamentos em Cartão de Crédito realizados a partir de hoje serão disponibilizados integralmente após ${AntecipacaoMessages.prazoAntecipacao} dias, independente do número de parcelas. Os pagamentos já realizados, com mais de ${AntecipacaoMessages.prazoAntecipacao} dias, também serão antecipados.`;
  static ativacaoInfoText = `Todas as vendas a crédito, realizadas a partir de agora, terão os seus pagamentos antecipados em ${AntecipacaoMessages.prazoAntecipacao} dias. As vendas já realizadas, com mais de ${AntecipacaoMessages.prazoAntecipacao} dias, também serão antecipadas. Para este recebimento será aplicada a taxa de ${AntecipacaoMessages.taxaAntecipacao}% ao mês, obedecendo o período de antecipação de cada parcela.`;

  static desativacaoInfoHeader =
    "A antecipação de recebíveis foi desabilitada.";
  static desativacaoInfoText = `Os pagamentos em Cartão de Crédito realizados a partir de hoje serão disponibilizados em parcelas. Os pagamentos feitos com mais de ${AntecipacaoMessages.prazoAntecipacao} dias permanecem com a antecipação.`;
  static errorHeader = "Ocorreu um erro.";
  static errorText =
    "Ocorreu um erro ao modificar a antecipação de recebíveis. Tente novamente.";
}

export class SaqueProgramadoMessages {
  static tooltipText =
    "Escolha um dia para receber a transferência, do seu saldo disponível, para a sua conta Unicred.";
  static ativacaoInfoHeader = "Tudo certo! O saque programado foi habilitado.";
  static ativacaoInfoText = (
    dia: string,
    taxa: string,
    minimo: string
  ) => `O saldo disponível de pagamentos com Cartão de Crédito será transferido para a sua 
                                                                             conta corrente automaticamente no dia ${dia} de cada mês e será debitada a tarifa de saque no valor 
                                                                             de ${taxa}. Caso o saldo disponível seja menor que o valor mínimo de ${minimo}, o saque não será efetuado no mês.`;
  static desativacaoInfoHeader = "O saque programado foi desabilitado.";
  static desativacaoInfoText = `O saldo disponível não será mais tranferido automaticamente para a sua conta corrente.`;
  static errorHeader = "Ocorreu um erro.";
  static errorText =
    "Ocorreu um erro ao modificar o saque programado. Tente novamente.";
}

export class NovoLinkMessages {
  static adicionar = "Adicionar";
  static limpar = "Remover";
  static itensTitle = "Especifique os itens inclusos no valor:";
  static formasPagamentoTitle =
    "Formas de pagamento e configurações adicionais";
  static validadeTitle = "Validade";
  static valorTotalMinimumError = "Valor total deve ser maior ou igual a";
  static valorTotalMaximumError = "Valor total deve ser menor ou igual a";
  static criarLinkText = "Criar Link";
  static criarLinkLoadingText = "Criando novo link...";
  static linkCriadoTitle = "Novo link de pagamento criado";
  static linkCriadoSubtitle =
    "Você já pode começar a receber pagamentos de forma fácil e rápida.";
  static copiar = "Copiar Link";
  static compartilhar = "Compartilhar";
  static irParaLista = "Ir para a lista";
  static criarNovo = "Criar novo";
  static errorMessage = "Erro ao criar o link. Tente novamente.";
}

export class OnboardingMessages {
  static semDadosTabela =
    "Não será cobrada nenhuma taxa ou tarifa para este tipo de pagamento.";
}

export class OpenFinanceGerarLink {
  static titleTooltip = "PIX QR CODE, COPIA E COLA E VIA OPEN FINANCE";
  static descriptionTooltip = "O pagador terá 3 meios de fazer o Pix:";
  static footerTooltip =
    "A confirmação do pagamento chegará por e-mail em todas as opções.";
  static QrCode = {
    title: "QR Code:",
    description: "escaneando o QR Code gerado;",
  };
  static CopiaCola = {
    title: "Copia e Cola:",
    description: "clicando no botão de copiar o código de pagamento;",
  };
  static pixOPF = {
    title: "Via Open Finance:",
    description:
      "selecionando uma instituição financeira, para onde ele será redirecionado de forma segura para realizar o débito.",
  };
}

export class ExtratoRecebiveis {
  static antecipacaoMessage =
    "* Em caso de antecipação do recebível, a taxa de juros será aplicada na data de liberação do saldo.";
}
export class OpenFinanceModalInstituicao {
  static titleModal = "Detalhes";
  static about = "Sobre";
  static participantesLabel = "Instituições que participam da marca";
  static footerPortal = "Para mais informações, consulte o Portal do Cidadão";
}

export class SobreOpenFinanceModal {
  static title = "SOBRE O OPEN FINANCE";
  static description =
    "O Open Finance é o sistema financeiro aberto, que permite novos tipos de transações financeiras, regulamentado pelo Banco Central. Após acessar sua conta na instituição de débito, você dará o consentimento para que a transação que você iniciou seja autorizada.";
  static footerPortal = "Para mais informações, consulte o Portal do Cidadão";
}

export class CardPixOpenFinance {
  static title = "Pague com Pix direto na sua instituição financeira";
  static subtitle = "Com segurança via Open Finance";
  static btnDetalhe = "Ver detalhes da instituição e dos participantes";
  static labelValor = "Valor total:";
  static labelDataPagamento = "Data de pagamento:";
  static descriptionBold =
    "Você será direcionado para a instituição selecionada";
  static descriptionNormal =
    "para confirmar o pagamento, mediante saldo disponível. Para mais detalhes, veja os termos e condições no menu superior.";
  static btnNext = "Avançar";
}

export class SimuladorMessages {
  static selectLabel = "Simule a forma de pagamento escolhida pelo pagador";
  static selectLabelMobile = "Simule a forma de pagamento escolhida";
  static selectDescription =
    "A forma de pagamento escolhida aqui tem somente a finalidade de simular o valor recebido e não influenciará nas opções de pagamento disponibilizadas ao pagador.";
  static mensagensDinamicas = {
    iraReceber: {
      PIX: "Você irá receber",
      BOLETO: "Você irá receber",
      Cartao: {
        "1": {
          rav: "Você irá receber",
          semRav: "Você irá receber",
        },
        "2": {
          rav: "Receba tudo de uma vez",
          semRav: "Receba mês a mês",
        },
      }, 
    },
    emAte: {
      PIX: "na hora!",
      BOLETO: "em até 3 dias",
      Cartao: {
        "1": {
          rav: "em 15 dias",
          semRav: "em 30 dias",
        },
        "2": {
          rav: "em 15 dias",
          semRav: "primeira parcela em 30 dias",
        },
      },
    },
    descricaoGeral: {
      PIX: "Com o pagamento via pix, você recebe o valor integral direto na sua conta corrente.",
      BOLETO: "Com o pagamento via boleto, você irá receber o valor integral depositado diretamente na sua conta corrente e posteriormente as taxas serão debitadas do seu saldo. Acima você visualiza o valor que irá receber após o desconto das taxas.",
      Cartao: {
        comum: "Valores referentes para pagamento via cartão de crédito com as bandeiras Visa e Mastercard. Pagamentos nas bandeiras American Express, Hiper Card e Elo possuem acréscimo de taxas a depender do número de parcelas escolhidas. Este valor também pode sofrer pequenas variações pois os cálculos de juros e taxas variam conforme o número de dias úteis entre a data de vencimento e antecipação das parcelas.",
        rav: "Você possui o plano de antecipação de parcelas e, por isso, recebe todo o valor do pagamento de uma vez só. Se deseja saber mais, acesse suas configurações.",
        semRav: "Atualmente, você <b>não possui</b> o plano de antecipação de parcelas, por isso irá receber conforme os pagamentos forem efetivados. Se deseja alterar seu plano e receber todo o valor do pagamento de uma vez só e em menos tempo, acesse as suas configurações. <b>Essa mudança poderá ser feita após a criação deste link e afetará todos os seus links de pagamento ativos.</b>",
      },
    },
  };
  static errorMessage = "Não foi possível realizar esta simulação. Tente novamente mais tarde";
}


export class EditarValidadeDetalhesLinkMessages {
  static title = {
    success: 'Link Atualizado',
    error: 'Erro ao atualizar link'
  }
  static message = {
    success: 'A data de validade do link foi atualizado com sucesso',
    error: 'Ocorreu um erro ao tentar atualizar o link de pagamento. Tente novamente mais tarde'
  }
}
export class ChargeBack {
  static title = "Saiba mais sobre chargeback";
  static infoText = "O chargeback é a contestação de uma compra feita pelo portador do cartão de crédito diretamente ao emissor. Esse recurso pode ser acionado em casos de fraudes ou não reconhecimento da compra. Entenda mais sobre o processo e como ele pode impactar suas vendas."; 
  static beforeButton = "VER DEPOIS"
  static redirectButton = "IR PARA CHARGEBACK"
}

export class TransactionTable {
  static noneTransaction = "Não foi encontrada nenhuma movimentação referente aos filtros selecionados."
  static specificPeriod = "Período específico"
  static selection = "Selecionar"
  static status = "Status"
  static modality = "Modalidade"
  static nameTitle = "Nome"
  static namePlaceholder = "Buscar por nome"
  static primaryTitle = "Movimentações"
  static custom = "Personalizado"
  static periodTimeInitial = "180 dias"
  static error = " Ocorreu um erro ao buscar movimentações, por favor contate o suporte."
}