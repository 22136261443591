import { StateEnum } from "shared/enums";
import querystring from "querystring";
import {
  RecebedorState,
  BodySaqueProgramado,
  QueryStringRecebedor,
} from "./interfaces";
import { axiosInstance, Endpoints } from "api";
import { Dispatch } from "redux";
import { Action } from "store/interfaces";
import { AuthActions, Recebedor } from "store/auth";
import { createErrorMessage } from "utils";

export enum RecebedorTypes {
  SET_RECEBEDOR = "recebedor/SET_RECEBEDOR",
  NOVO_RECEBEDOR = "recebedor/NOVO_RECEBEDOR",
  NOVO_RECEBEDOR_SUCCESS = "recebedor/NOVO_RECEBEDOR_SUCCESS",
  NOVO_RECEBEDOR_FAILED = "recebedor/NOVO_RECEBEDOR_FAILED",
  NOVO_RECEBEDOR_ERROR_HANDLED = "recebedor/NOVO_RECEBEDOR_ERROR_HANDLED",
  GET_SALDO = "recebedor/GET_SALDO",
  GET_SALDO_SUCCESS = "recebedor/GET_SALDO_SUCCESS",
  GET_SALDO_FAILED = "recebedor/GET_SALDO_FAILED",
  GET_SALDO_ERROR_HANDLED = "recebedor/GET_SALDO_ERROR_HANDLED",
  NOVO_SAQUE = "recebedor/NOVO_SAQUE",
  NOVO_SAQUE_SUCCESS = "recebedor/NOVO_SAQUE_SUCCESS",
  NOVO_SAQUE_FAILED = "recebedor/NOVO_SAQUE_FAILED",
  NOVO_SAQUE_ERROR_HANDLED = "recebedor/NOVO_SAQUE_ERROR_HANDLED",
  GET_CHAVES = "recebedor/GET_CHAVES",
  GET_CHAVES_SUCCESS = "recebedor/GET_CHAVES_SUCCESS",
  GET_CHAVES_FAILED = "recebedor/GET_CHAVES_FAILED",
  GET_CHAVES_ERROR_HANDLED = "recebedor/GET_CHAVES_ERROR_HANDLED",
  TOGGLE_ANTECIPACAO = "recebedor/TOGGLE_ANTECIPACAO",
  TOGGLE_ANTECIPACAO_SUCCESS = "recebedor/TOGGLE_ANTECIPACAO_SUCCESS",
  TOGGLE_ANTECIPACAO_FAILED = "recebedor/TOGGLE_ANTECIPACAO_FAILED",
  TOGGLE_ANTECIPACAO_ERROR_HANDLED = "recebedor/TOGGLE_ANTECIPACAO_ERROR_HANDLED",
  TOGGLE_SAQUE_PROGRAMADO = "recebedor/TOGGLE_SAQUE_PROGRAMADO",
  TOGGLE_SAQUE_PROGRAMADO_SUCCESS = "recebedor/TOGGLE_SAQUE_PROGRAMADO_SUCCESS",
  TOGGLE_SAQUE_PROGRAMADO_FAILED = "recebedor/TOGGLE_SAQUE_PROGRAMADO_FAILED",
  TOGGLE_SAQUE_PROGRAMADO_ERROR_HANDLED = "recebedor/TOGGLE_SAQUE_PROGRAMADO_ERROR_HANDLED",
  GET_ALL_EXTRATO_SALDO_FUTURO = "recebedor/GET_ALL_EXTRATO_SALDO_FUTURO",
  GET_ALL_EXTRATO_SALDO_FUTURO_SUCCESS = "recebedor/GET_ALL_EXTRATO_SALDO_FUTURO_SUCCESS",
  GET_ALL_EXTRATO_SALDO_FUTURO_FAILED = "recebedor/GET_ALL_EXTRATO_SALDO_FUTURO_FAILED",
  GET_ALL_EXTRATO_SALDO_FUTURO_ERROR_HANDLED = "recebedor/GET_ALL_EXTRATO_SALDO_FUTURO_ERROR_HANDLED",
  GET_TARIFAS_BOLETO = "recebedor/GET_TARIFAS_BOLETO",
  GET_TARIFAS_BOLETO_SUCCESS = "recebedor/GET_TARIFAS_BOLETO_SUCCESS",
  GET_TARIFAS_BOLETO_FAILED = "recebedor/GET_TARIFAS_BOLETO_FAILED",
  GET_TARIFAS_BOLETO_ERROR_HANDLED = "recebedor/GET_TARIFAS_BOLETO_ERROR_HANDLED",
  GET_RECEBEDOR = "recebedor/GET_RECEBEDOR",
  GET_RECEBEDOR_SUCCESS = "recebedor/GET_RECEBEDOR_SUCCESS",
  GET_RECEBEDOR_FAILED = "recebedor/GET_RECEBEDOR_FAILED",
  GET_RECEBEDOR_ERROR_HANDLED = "recebedor/GET_RECEBEDOR_ERROR_HANDLED",
  RESET_GET_SALDO = "recebedor/RESET_GET_SALDO",
  RESET_NOVO_RECEBEDOR = "recebedor/RESET_NOVO_RECEBEDOR",
  RESET_NOVO_SAQUE = "recebedor/RESET_NOVO_SAQUE",
  RESET_TOGGLE_ANTECIPACAO = "recebedor/RESET_TOGGLE_ANTECIPACAO",
  RESET_TOGGLE_SAQUE_PROGRAMADO = "recebedor/RESET_TOGGLE_SAQUE_PROGRAMADO",
  RESET_GET_ALL_EXTRATO_SALDO_FUTURO = "recebedor/RESET_GET_ALL_EXTRATO_SALDO_FUTURO",
}

export const initialState: RecebedorState = {
  novoRecebedor: {
    state: StateEnum.IDLE,
    error: null,
  },
  getRecebedor: {
    state: StateEnum.IDLE,
    error: null,
  },
  getSaldo: {
    state: StateEnum.IDLE,
    error: null,
  },
  getChaves: {
    state: StateEnum.IDLE,
    error: null,
    data: [],
  },
  novoSaque: {
    state: StateEnum.IDLE,
    error: null,
  },
  toggleAntecipacao: {
    state: StateEnum.IDLE,
    error: null,
  },
  toggleSaqueProgramado: {
    state: StateEnum.IDLE,
    error: null,
  },
  getAllExtratoSaldoFuturo: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      recebiveis: [],
    },
  },
  getTarifasBoleto: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      id: "",
      type: "",
      title: "",
      columns: [],
      data: [],
    },
  },
  recebedor: [],
};

export function reducer(state = initialState, action: Action): RecebedorState {
  switch (action.type) {
    case RecebedorTypes.SET_RECEBEDOR:
      return {
        ...state,
        recebedor: {
          ...state.recebedor,
          ...action.payload,
        },
      };
    case RecebedorTypes.NOVO_RECEBEDOR:
      return {
        ...state,
        novoRecebedor: {
          ...state.novoRecebedor,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.NOVO_RECEBEDOR_SUCCESS:
      return {
        ...state,
        recebedor: {
          ...state.recebedor,
          ...action.payload.data,
        },
        novoRecebedor: {
          ...state.novoRecebedor,
          state: StateEnum.COMPLETE,
        },
      };
    case RecebedorTypes.NOVO_RECEBEDOR_FAILED:
      return {
        ...state,
        novoRecebedor: {
          ...state.novoRecebedor,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.NOVO_RECEBEDOR_ERROR_HANDLED:
      return {
        ...state,
        novoRecebedor: {
          ...state.novoRecebedor,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.GET_SALDO:
      return {
        ...state,
        getSaldo: {
          ...state.getSaldo,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.GET_SALDO_SUCCESS:
      return {
        ...state,
        recebedor: {
          ...state.recebedor,
          ...action.payload.data,
        },
        getSaldo: {
          ...state.getSaldo,
          state: StateEnum.COMPLETE,
        },
      };
    case RecebedorTypes.GET_SALDO_FAILED:
      return {
        ...state,
        getSaldo: {
          ...state.getSaldo,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.GET_SALDO_ERROR_HANDLED:
      return {
        ...state,
        getSaldo: {
          ...state.getSaldo,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.NOVO_SAQUE:
      return {
        ...state,
        novoSaque: {
          ...state.novoSaque,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.NOVO_SAQUE_SUCCESS:
      return {
        ...state,
        novoSaque: {
          ...state.novoSaque,
          state: StateEnum.COMPLETE,
        },
      };
    case RecebedorTypes.NOVO_SAQUE_FAILED:
      return {
        ...state,
        novoSaque: {
          ...state.novoSaque,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.NOVO_SAQUE_ERROR_HANDLED:
      return {
        ...state,
        novoSaque: {
          ...state.novoSaque,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.GET_CHAVES:
      return {
        ...state,
        getChaves: {
          ...state.getChaves,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.GET_CHAVES_SUCCESS:
      return {
        ...state,
        getChaves: {
          ...state.getChaves,
          data: action.payload.data,
          state: StateEnum.COMPLETE,
        },
      };
    case RecebedorTypes.GET_CHAVES_FAILED:
      return {
        ...state,
        getChaves: {
          ...state.getChaves,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.GET_CHAVES_ERROR_HANDLED:
      return {
        ...state,
        getChaves: {
          ...state.getChaves,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.TOGGLE_ANTECIPACAO:
      return {
        ...state,
        toggleAntecipacao: {
          ...state.toggleAntecipacao,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.TOGGLE_ANTECIPACAO_SUCCESS:
      return {
        ...state,
        toggleAntecipacao: {
          ...state.toggleAntecipacao,
          state: StateEnum.COMPLETE,
        },
        recebedor: {
          ...state.recebedor,
          rav: {
            ...state.recebedor.rav,
            automatica: action.payload.automatica,
          },
        },
      };
    case RecebedorTypes.TOGGLE_ANTECIPACAO_FAILED:
      return {
        ...state,
        toggleAntecipacao: {
          ...state.toggleAntecipacao,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.TOGGLE_ANTECIPACAO_ERROR_HANDLED:
      return {
        ...state,
        toggleAntecipacao: {
          ...state.toggleAntecipacao,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO:
      return {
        ...state,
        toggleSaqueProgramado: {
          ...state.toggleSaqueProgramado,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO_SUCCESS:
      return {
        ...state,
        toggleSaqueProgramado: {
          ...state.toggleSaqueProgramado,
          state: StateEnum.COMPLETE,
        },
        recebedor: {
          ...state.recebedor,
          saqueProgramado: {
            ...state.recebedor.saqueProgramado,
            ...action.payload.body,
          },
        },
      };
    case RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO_FAILED:
      return {
        ...state,
        toggleSaqueProgramado: {
          ...state.toggleSaqueProgramado,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO_ERROR_HANDLED:
      return {
        ...state,
        toggleSaqueProgramado: {
          ...state.toggleSaqueProgramado,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO:
      return {
        ...state,
        getAllExtratoSaldoFuturo: {
          ...state.getAllExtratoSaldoFuturo,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO_SUCCESS:
      return {
        ...state,
        getAllExtratoSaldoFuturo: {
          ...state.getAllExtratoSaldoFuturo,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      };
    case RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO_FAILED:
      return {
        ...state,
        getAllExtratoSaldoFuturo: {
          ...state.getAllExtratoSaldoFuturo,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO_ERROR_HANDLED:
      return {
        ...state,
        getAllExtratoSaldoFuturo: {
          ...state.getAllExtratoSaldoFuturo,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.GET_TARIFAS_BOLETO:
      return {
        ...state,
        getTarifasBoleto: {
          ...state.getTarifasBoleto,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.GET_TARIFAS_BOLETO_SUCCESS:
      return {
        ...state,
        getTarifasBoleto: {
          ...state.getTarifasBoleto,
          data: action.payload.data,
          state: StateEnum.COMPLETE,
        },
      };
    case RecebedorTypes.GET_TARIFAS_BOLETO_FAILED:
      return {
        ...state,
        getTarifasBoleto: {
          ...state.getTarifasBoleto,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.GET_TARIFAS_BOLETO_ERROR_HANDLED:
      return {
        ...state,
        getTarifasBoleto: {
          ...state.getTarifasBoleto,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case RecebedorTypes.RESET_GET_ALL_EXTRATO_SALDO_FUTURO:
      return {
        ...state,
        getAllExtratoSaldoFuturo: initialState.getAllExtratoSaldoFuturo,
      };
    case RecebedorTypes.RESET_GET_SALDO:
      return {
        ...state,
        getSaldo: initialState.getSaldo,
      };
    case RecebedorTypes.RESET_NOVO_RECEBEDOR:
      return {
        ...state,
        novoRecebedor: initialState.novoRecebedor,
      };
    case RecebedorTypes.RESET_NOVO_SAQUE:
      return {
        ...state,
        novoSaque: initialState.novoSaque,
      };
    case RecebedorTypes.RESET_TOGGLE_ANTECIPACAO:
      return {
        ...state,
        toggleAntecipacao: initialState.toggleAntecipacao,
      };
    case RecebedorTypes.RESET_TOGGLE_SAQUE_PROGRAMADO:
      return {
        ...state,
        toggleSaqueProgramado: initialState.toggleSaqueProgramado,
      };
    case RecebedorTypes.GET_RECEBEDOR:
      return {
        ...state,
        getRecebedor: {
          ...state.getRecebedor,
          state: StateEnum.LOADING,
        },
      };
    case RecebedorTypes.GET_RECEBEDOR_SUCCESS:
      return {
        ...state,
        recebedor: {
          ...state.recebedor,
          ...action.payload.data,
        },
        getRecebedor: {
          ...state.novoRecebedor,
          state: StateEnum.COMPLETE,
        },
      };
    case RecebedorTypes.GET_RECEBEDOR_FAILED:
      return {
        ...state,
        getRecebedor: {
          ...state.getRecebedor,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case RecebedorTypes.GET_RECEBEDOR_ERROR_HANDLED:
      return {
        ...state,
        getRecebedor: {
          ...state.getRecebedor,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    default:
      return state;
  }
}

export class RecebedorActions {
  static setRecebedor(recebedor: Recebedor): Action {
    return {
      type: RecebedorTypes.SET_RECEBEDOR,
      payload: recebedor,
    };
  }

  static novoRecebedor(): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.NOVO_RECEBEDOR });
        const response = await axiosInstance.post(
          Endpoints.recebedor.recebedor()
        );
        dispatch({
          type: RecebedorTypes.NOVO_RECEBEDOR_SUCCESS,
          payload: { data: response.data },
        });
        dispatch(AuthActions.setIsRecebedor(response.data, true));
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.NOVO_RECEBEDOR_FAILED,
          payload: { error },
        });
        dispatch(AuthActions.setIsRecebedor({} as Recebedor, false));
      }
    };
  }

  static resetRecebedor(): Action {
    return { type: RecebedorTypes.RESET_NOVO_RECEBEDOR };
  }

  static getSaldo(id: string) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.GET_SALDO });

        const { data } = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/${id}/saldo`
        );

        dispatch({
          type: RecebedorTypes.GET_SALDO_SUCCESS,
          payload: { data },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.GET_SALDO_FAILED,
          payload: { error },
        });
      }
    };
  }

  static novoSaque(body: any): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.NOVO_SAQUE });

        const response = await axiosInstance.post(
          `${Endpoints.recebedor.recebedor()}/sacar`,
          body
        );

        dispatch({
          type: RecebedorTypes.NOVO_SAQUE_SUCCESS,
          payload: { data: response.data },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.NOVO_SAQUE_FAILED,
          payload: { error },
        });
      }
    };
  }

  static getChaves(id: string) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.GET_CHAVES });

        const { data } = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/${id}/chaves`
        );

        dispatch({
          type: RecebedorTypes.GET_CHAVES_SUCCESS,
          payload: { data },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.GET_CHAVES_FAILED,
          payload: { error },
        });
      }
    };
  }

  static toggleAntecipacao(id: string, automatica: boolean): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.TOGGLE_ANTECIPACAO });

        await axiosInstance.patch(
          `${Endpoints.recebedor.recebedor()}/${id}/ravAutomatica`
        );

        dispatch({
          type: RecebedorTypes.TOGGLE_ANTECIPACAO_SUCCESS,
          payload: { automatica },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.TOGGLE_ANTECIPACAO_FAILED,
          payload: { error },
        });
      }
    };
  }

  static toggleSaqueProgramado(id: string, body: BodySaqueProgramado): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO });

        await axiosInstance.patch(
          `${Endpoints.recebedor.recebedor()}/${id}/saqueProgramado`,
          body
        );

        dispatch({
          type: RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO_SUCCESS,
          payload: { body },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.TOGGLE_SAQUE_PROGRAMADO_FAILED,
          payload: { error },
        });
      }
    };
  }

  static getAllExtratoSaldoFuturo(
    id: string,
    query: QueryStringRecebedor
  ): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO });

        const response = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/${id}/saldo/extrato?${querystring.stringify(
            query
          )}`
        );

        dispatch({
          type: RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO_SUCCESS,
          payload: { data: response.data },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.GET_ALL_EXTRATO_SALDO_FUTURO_FAILED,
          payload: { error },
        });
      }
    };
  }

  static getTarifasBoleto() {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.GET_TARIFAS_BOLETO });

        const { data } = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/tarifas/cobranca`
        );

        dispatch({
          type: RecebedorTypes.GET_TARIFAS_BOLETO_SUCCESS,
          payload: { data },
        });
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.GET_TARIFAS_BOLETO_FAILED,
          payload: { error },
        });
      }
    };
  }

  static getRecebedor(headers: {}): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: RecebedorTypes.GET_RECEBEDOR });
        const response = await axiosInstance.get(
          Endpoints.recebedor.recebedor(),
          { headers: headers && headers }
        );
        dispatch({
          type: RecebedorTypes.GET_RECEBEDOR_SUCCESS,
          payload: { data: response.data },
        });
        dispatch(AuthActions.setIsRecebedor(response.data, true));
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch({
          type: RecebedorTypes.GET_RECEBEDOR_FAILED,
          payload: { error },
        });
      }
    };
  }

  static resetNovoSaque(): Action {
    return { type: RecebedorTypes.RESET_NOVO_SAQUE };
  }

  static resetToggleAntecipacao(): Action {
    return { type: RecebedorTypes.RESET_TOGGLE_ANTECIPACAO };
  }

  static resetToggleSaqueProgramado(): Action {
    return { type: RecebedorTypes.RESET_TOGGLE_SAQUE_PROGRAMADO };
  }

  static resetGetAllExtradoSaldoFuturo(): Action {
    return { type: RecebedorTypes.RESET_GET_ALL_EXTRATO_SALDO_FUTURO };
  }

  static defineDataAlert(recebedorId: string): any {
    return async (dispatch: Dispatch) => {
      try {
        const response = await axiosInstance.post(
          `${Endpoints.recebedor.recebedor()}/${recebedorId}/dataAlerta`
        );
        dispatch(RecebedorActions.setRecebedor({
          ...response.data
        }));
      } catch (err) {
        const error = createErrorMessage(err);
      }
    };
  }

  static confirmarLeituraAjuda(recebedorId: string): any {
    return async (dispatch: Dispatch) => {
      try {
        const response = await axiosInstance.post(
          `${Endpoints.recebedor.recebedor()}/${recebedorId}/leitura/chargeback`
        );
        dispatch(RecebedorActions.setRecebedor({
          ...response.data
        }));
      } catch (err) {
        const error = createErrorMessage(err);
      }
    };
  }

}
