import React from "react";
import { useTypedSelector } from "hooks";
import { useDispatch } from "react-redux";
import { StateEnum } from "shared/enums";
import { LinkPagamentoActions } from "store/linkPagamento";
import { Button, Modal } from "components";
import { Grid, useMediaQuery } from "@material-ui/core";
import theme from "theme";
import { EditarValidadeDetalhesLinkMessages } from "shared/messages";

export default function ModalRetornoEdicao() {
  const dispatch = useDispatch();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("xs"));
  const { editState = StateEnum.IDLE } = useTypedSelector(
    (state) => state.linkPagamento.link
  );
  const showModal =
    editState === StateEnum.COMPLETE || editState === StateEnum.ERROR;

  const handleCloseModal = () => {
    dispatch(LinkPagamentoActions.closeModalValidade());
  };

  const title =
    editState === StateEnum.COMPLETE
      ? EditarValidadeDetalhesLinkMessages.title.success
      : EditarValidadeDetalhesLinkMessages.title.error;

  const message =
    editState === StateEnum.COMPLETE
      ? EditarValidadeDetalhesLinkMessages.message.success
      : EditarValidadeDetalhesLinkMessages.message.error;

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      title={title}
      fullScreen={mediaQuery}
    >
      <Grid container direction="column">
        <p>{message}</p>

        <Button style={{ marginLeft: "auto" }} onClick={handleCloseModal}>
          Entendi
        </Button>
      </Grid>
    </Modal>
  );
}
