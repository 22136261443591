import React, { useCallback, useMemo } from "react";
import { InputLabel, MenuItem, SelectProps } from "@material-ui/core";
import { SelectOption } from "components/SelectBase";
import { FormControl, Select } from "./styles";
import { ExpandMoreOutlined } from "@material-ui/icons";

interface SelectMuiProps extends SelectProps {
  options: SelectOption[];
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  onChange: (val: any) => void;
  value: SelectOption;
	menuPlacement?: 'top' | 'bottom'
}

export function SelectMui({
  options,
  label,
  loading,
  disabled,
  margin,
  fullWidth = true,
  onChange: onChangeCallback,
  value,
	menuPlacement = "bottom",
  ...props
}: SelectMuiProps) {
  const onChangeHandler = useCallback(
    (element: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (onChangeCallback && typeof onChangeCallback === "function") {
        const opcaoSelecionada = options.find(
          (option) => option.value === element.target.value
        );
        onChangeCallback(opcaoSelecionada);
      }
    },
    [onChangeCallback]
  );

  return (
    <FormControl
      disabled={loading || disabled}
      margin={margin}
      fullWidth={fullWidth}
      variant="outlined"
      hiddenLabel={false}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value?.value ? value.value : ""}
        label={label}
        IconComponent={ExpandMoreOutlined}
        onChange={onChangeHandler}
        MenuProps={{
          anchorOrigin: {
            vertical: menuPlacement,
            horizontal: "left",
          },
          transformOrigin: {
            vertical: menuPlacement === 'top' ? 'bottom' : 'top',
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
