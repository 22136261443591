import React from 'react';
import { Wrapper } from './styles';
import { FiltroItem } from './index';

interface FiltrosProps {
    setFiltro: (dia: number) => void
    periodoSelecionado: number
    listaPeriodos: number[]
}


const Filtros = ({ setFiltro, periodoSelecionado, listaPeriodos }: FiltrosProps) => {  
    const setDiaFiltro = (value: number) => {      
        setFiltro(value)
    }

    return (
        <Wrapper>
            {listaPeriodos && listaPeriodos.map((periodo) => 
                <FiltroItem key={periodo} setFiltro={() => setDiaFiltro(periodo)} texto={`${periodo} dias`} active={periodoSelecionado === periodo} />
            )}           
        </Wrapper>
    );
}

export default Filtros;