import React, { forwardRef, useMemo } from 'react';
import {
  FormControl,
  InputBaseProps,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { ErrorMsg, OutlinedInput } from './styles';
import FadeIn from 'react-fade-in';
import { Label } from 'components';
import InputMask from 'react-input-mask';
import { NumberFormat, TaxesFormat } from 'components/masks'
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

interface InputProps extends InputBaseProps {
  label?: string;
  loading?: boolean;
  'data-testid'?: string;
  errorMsg?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  mask?: string;
  inputComponent?: any;
  maskMoney?: boolean;
  maskTaxes?: boolean;
  maskDocument?: boolean;
}

const Input = forwardRef((props: InputProps, ref) => {
  const {
    label,
    fullWidth = true,
    margin = 'dense',
    endAdornment,
    disabled,
    loading = false,
    placeholder,
    'data-testid': dataTestId,
    error,
    errorMsg,
    textAlign,
    color,
    mask,
    maskMoney,
    maskTaxes,
    maskDocument,
    inputComponent,
    ...rest
  } = props;

  const loadingAdornment = (
    <InputAdornment variant='outlined' position='end'>
      <CircularProgress size={15} />
    </InputAdornment>
  );

  const inputComponentProp = useMemo(() => {
    if (mask) {
      return (props: any) => <InputMask maskChar='' {...props} mask={mask} />;
    } else if (maskMoney) {
      return NumberFormat as any
    } else if (maskTaxes) {
      return TaxesFormat as any
    } else if (maskDocument) {
      return (props: any) => <CpfCnpj className={props.className} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onBlur={props.onBlur} />;
    }
    else {
      return inputComponent;
    }
  }, [mask, maskMoney, maskTaxes, maskDocument, inputComponent]);

  return (
    <FormControl
      disabled={loading || disabled}
      margin={margin}
      fullWidth={fullWidth}
    >
      <Label color={color} textAlign={textAlign}>
        {label ?? label}
        <OutlinedInput
          fullWidth={fullWidth}
          endAdornment={loading ? loadingAdornment : endAdornment}
          placeholder={loading ? 'Carregando...' : placeholder}
          inputProps={{
            'data-testid': dataTestId,
            ref,
          }}
          error={error}
          inputComponent={inputComponentProp}
          {...rest}
        />
      </Label>
      {!!error && (
        <FadeIn>
          <ErrorMsg textAlign={textAlign}>{errorMsg}</ErrorMsg>
        </FadeIn>
      )}
    </FormControl>
  );
});

export default Input;
