import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks';
import StringUtils from 'utils/StringUtils'
import { isLoading } from 'utils/isLoading';
import { RecebedorActions } from 'store/recebedor/recebedor';
import { UnicredPayLogo } from 'shared/styles';
import { BotaoSacar, Valores, Dados, Prazo, Descricao, Valor, Item, Divisor, SaqueProgramado } from './styles';

const ResumoSaque = () => {
    const dispatch = useDispatch();
    const { recebedor } = useTypedSelector(state => state.recebedor);
    const novoSaque = useTypedSelector(state => state.recebedor.novoSaque);
    const auth = useTypedSelector(state => state.auth);
    const saqueProgramadoEnabled = recebedor.saqueProgramado?.enabled;
    const diaTransferenciaSaqueProgramado = recebedor.saqueProgramado?.diaTransferenciaMensal;
    const saldo = recebedor.saldo;
    const tarifaSaque = auth.recebedor.splitRule.saque;
    const disponivelSaque = recebedor.saldo - auth.recebedor.splitRule.saque;

    const sacar = () => {
        dispatch(RecebedorActions.novoSaque({
            id: auth.recebedor.id || '',
            valor: disponivelSaque,
        }))
    }

    return (
        <>
            <UnicredPayLogo />
            <Valores>
                <Item>
                    <Descricao>Saldo</Descricao>
                    <Valor>{StringUtils.format.formatBRL(saldo)}</Valor>
                </Item>
                <Item>
                    <Descricao>Tarifa de Saque (-)</Descricao>
                    <Valor>{StringUtils.format.formatBRL(tarifaSaque)}</Valor>
                </Item>
                <Divisor />
                <Item>
                    <Descricao>
                        <strong>Valor disponível para saque</strong>
                    </Descricao>
                    <Valor>
                        <strong>{StringUtils.format.formatBRL(disponivelSaque)}</strong>
                    </Valor>
                </Item>
            </Valores>
            <Dados>
                <Item>
                    <Descricao>Agência</Descricao>
                    <Valor>{auth.recebedor.agencia}</Valor>
                </Item>
                <Item>
                    <Descricao>Conta </Descricao>
                    <Valor>{auth.recebedor.numConta}</Valor>
                </Item>
                <Item>
                    <Descricao>Recebedor </Descricao>
                    <Valor>{auth.recebedor.nome}</Valor>
                </Item>
            </Dados>            
            <Prazo>Saques solicitados até as <strong>15h</strong>, em dias úteis, são processados no mesmo dia, caso contrário o crédito acontecerá no próximo dia útil.</Prazo>
            {saqueProgramadoEnabled && (<SaqueProgramado> O saque programado mensal está agendado para dia {diaTransferenciaSaqueProgramado}.</SaqueProgramado>)}
            <BotaoSacar onClick={() => sacar()} loading={isLoading(novoSaque.state)}> Confirmar ({StringUtils.format.formatBRL(disponivelSaque)}) </BotaoSacar>
        </>
    );
}

export default ResumoSaque;