import styled from 'styled-components'
import { Colors } from 'theme'

import { InfoOutlined } from '@material-ui/icons'

export const Header = styled.header`
  height: 115px;
  width: 100%;
  background-color: ${Colors.danger};
  color: ${Colors.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.section`
  margin-top: 1rem;
  padding: 1.5rem;
  text-align: center;

  p {
    margin: 0;
  }
`

export const InfoIcon = styled(InfoOutlined)`
  color: ${Colors.light};
  font-size: 60px;
  margin-top: 1rem;
`
