import React from 'react';
import { FiltroChip } from "./styles";

interface FiltroItemProps {
    texto: string;
    active: boolean;
    setFiltro: () => void;
}

export default function FiltroItem({ texto, active, setFiltro }: FiltroItemProps) {

    return (
        <FiltroChip onClick={setFiltro} label={texto} color={active ? "primary" : "default"} />
    );
}
