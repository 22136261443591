import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import { Modal } from 'components'
import { useTypedSelector } from 'hooks'
import { StateEnum } from 'shared/enums'
import { isLoading } from 'utils'

import { LinkPagamentoActions } from 'store/linkPagamento'

import { OperationModalLink, PropsModal } from './Interfaces'
import Delete from '../ModalLink/Delete'
import Sucess from '../ModalLink/Sucess'

import {
  MESSAGE_MODAL_SUCESS,
  QUESTION_MODAL_DELETE,
  TITLE_MODAL_DELTE,
  TITLE_MODAL_SUCESS,
} from './Constants'

import { Content } from './styles'

export default function ModalLinks(props: PropsModal) {
  const { isOpen, onClose, data } = props
  const [uiModal, setUiModal] = useState(data.operation)

  const stateDelete = useTypedSelector((state) => state.linkPagamento.delete)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      uiModal === OperationModalLink.DELETE &&
      stateDelete?.state === StateEnum.COMPLETE
    ) {
      setUiModal(OperationModalLink.SUCESS)
    }
  }, [stateDelete, uiModal])

  const onConfirm = async () => {
    dispatch(LinkPagamentoActions.deleteLink({ id: data.id }))
  }

  const typeModal = () => {
    if (
      uiModal === OperationModalLink.DELETE &&
      stateDelete.state === StateEnum.IDLE
    )
      return (
        <Delete
          onClose={onClose}
          onConfirm={onConfirm}
          title={TITLE_MODAL_DELTE}
          question={`${QUESTION_MODAL_DELETE}  ${data.nome} ?`}
          idLink={data.id}
        />
      )
    if (uiModal === OperationModalLink.SUCESS) {
      return (
        <Sucess
          onClose={onClose}
          title={TITLE_MODAL_SUCESS}
          message={MESSAGE_MODAL_SUCESS}
        />
      )
    }
  }

  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      disableCloseIcon
      onClose={() => null}
      open={isOpen}
      noPadding
    >
      {isLoading(stateDelete.state) ? (
        <Content>
          <CircularProgress />
        </Content>
      ) : (
        typeModal()
      )}
    </Modal>
  )
}
