import styled from 'styled-components';
import { Colors } from 'theme';

interface LabelProps {
  color?: string;
}

export const Label = styled.label<LabelProps>`
  color: ${({ color }) => (color ? color : Colors.typography)};
  font-size: 15px;
  display: flex;
  flex-direction: column;

  & > div:last-child {
    margin-top: 0.5rem;
  }
`;

export const ErrorMsg = styled.small`
  margin-top: 0.25rem;
  color: ${Colors.danger};
`;
