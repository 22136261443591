import styled from 'styled-components'
import { CSVLink } from 'react-csv';
import { Alert } from '@material-ui/lab';
import { Button, Card } from 'components';
import { Colors } from 'theme';

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.4rem;
  justify-content: flex-start;
  flex-direction: column; 
`
export const CsvExport = styled(CSVLink)`
  visibility: hidden;
`

export const InfoPeriodo = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: true,
}))`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const Subtitle = styled.p`
  color: ${Colors.lightGrey};
  font-size: 1rem;
  margin: 0;
`

export const GerarButton = styled(Button)`
  margin-bottom: 5px;
`;

export const ErrorAlert = styled(Alert)`
  margin-top: 1.5rem;
`;