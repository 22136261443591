import React from 'react';

import { Wrapper, Title, Paragraph } from './styles';
import { Check } from '@material-ui/icons';

export default function Sucesso() {
  return (
    <Wrapper>
      <Check />
      <Title>Saque Realizado</Title>
      <Paragraph>Seu saque foi realizado com sucesso.</Paragraph>
    </Wrapper>
  );
}
