import React, { useMemo } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { multiLazy } from "./multiLazy";
import { useTypedSelector } from "hooks";
import { CenteredLoader } from "components";
import RoutesPaths from "./RoutesPaths";
import KeycloakService from "KeycloakService";
import PaginaNaoEncontrada from "components/PaginaNaoEncontrada";

interface RouteWrapperProps extends RouteProps {
  isNotMobile?: boolean;
}

interface PrivateRoutesProps {
  isAuth: boolean;
}

const WrapperPrivateRoute = (props: RouteWrapperProps) => {
  const { isNotMobile, ...rest } = props;
  if (isNotMobile) return <Redirect to={RoutesPaths.dashboard} />;
  return <Route {...rest} />;
};

const PrivateRoutes = ({isAuth}: PrivateRoutesProps) => {
  const [
    Layout,
    Recebimentos,
    Dashboard,
    Links,
    Transacoes,
    TaxasMDR,
    Ajuda,
    // Relatorios,
    Configuracoes,
    NovoLink,
    Termos,
  ] = useMemo(
    () =>
      multiLazy([
        () => import("components/Layout"),
        () => import("pages/Recebimentos"),
        () => import("pages/Dashboard"),
        () => import("pages/Links"),
        () => import("pages/Transacoes"),
        () => import("pages/TaxasMDR"),
        () => import("pages/Ajuda"),
        // () => import("pages/Relatorios"),
        () => import("pages/Configuracoes"),
        () => import("pages/NovoLink"),
        () => import("pages/Termos"),
        () => import("@material-table/core"),
      ]),
    []
  );

  const plataforma = useTypedSelector((state) => state.auth.plataforma);

  const keycloak = useTypedSelector((state) => state.auth?.metodoLogin?.keycloak);
  const { toogle_rav: toggleRav } = useTypedSelector((state) => state.config);

  const render = (component: JSX.Element): () =>  JSX.Element => {
    const elemento = !isAuth ? <CenteredLoader /> : <Layout> {component} </Layout> 

    return () => {
      if (!KeycloakService.isAuthenticated() && keycloak) {
        KeycloakService.login();
      }
      
      return elemento;
    }
  }

  return (
    <>
      <Route exact path={RoutesPaths.dashboard} render={render(<Dashboard/>)} />
      <Route path={RoutesPaths.links.base} render={render(<Links/>)} />
      <Route path={RoutesPaths.transacoes.base} render={render(<Transacoes/>)} />
      <Route path={RoutesPaths.recebimentos.base} render={render(<Recebimentos/>)} />
      <Route path={RoutesPaths.taxasmdr} render={render(<TaxasMDR/>)} />
      {/* <WrapperPrivateRoute
        path={RoutesPaths.relatorios}
        render={render(<Relatorios/>)}
        isNotMobile={Boolean(plataforma)}
      /> */}
      <Route path={RoutesPaths.ajuda.base} render={render(<Ajuda/>)} />
      <Route path={RoutesPaths.configuracoes} render={render(toggleRav ? <Configuracoes/> : <></>)} />
      <Route path={RoutesPaths.termos} render={render(toggleRav ? <Termos/> : <></>)} />
      <Route path={RoutesPaths.novoLink} render={render(<NovoLink/>)} />
      <Route path="*">{render(<PaginaNaoEncontrada/>)}</Route>
    </>
  );
};

export default PrivateRoutes;