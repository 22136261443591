import React from "react";

import {
  BlueCard,
  ContentWrapper,
  DataInfo,
  InfoIcon,
  SubContentWrapper,
  TitleStyled,
  Wrapper,
} from "./styles";
import {
  DetalhePagamento,
} from "store/pagamento/interfaces";
import StringUtils from "utils/StringUtils";
import { Grid } from "@material-ui/core";
import { EstornoMessages } from "shared/messages";

interface MainContentProps {
  idPagamento: string | undefined;
  pagamento: DetalhePagamento | null;
}

export default function MainContent({idPagamento, pagamento}: MainContentProps) {


  return (
    <>
    <Wrapper>
      <ContentWrapper>
        <SubContentWrapper>
          <Grid item>
            <TitleStyled>Data</TitleStyled>
            <DataInfo>
              {pagamento?.createdDate ? pagamento?.createdDate : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Nome cadastro</TitleStyled>
            <DataInfo>{pagamento?.nomePagador}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Portador cartão</TitleStyled>
            <DataInfo>
              {pagamento?.portadorCartao ? pagamento?.portadorCartao : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Documento</TitleStyled>
            <DataInfo>
              {pagamento?.documento ? StringUtils.format.formatDocument(String(pagamento?.documento)) : '-'}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Modalidade</TitleStyled>
            <DataInfo>{pagamento?.modalidade ?? '-'}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Status</TitleStyled>
            <DataInfo>{pagamento?.status}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Valor pago</TitleStyled>
            <DataInfo>{pagamento?.valorPago ? StringUtils.format.formatBRL(Number(pagamento?.valorPago)) : '-'}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Desconto</TitleStyled>
            <DataInfo>
              {pagamento?.valorDesconto ? StringUtils.format.formatBRL(Number(pagamento?.valorDesconto)) : '-'}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Valor a receber</TitleStyled>
            <DataInfo>{pagamento?.valorLiquido ? StringUtils.format.formatBRL(Number(pagamento?.valorLiquido)) : '-'}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Link</TitleStyled>
            <DataInfo>{pagamento?.link ?? '-'}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Operação</TitleStyled>
            <DataInfo>{pagamento?.operacao ?? '-'}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Bandeira</TitleStyled>
            <DataInfo>
              {pagamento?.bandeira ? pagamento?.bandeira : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Parcelamento</TitleStyled>
            <DataInfo>
              {pagamento?.parcelamento ? pagamento?.parcelamento : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Descrição pagamento</TitleStyled>
            <DataInfo>{pagamento?.descricao ? pagamento?.descricao : "-"}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>E-mail</TitleStyled>
            <DataInfo>{pagamento?.email ?? '-'}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Telefone</TitleStyled>
            <DataInfo>
              {pagamento?.telefone
                ? StringUtils.format.formatCelularComplex(
                    String(pagamento?.telefone)
                  )
                : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Endereço</TitleStyled>
            <DataInfo>{pagamento?.endereco ? pagamento?.endereco : "-"}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Número/complemento</TitleStyled>
            <DataInfo>
              {pagamento?.numeroComplemento ? pagamento?.numeroComplemento : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>CEP</TitleStyled>
            <DataInfo>
              {pagamento?.cep
                ? StringUtils.format.formatCep(String(pagamento?.cep))
                : "-"}
            </DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Cidade</TitleStyled>
            <DataInfo>{pagamento?.cidade ? pagamento?.cidade : "-"}</DataInfo>
          </Grid>
          <Grid item>
            <TitleStyled>Estado</TitleStyled>
            <DataInfo>{pagamento?.estado ? pagamento?.estado : "-"}</DataInfo>
          </Grid>
        </SubContentWrapper>
      </ContentWrapper>
      {pagamento?.status === "Recusado" && pagamento?.motivoRecusa && pagamento?.modalidade === "Cartão de Crédito" && (
        <BlueCard>
          <InfoIcon />
          {pagamento?.motivoRecusa}
        </BlueCard>
      )}
      {(pagamento?.status === "Devolvido" || pagamento?.status === "Estornado") && pagamento?.motivoEstorno && (
        <BlueCard>
        <InfoIcon />
        {pagamento?.motivoEstorno}
      </BlueCard>
      )}
      {pagamento?.status === "Pago" && pagamento?.modalidade === "Boleto" && (
        <BlueCard>
        <InfoIcon />
          {EstornoMessages.estornoBoleto}
      </BlueCard>
      )}      
    </Wrapper>
    
    </>
  );
}
