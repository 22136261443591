import { StateEnum } from 'shared/enums'
import { Action } from 'store/interfaces'
import { Dispatch } from 'redux'
import {
  CooperativaTaxaState,
  GetCooperativaTaxasSuccess,
  QueryString,
} from './interfaces'
import { axiosInstance, Endpoints } from 'api'
import { createErrorMessage } from 'utils'

export enum CooperativaTaxasTypes {
  GET_COOPERATIVA_TAXAS = 'taxas/GET_COOPERATIVA_TAXAS',
  GET_COOPERATIVA_TAXAS_SUCCESS = 'taxas/GET_COOPERATIVA_TAXAS_SUCCESS',
  GET_COOPERATIVA_TAXAS_FAILED = 'taxas/GET_COOPERATIVA_TAXAS_FAILED',
  GET_COOPERATIVA_TAXAS_ERROR_HANDLED = 'taxas/GET_COOPERATIVA_TAXAS_ERROR_HANDLED',
  GET_CONFIGURACAO_SAQUE_PROGRAMADO = 'taxas/GET_CONFIGURACAO_SAQUE_PROGRAMADO',
  GET_CONFIGURACAO_SAQUE_PROGRAMADO_SUCCESS = 'taxas/GET_CONFIGURACAO_SAQUE_PROGRAMADO_SUCCESS',
  GET_CONFIGURACAO_SAQUE_PROGRAMADO_FAILED = 'taxas/GET_CONFIGURACAO_SAQUE_PROGRAMADO_FAILED',
  GET_CONFIGURACAO_SAQUE_PROGRAMADO_ERROR_HANDLED = 'taxas/GET_CONFIGURACAO_SAQUE_PROGRAMADO_ERROR_HANDLED',
  GET_TERMO = 'taxas/GET_TERMO',
  GET_TERMO_SUCCESS = 'taxas/GET_TERMO_SUCCESS',
  GET_TERMO_FAILED = 'taxas/GET_TERMO_FAILED',
  GET_TERMO_ERROR_HANDLED = 'taxas/GET_TERMO_ERROR_HANDLED',
  RESET = 'taxas/RESET',
}

const initialState: CooperativaTaxaState = {
  getCooperativaTaxas: {
    state: StateEnum.IDLE,
    error: null,
    taxas: [],
  },
  getConfiguracaoSaqueProgramado: {
    state: StateEnum.IDLE,
    error: null,
    data: null,
  },
  getTermo: {
    state: StateEnum.IDLE,
    error: null,
    data: null,
  }
}

export function reducer(
  state = initialState,
  action: Action
): CooperativaTaxaState {
  switch (action.type) {
    case CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS:
      return {
        ...state,
        getCooperativaTaxas: {
          ...state.getCooperativaTaxas,
          state: StateEnum.LOADING,
        },
      }
    case CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS_SUCCESS:
      return {
        ...state,
        getCooperativaTaxas: {
          ...state.getCooperativaTaxas,
          state: StateEnum.IDLE,
          taxas: action.payload.taxas,
        },
      }
    case CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS_FAILED:
      return {
        ...state,
        getCooperativaTaxas: {
          ...state.getCooperativaTaxas,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS_ERROR_HANDLED:
      return {
        ...state,
        getCooperativaTaxas: {
          ...state.getCooperativaTaxas,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO:
      return {
        ...state,
        getConfiguracaoSaqueProgramado: {
          ...state.getConfiguracaoSaqueProgramado,
          state: StateEnum.LOADING,
        },
      }
    case CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO_SUCCESS:
      return {
        ...state,
        getConfiguracaoSaqueProgramado: {
          ...state.getConfiguracaoSaqueProgramado,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO_FAILED:
      return {
        ...state,
        getConfiguracaoSaqueProgramado: {
          ...state.getConfiguracaoSaqueProgramado,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO_ERROR_HANDLED:
      return {
        ...state,
        getConfiguracaoSaqueProgramado: {
          ...state.getConfiguracaoSaqueProgramado,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case CooperativaTaxasTypes.GET_TERMO:
      return {
        ...state,
        getTermo: {
          ...state.getTermo,
          state: StateEnum.LOADING,
        },
      }
    case CooperativaTaxasTypes.GET_TERMO_SUCCESS:
      return {
        ...state,
        getTermo: {
          ...state.getTermo,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case CooperativaTaxasTypes.GET_TERMO_FAILED:
      return {
        ...state,
        getTermo: {
          ...state.getTermo,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case CooperativaTaxasTypes.GET_TERMO_ERROR_HANDLED:
      return {
        ...state,
        getTermo: {
          ...state.getTermo,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case CooperativaTaxasTypes.RESET:
      return initialState
    default:
      return state
  }
}

export class CooperativaTaxasActions {
  static getTaxas(query: QueryString) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS })

        const response = await axiosInstance.get(
          Endpoints.cooperativaTaxas.taxas(query)
        )
        const taxas: GetCooperativaTaxasSuccess = response.data.taxas;

        dispatch({
          type: CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS_SUCCESS,
          payload: { taxas },
        })

      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: CooperativaTaxasTypes.GET_COOPERATIVA_TAXAS_FAILED,
          payload: { error },
        })
      }
    }
  }

  static getConfiguracaoSaqueProgramado() {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO })

        const response = await axiosInstance.get(
          `${Endpoints.cooperativaTaxas.taxas()}/saqueProgramado`,
        )

        dispatch({
          type: CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO_SUCCESS,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: CooperativaTaxasTypes.GET_CONFIGURACAO_SAQUE_PROGRAMADO_FAILED,
          payload: { error },
        })
      }
    }
  }

  static getTermo() {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: CooperativaTaxasTypes.GET_TERMO })

        const response = await axiosInstance.get(
          `${Endpoints.cooperativaTaxas.taxas()}/termo`,
        )

        dispatch({
          type: CooperativaTaxasTypes.GET_TERMO_SUCCESS,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: CooperativaTaxasTypes.GET_TERMO_FAILED,
          payload: { error },
        })
      }
    }
  }
}
