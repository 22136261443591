import { AjudaRoutesPaths } from "pages/Ajuda/Ajuda";
import { LinksRoutesPaths } from "pages/Links";
import { TransacoesRoutesPaths } from "pages/Transacoes";
import { RecebimentosRoutesPaths } from "pages/Recebimentos";

export default class RoutesPaths {
  static dashboard = "/";
  static login = "/login";
  static links = LinksRoutesPaths;
  static pagamento = "/p";
  static transacoes = TransacoesRoutesPaths;
  static taxasmdr = "/taxas";
  static recebimentos = RecebimentosRoutesPaths;
  static ajuda = AjudaRoutesPaths;
  static relatorios = "/relatorios";
  static configuracoes = "/configuracoes";
  static novoLink = "/novo-link";
  static termos = "/termos-condicoes";
}
