import React, { useEffect } from "react";
import {
  Wrapper,
  WrapperContainer,
  HelpIcon,
  IconPayment,
  IconLink,
  IconMoney,
  IconDefault,
  IconError,
  IconSchedulePayment,
  IconAnticipation,
  IconReverse,
  IconSAC,
  CategoriaList,
  CategoriaListItem,
  CategoriaListItemIcon,
  CategoriaListItemText,
  ContentOverlay,
  CardCategorias,
  TagNovo,
} from "./styles";

import Header from "../Header";

import { CircularProgress } from "@material-ui/core";

import { ICategoriasQuestoesProps, ICategorias } from "./interfaces";
import { useRedirect, useTypedSelector } from "hooks";
import { useDispatch } from "react-redux";
import { AjudaActions, ICategoriasApi } from "store/ajuda";
import { AjudaRoutesPaths } from "../Ajuda";
import { hasError, isLoading } from "utils";
import { Alert } from "@material-ui/lab";
import { PageWrapper } from "shared/styles";
import { CurrencyExchange } from "components";
import { isAfter } from "date-fns";

const CategoriasQuestoes: React.FC<ICategoriasQuestoesProps> = () => {
  const categorias = useTypedSelector((state) => state.ajuda.categorias);
  const dispatch = useDispatch();
  const redirect = useRedirect();

  useEffect(() => {
    dispatch(AjudaActions.resetAjuda());
    dispatch(AjudaActions.getAjudaCategorias());
  }, [dispatch]);

  const categoriasDetalhes: ICategorias = React.useMemo(
    () => ({
      linkPagamento: {
        icon: <IconLink />,
        title: "Gerar link de pagamento",
      },
      pagamentos: {
        icon: <IconPayment />,
        title: "Pagamentos",
      },
      saquesRecebimentos: {
        icon: <IconMoney />,
        title: "Saques e recebimentos",
      },
      estorarPagamento: {
        icon: <IconReverse />,
        title: "Estornar pagamento",
      },
      saqueProgramado: {
        icon: <IconSchedulePayment />,
        title: "Saques Programado",
      },
      antecipacaoRecebiveis: {
        icon: <IconAnticipation />,
        title: "Antecipação de Recebíveis",
      },
      canaisAtendimento: {
        icon: <IconSAC />,
        title: "Canais de Atendimento",
      },
      chargeback: {
        icon: <CurrencyExchange />,
        title: "ChargeBack",
      },
      default: {
        icon: <IconDefault />,
        title: "",
      },
    }),
    []
  );

  const showNewTag = () => {
		const untilDate = new Date('2023/10/17');

		return !isAfter(new Date(), untilDate);
	}

  return (
    <PageWrapper id="wrapper_categoria_questoes">
      <WrapperContainer id="wrapper_container_categoria_questoes">
        <Header
          id="wrapper_container_header_categoria_questoes"
          title="Com o que podemos ajudar?"
          showIcon={true}
          Icon={<HelpIcon />}
        />
        <CardCategorias noPadding>
          <CategoriaList id="lista_categoria_questoes">
            {categorias.data.length > 0 ? (
              categorias.data.map((categoria: ICategoriasApi) => (
                <CategoriaListItem
                  id="lista_item_categoria_questoes"
                  button
                  key={categoria.id}
                  onClick={() =>
                    redirect(`${AjudaRoutesPaths.detalhes}/${categoria.id}`)
                  }
                >
                  <CategoriaListItemIcon id="lista_item_icon_categoria_questoes">
                    {categoriasDetalhes[categoria.type].icon}
                  </CategoriaListItemIcon>
                  <CategoriaListItemText
                    primary={
                      <>
                        {categoria.descricao}{" "}
                        {categoria.type === "chargeback" && showNewTag() && ( //Remover trecho dps de 17/10/2023, deixando apenas a linha 124 (de cima)
                          <TagNovo>Novo</TagNovo>
                        )}
                      </>
                    }
                    id="lista_item_text_categoria_questoes"
                  />
                </CategoriaListItem>
              ))
            ) : (
              <CategoriaListItem id="lista_item_categoria_questoes" dense>
                <CategoriaListItemIcon id="lista_item_icon_categoria_questoes">
                  <IconError />
                </CategoriaListItemIcon>
                <CategoriaListItemText
                  primary="Nenhum item encontrado."
                  id="lista_item_text_categoria_questoes"
                />
              </CategoriaListItem>
            )}
          </CategoriaList>
          {isLoading(categorias.state) && (
            <ContentOverlay>
              <CircularProgress />
            </ContentOverlay>
          )}
          {hasError(categorias.state) && (
            <ContentOverlay>
              <Alert severity="error">{categorias.error} </Alert>
            </ContentOverlay>
          )}
        </CardCategorias>
      </WrapperContainer>
    </PageWrapper>
  );
};

export default CategoriasQuestoes;
