import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AttachMoney from "@material-ui/icons/AttachMoney";
import { useTypedSelector } from "hooks";
import Header from "./Header";
import ResumoConta from "./ResumoConta";
import SaqueModal from "./SaqueModal";
import { RecebedorActions } from "store/recebedor";
import { PageWrapper } from "shared/styles";
import { ContentWrapperInfo } from "./styles";
import ExtratoRecebiveis from "./ExtratoRecebiveis";

const Dashboard = () => {
  const dispatch = useDispatch();
  const auth = useTypedSelector((state) => state.auth);
  const [openSaqueModal, setOpenSaqueModal] = useState(false);

  const openSaque = () => {
    setOpenSaqueModal(true);
  };

  const closeSaque = () => {
    setOpenSaqueModal(false);
  };

  useEffect(() => {
    if (auth.isRecebedor) {
      dispatch(RecebedorActions.getSaldo(auth.recebedor?.id || ""));
    }
  }, [auth.isRecebedor, auth.recebedor, dispatch]);

  return (
    <>
      <PageWrapper>
        <ContentWrapperInfo>
          <Header
            icon={<AttachMoney />}
            title="Saques"
            rightText="Saldo e saques são referentes a pagamentos feitos com Cartão de Crédito"
          />
          <ResumoConta openSaque={openSaque} />
          <ExtratoRecebiveis />
        </ContentWrapperInfo>
      </PageWrapper>
      {openSaqueModal && (
        <SaqueModal isOpen={openSaqueModal} onClose={closeSaque} />
      )}
    </>
  );
};

export default Dashboard;
