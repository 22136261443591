import styled from 'styled-components';
import { Colors } from 'theme';

export const TitleTable = styled.h3`
  background-color: ${Colors.primary};
  color: ${Colors.light};
  margin: 1rem 0 0 0;
  text-align: center;
  padding: 0.5rem;
  border-radius: 4px 4px 0 0 ;

`



