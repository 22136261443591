import React from "react";
import { EstornoMessages } from "shared/messages";
import { PaymentMethods } from "pages/Pagamento/interfaces";
import { Wrapper, Title, Paragraph, EntendiButton } from "./styles";
import Button from "components/Button";

interface SucessoProps {
  metodoId: string;
  onClose: any;
}

export default function Sucesso({ metodoId, onClose }: SucessoProps) {
  return (
    <Wrapper>
      <Title>
        {(metodoId === PaymentMethods.CARTAO || metodoId === "Cartão de Crédito") &&
          EstornoMessages.cancelamentoAgendadoTitulo}

        {metodoId.toLowerCase() === PaymentMethods.PIX &&
          EstornoMessages.cancelamentoSolicitadoTitulo}

        {metodoId === PaymentMethods.BOLETO &&
          EstornoMessages.cancelamentoRealizadoTitulo}
      </Title>
      <Paragraph>
        {(metodoId === PaymentMethods.CARTAO || metodoId === "Cartão de Crédito") &&
          EstornoMessages.cancelamentoAgendadoTexto}

        {metodoId.toLowerCase() === PaymentMethods.PIX &&
          EstornoMessages.cancelamentoSolicitadoTexto}

        {metodoId === PaymentMethods.BOLETO &&
          EstornoMessages.cancelamentoRealizadoTexto}
      </Paragraph>
        <EntendiButton
          variant="contained"
          onClick={() => onClose()}
        >
          Entendi
        </EntendiButton>
    </Wrapper>
  );
}
