import { StateEnum } from 'shared/enums'
import { Action } from 'store/interfaces'
import { Dispatch } from 'redux'
import querystring from 'querystring'

import { ErrorMessages } from 'shared/messages'
import { BodyUpdateLike, IAjudaState, QueryStringQuestoes } from './interfaces'
import { axiosInstance, Endpoints } from 'api'

export enum AjudaTypes {
  GET_CATEGORIAS = 'ajuda/GET_CATEGORIAS',
  GET_CATEGORIAS_SUCCESS = 'ajuda/GET_CATEGORIAS_SUCCESS',
  GET_CATEGORIAS_FAILED = 'ajuda/GET_CATEGORIAS_FAILED',
  GET_CATEGORIAS_ERROR_HANDLED = 'ajuda/GET_CATEGORIAS_ERROR_HANDLED',
  GET_QUESTOES = 'ajuda/GET_QUESTOES',
  GET_QUESTOES_SUCCESS = 'ajuda/GET_QUESTOES_SUCCESS',
  GET_QUESTOES_FAILED = 'ajuda/GET_QUESTOES_FAILED',
  GET_QUESTOES_ERROR_HANDLED = 'ajuda/GET_QUESTOES_ERROR_HANDLED',
  GET_QUESTAO = 'ajuda/GET_QUESTAO',
  GET_QUESTAO_SUCCESS = 'ajuda/GET_QUESTAO_SUCCESS',
  GET_QUESTAO_FAILED = 'ajuda/GET_QUESTAO_FAILED',
  GET_QUESTAO_ERROR_HANDLED = 'ajuda/GET_QUESTAO_ERROR_HANDLED',
  UPDATE_LIKE_QUESTOES = 'ajuda/UPDATE_LIKE_QUESTOES',
  UPDATE_LIKE_QUESTOES_SUCCESS = 'ajuda/UPDATE_LIKE_QUESTOES_SUCCESS',
  UPDATE_LIKE_QUESTOES_FAILED = 'ajuda/UPDATE_LIKE_QUESTOES_FAILED',
  UPDATE_LIKE_QUESTOES_ERROR_HANDLED = 'ajuda/UPDATE_LIKE_QUESTOES_ERROR_HANDLED',
  RESET = 'ajuda/RESET',
}

const initialState: IAjudaState = {
  categorias: {
    state: StateEnum.IDLE,
    error: null,
    data: [],
  },
  questoes: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      categoria: {
        id: '',
        descricao: '',
      },
      questoes: [],
    },
  },
  likes: {
    state: StateEnum.IDLE,
    error: null,
  },
  questao: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      id: '',
      idCategoria: '',
      descricaoCategoria: '',
      pergunta: '',
      resposta: '',
      order: 0,
    },
  },
}

export function reducer(state = initialState, action: Action): IAjudaState {
  switch (action.type) {
    case AjudaTypes.GET_CATEGORIAS:
      return {
        ...state,
        categorias: {
          ...state.categorias,
          state: StateEnum.LOADING,
        },
      }
    case AjudaTypes.GET_CATEGORIAS_SUCCESS:
      return {
        ...state,
        categorias: {
          ...state.categorias,
          state: StateEnum.COMPLETE,
          data: action.payload.categorias,
        },
      }
    case AjudaTypes.GET_CATEGORIAS_FAILED:
      return {
        ...state,
        categorias: {
          ...state.categorias,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case AjudaTypes.GET_CATEGORIAS_ERROR_HANDLED:
      return {
        ...state,
        categorias: {
          ...state.categorias,
          state: StateEnum.ERROR,
          error: null,
        },
      }
    case AjudaTypes.GET_QUESTOES:
      return {
        ...state,
        questoes: {
          ...state.questoes,
          state: StateEnum.LOADING,
        },
      }
    case AjudaTypes.GET_QUESTOES_SUCCESS:
      return {
        ...state,
        questoes: {
          ...state.questoes,
          state: StateEnum.COMPLETE,
          data: action.payload.questoes,
        },
      }
    case AjudaTypes.GET_QUESTOES_FAILED:
      return {
        ...state,
        questoes: {
          ...state.questoes,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case AjudaTypes.GET_QUESTOES_ERROR_HANDLED:
      return {
        ...state,
        questoes: {
          ...state.questoes,
          state: StateEnum.ERROR,
          error: null,
        },
      }
    case AjudaTypes.GET_QUESTAO:
      return {
        ...state,
        questao: {
          ...state.questao,
          state: StateEnum.LOADING,
        },
      }
    case AjudaTypes.GET_QUESTAO_SUCCESS:
      return {
        ...state,
        questao: {
          ...state.questao,
          state: StateEnum.COMPLETE,
          data: action.payload.questao,
        },
      }
    case AjudaTypes.GET_QUESTAO_FAILED:
      return {
        ...state,
        questao: {
          ...state.questao,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case AjudaTypes.GET_QUESTAO_ERROR_HANDLED:
      return {
        ...state,
        questao: {
          ...state.questao,
          state: StateEnum.ERROR,
          error: null,
        },
      }
    case AjudaTypes.UPDATE_LIKE_QUESTOES:
      return {
        ...state,
        likes: {
          ...state.likes,
          state: StateEnum.LOADING,
        },
      }
    case AjudaTypes.UPDATE_LIKE_QUESTOES_SUCCESS:
      return {
        ...state,
        likes: {
          ...state.likes,
          state: StateEnum.COMPLETE,
        },
      }
    case AjudaTypes.UPDATE_LIKE_QUESTOES_FAILED:
      return {
        ...state,
        likes: {
          ...state.likes,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case AjudaTypes.UPDATE_LIKE_QUESTOES_ERROR_HANDLED:
      return {
        ...state,
        likes: {
          ...state.likes,
          state: StateEnum.ERROR,
          error: null,
        },
      }
    case AjudaTypes.RESET:
      return initialState
    default:
      return state
  }
}

export class AjudaActions {
  static resetAjuda(): Action {
    return { type: AjudaTypes.RESET }
  }

  static getAjudaStart(): Action {
    return { type: AjudaTypes.GET_CATEGORIAS }
  }

  static getAjudaSuccess(data: any): Action {
    return {
      type: AjudaTypes.GET_CATEGORIAS_SUCCESS,
      payload: { categorias: data },
    }
  }
  static getAjudaFailed(error: string): Action {
    return {
      type: AjudaTypes.GET_CATEGORIAS_FAILED,
      payload: { error },
    }
  }
  static handleGetAjudaError(): Action {
    return { type: AjudaTypes.GET_CATEGORIAS_ERROR_HANDLED }
  }
  static getAjudaCategorias(): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(AjudaActions.getAjudaStart())
        const response = await axiosInstance.get(
          `${Endpoints.cooperativaTaxas.taxas()}/ajuda/categorias`
        )

        dispatch(this.getAjudaSuccess(response.data))
      } catch (err) {
        dispatch({
          type: AjudaTypes.GET_CATEGORIAS_FAILED,
          payload: { error: err.message || ErrorMessages.defaultError },
        })
      }
    }
  }

  static getQuestoesStart(): Action {
    return { type: AjudaTypes.GET_QUESTOES }
  }

  static getQuestoesSuccess(data: any): Action {
    return {
      type: AjudaTypes.GET_QUESTOES_SUCCESS,
      payload: { questoes: data },
    }
  }
  static getQuestoesFailed(error: string): Action {
    return {
      type: AjudaTypes.GET_QUESTOES_FAILED,
      payload: { error },
    }
  }
  static handleGetQuestoesError(): Action {
    return { type: AjudaTypes.GET_QUESTOES_ERROR_HANDLED }
  }

  static getAjudaQuestoes(query: QueryStringQuestoes): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(AjudaActions.getQuestoesStart())
        const response = await axiosInstance.get(
          `${Endpoints.cooperativaTaxas.taxas()}/ajuda/questoes?${querystring.stringify(
            query
          )}`
        )

        dispatch(this.getQuestoesSuccess(response.data))
      } catch (err) {
        dispatch({
          type: AjudaTypes.GET_QUESTOES_FAILED,
          payload: { error: err.message || ErrorMessages.defaultError },
        })
      }
    }
  }

  static getQuestaoStart(): Action {
    return { type: AjudaTypes.GET_QUESTAO }
  }

  static getQuestaoSuccess(data: any): Action {
    return {
      type: AjudaTypes.GET_QUESTAO_SUCCESS,
      payload: { questao: data },
    }
  }
  static getQuestaoFailed(error: string): Action {
    return {
      type: AjudaTypes.GET_QUESTAO_FAILED,
      payload: { error },
    }
  }
  static handleGetQuestaoError(): Action {
    return { type: AjudaTypes.GET_QUESTAO_ERROR_HANDLED }
  }

  static getQuestao(id: string): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(AjudaActions.getQuestaoStart())
        const response = await axiosInstance.get(
          `${Endpoints.cooperativaTaxas.taxas()}/ajuda/answer/${id}`
        )

        dispatch(this.getQuestaoSuccess(response.data))
      } catch (err) {
        dispatch({
          type: AjudaTypes.GET_QUESTAO_FAILED,
          payload: { error: err.message || ErrorMessages.defaultError },
        })
      }
    }
  }

  static updateLikeQuestoesStart(): Action {
    return { type: AjudaTypes.UPDATE_LIKE_QUESTOES }
  }

  static updateLikeQuestoesSuccess(data: any): Action {
    return {
      type: AjudaTypes.UPDATE_LIKE_QUESTOES_SUCCESS,
      payload: { questoes: data },
    }
  }
  static updateLikeQuestoesFailed(error: string): Action {
    return {
      type: AjudaTypes.UPDATE_LIKE_QUESTOES_FAILED,
      payload: { error },
    }
  }
  static handleUpdateLikeQuestoesError(): Action {
    return { type: AjudaTypes.UPDATE_LIKE_QUESTOES_ERROR_HANDLED }
  }

  static updateLikeQuestoes(body: BodyUpdateLike): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(AjudaActions.updateLikeQuestoesStart())
        const response = await axiosInstance.patch(
          `${Endpoints.cooperativaTaxas.taxas()}/ajuda/answer`,
          body
        )

        dispatch(this.updateLikeQuestoesSuccess(response.data))
      } catch (err) {
        dispatch({
          type: AjudaTypes.UPDATE_LIKE_QUESTOES_FAILED,
          payload: { error: err.message || ErrorMessages.defaultError },
        })
      }
    }
  }
}
