import styled from 'styled-components'

export const ParagrafosIniciais = styled.div`
  padding-bottom: 1rem;
`

export const Title = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 1.5rem 0 1rem 0;
`