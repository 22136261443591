import styled from 'styled-components'
import { Colors } from 'theme'

export const ContentWrapperInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
`

export const Subtitle = styled.h2`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${Colors.grey};
`
