import React, { useCallback, useEffect, useState, useMemo } from "react";
import { AttachMoney } from "@material-ui/icons";
import { PageHeader, TransacoesTable } from "components";
import { Container, Pagination } from "./styles";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "hooks";
import { ExtratoRecebiveisActions } from 'store/extratoRecebiveis';
import StringUtils from "utils/StringUtils";
import { isLoading } from "utils";
import { TablePagination } from "@material-ui/core";
import { ExtratoRecebiveis } from 'shared/messages';
import { Link } from "react-router-dom";

interface IOptions {
  page: number;
  size: number;
  sort: string | null;
  orderBy: string;
  orderDirection: 'asc' | 'desc' | undefined
}

const optionsStateInitial: IOptions = {
  page: 0,
  size: 5,
  sort: 'dataPagamentoPagarMe,asc',
  orderBy: 'dataPagamentoPagarMe',
  orderDirection: 'asc',
}

const SaldoAReceber: React.FC = () => {
  const [options, setOptions] = useState<IOptions>({...optionsStateInitial})

  const dispatch = useDispatch();
  const saldoAReceber = useTypedSelector((state) => state.extratoRecebiveis);
  const auth = useTypedSelector((state) => state.auth);

  const tableData = saldoAReceber.saldoAReceber.data.saldosFuturos.map((item, index: number) => ({
    id: index,
    ...item,
  }));

  const columns = useMemo(() => [
    'dataPagamentoPagarMe',
    'idTransacaoPagarMe',
    'nomeLink',
    'nomePagador',
    'dataHoraCompra',
    'valorTotal',
    'parcela',
    'valorParcela',
    'valorAntecipacao',
    'valorLiquido'
  ], [])

  const getSaldoAReceber = useCallback(() => {
    dispatch(ExtratoRecebiveisActions.getSaldoAReceber(auth.recebedor.id as string, options))
  }, [dispatch, options])

  const onChangePage = (page: number, size: number) => {
    setOptions((prev) => ({...prev, page, size}))
  }

  const onChangeOrder = (orderBy: number, orderDirection: string ) => {
    const columnName = columns[orderBy];
    if (columnName) {
      setOptions((prev) => ({
        ...prev,
        sort: `${columnName},${orderDirection}`,
        orderBy: columnName,
        orderDirection: orderDirection as  'asc'| 'desc' | undefined
      }))
    } else {
      setOptions((prev: any) => ({
        ...prev,
        sort: 'dataPagamentoPagarMe,asc',
        orderBy: 'dataPagamentoPagarMe',
        orderDirection: 'asc'
      }))
    }
  }

  const getOrderBy = (field: string) => {
    const direction: 'asc' | 'desc' | undefined = options.orderBy === field ? options.orderDirection : undefined;

    return direction;
  }

  useEffect(() => {
    getSaldoAReceber();
  }, [getSaldoAReceber])

  return (
    <Container>
      <PageHeader
        Icon={<AttachMoney />}
        showIcon={true}
        title={"Saldo a Receber"}
      />

      <TransacoesTable
        data={tableData}
        onRowClick={(_, rowData: any) => null}
        columns={[
          {
              title: "Data futura liberação",
              field: "dataLiberacaoSaldo",
              defaultSort: getOrderBy('dataPagamentoPagarMe'),
              customSort: () => 0
          },
          {
              title: "ID transação",
              field: "idTransacao",
              defaultSort: getOrderBy('idTransacaoPagarMe')
          },
          {
              title: "Nome do link",
              field: "linkPagamento.nomeLink",
              defaultSort: getOrderBy('nomeLink'),
              render: (rowData: any) => <Link to={`/links/detalhes/${rowData.linkPagamento?.idLink}`}>{rowData.linkPagamento?.nomeLink}</Link>
          },
          {
              title: "Nome do pagador",
              field: "nomePagador",
              defaultSort: getOrderBy('nomePagador')
          },
          {
              title: "Data da venda",
              field: "dataVenda",
              defaultSort: getOrderBy('dataHoraCompra'),
              customSort: () => 0
          },
          {
              title: "Valor bruto",
              field: "valorBruto",
              defaultSort: getOrderBy('valorTotal'),
              render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorBruto)}`
          },
          {
              title: "Parcela",
              field: "parcela",
              defaultSort: getOrderBy('parcela'),
              sorting: false,
          },
          {
              title: "Recebível por parcela",
              field: "valorParcela",
              defaultSort: getOrderBy('valorParcela'),
              render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorParcela)}`,
          },
          {
              title: "Valor antecipação",
              field: "valorAntecipacao",
              defaultSort: getOrderBy('valorAntecipacao'),
              render: (rowData: any) => `-`,
              sorting: false,
          },
          {
              title: "Valor líquido",
              field: "valorLiquido",
              defaultSort: getOrderBy('valorLiquido'),
              render: (rowData: any) => `R$ ${StringUtils.format.formatNumber(rowData.valorLiquido)}`,
              sorting: false,
          }
        ]}
        toolbar={false}
        page={options.page}
        onChangePage={(page, pageSize) => onChangePage(page, pageSize)}
        onChangeOrder={onChangeOrder}
        isLoading={isLoading(saldoAReceber.saldoAReceber.state)}
        totalElements={saldoAReceber.saldoAReceber?.data?.totalElements}
        components={{
          Pagination: (props: any) => (
            <Pagination>
              <p>{ExtratoRecebiveis.antecipacaoMessage}</p>
              <table>
                <tbody>
                <tr>
                  <TablePagination {...props}/>
                </tr>
                </tbody>
              </table>
            </Pagination>
          )
        }}
      />
    </Container>
  );
};

export default SaldoAReceber;
