import React from 'react'
import { Switch, Route } from 'react-router-dom'
import LinksPagamento from './LinksPagamento'
import DetalhesLink from './DetalhesLink'

export default function Links() {
  return (
    <>     
        <Switch>
          <Route
            path={LinksRoutesPaths.base}
            component={LinksPagamento}
            exact
          />
          <Route
            path={`${LinksRoutesPaths.detalhes}/:id`}
            component={DetalhesLink}
          />
        </Switch>
    </>
  )
}

export class LinksRoutesPaths {
  static base = '/links'
  static detalhes = `${LinksRoutesPaths.base}/detalhes`
}
