import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import theme from 'theme'

import App from 'pages/App'
import GlobalStyle from 'GlobalStyle'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { persistor, store } from 'store'
import { createInterceptors } from 'api/createInterceptors'
import KeycloakService from 'KeycloakService';

const render = () => ReactDOM.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <PersistGate loading={<h1>Carregando!</h1>} persistor={persistor}>
        <BrowserRouter>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </StylesProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById('root')
)

KeycloakService.initKeycloak(render);
serviceWorker.unregister()
createInterceptors()
