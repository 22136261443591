import styled from 'styled-components'
import { Chip } from '@material-ui/core'
import { Breakpoints } from 'theme'

export const Wrapper = styled.div`
  align-self: center;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    justify-content: flex-start;
    align-self: flex-start;
  }
`

export const FiltroChip = styled(Chip)`
  height: 26px;
  font-weight: bold;
  margin-left: 1rem;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    margin-left: 0rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
  }
`
