import styled from 'styled-components';
import { Colors, Breakpoints } from 'theme';
import { UnicredPayLogo } from 'shared/styles';

export const Logo = styled(UnicredPayLogo)`
    width: 100px;
    margin: 1rem 0 1rem 0;
    text-align: center;
`;

export const Wrapper = styled.div`
  background: ${Colors.lightBackground};
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  box-sizing: border-box;
  padding: 1rem 2rem; 

  @media (max-width: ${Breakpoints.maxSmall}) {  
    padding: 0 1rem 1rem 1rem;
  }    
`;

export const Container = styled.div`      
    max-width: ${Breakpoints.maxMedium}; 
    width: 100%; 
    height: 100%;
`;

