import { StateEnum } from 'shared/enums';
import { Action } from 'store/interfaces';
import { Dispatch } from 'redux';
import {  
  IGetLocalidadeSuccess,
  LocalidadeState,
} from './interfaces';
import { createErrorMessage } from 'utils';
import { axiosInstance, Endpoints } from 'api';

export enum LocalidadeTypes {
  GET_LOCALIDADE = 'usuario/GET_LOCALIDADE',
  GET_LOCALIDADE_SUCCESS = 'usuario/GET_LOCALIDADE_SUCCESS',
  GET_LOCALIDADE_FAILED = 'usuario/GET_LOCALIDADE_FAILED',
  GET_LOCALIDADE_ERROR_HANDLED = 'usuario/GET_LOCALIDADE_ERROR_HANDLED',
  RESET = 'localidade/RESET',
}

const initialState: LocalidadeState = {
  getLocalidade: {
    state: StateEnum.IDLE,
    error: null,
    localidade: null,
  },
};

export function localidadeReducer(
  state = initialState,
  action: Action,
): LocalidadeState {
  switch (action.type) {
    case LocalidadeTypes.GET_LOCALIDADE:
      return {
        ...state,
        getLocalidade: { ...state.getLocalidade, state: StateEnum.LOADING },
      };
    case LocalidadeTypes.GET_LOCALIDADE_SUCCESS:
      return {
        ...state,
        getLocalidade: {
          ...state.getLocalidade,
          state: StateEnum.IDLE,
          localidade: action.payload.localidade,
        },
      };
    case LocalidadeTypes.GET_LOCALIDADE_FAILED:
      return {
        ...state,
        getLocalidade: {
          ...state.getLocalidade,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case LocalidadeTypes.GET_LOCALIDADE_ERROR_HANDLED:
      return {
        ...state,
        getLocalidade: {
          ...state.getLocalidade,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    case LocalidadeTypes.RESET:
      return initialState;
    default:
      return state;
  }
}

export class LocalidadeActions {
  static getLocalidadeStarted(): Action {
    return { type: LocalidadeTypes.GET_LOCALIDADE };
  }

  static getLocalidadeSuccess(data: IGetLocalidadeSuccess): Action {
    return {
      type: LocalidadeTypes.GET_LOCALIDADE_SUCCESS,
      payload: { localidade: data },
    };
  }

  static getLocalidadeFailed(error: string): Action {
    return {
      type: LocalidadeTypes.GET_LOCALIDADE_FAILED,
      payload: { error },
    };
  }

  static handleGetLocalidadeError(): Action {
    return { type: LocalidadeTypes.GET_LOCALIDADE_ERROR_HANDLED };
  }

  static getLocalidade(cep: string) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(LocalidadeActions.getLocalidadeStarted());
       
        const { data } = await axiosInstance.get(`${Endpoints.localidade.localidade()}/${cep}`);
        
        dispatch(
          this.getLocalidadeSuccess({
            ...data,
          }),
        );
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(LocalidadeActions.getLocalidadeFailed(error));
      }
    };
  }  
}
