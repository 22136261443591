import styled from 'styled-components'
import { ListItem } from '@material-ui/core'
import { Breakpoints, Colors } from 'theme'

export const Wrapper = styled(ListItem)`
    margin-left: 0.5rem;
    margin-right: -0.5rem;
    border-radius: 5px;

    &.Mui-selected {
        background-color: ${Colors.lightBackground};

        .MuiTypography-root {        
            font-weight: 600; 
        }

        .MuiListItemIcon-root {
            color: ${Colors.unicredGoldish};
        }
    }  

    @media (max-width: ${Breakpoints.maxSmall}) {
        margin: 0;
    }
`; 
