import React from "react";
import { IconButton } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useRedirect } from "hooks";
import { Filtros } from "./Filtros";
import { RecebimentosRoutesPaths } from "pages/Recebimentos";
import {
  Wrapper,
  Title,
  TitleWrapper,
  AttachMoneyIcon,
  HeaderDivider,
  TitleContainer,
} from "./styles";

interface HeaderProps {
  setFiltro: (dia: number) => void;
  periodo: number;
}

const Header = ({ setFiltro, periodo }: HeaderProps) => {
  const redirect = useRedirect();

  return (
    <>
      <Wrapper>
        <TitleContainer>
          <IconButton
            onClick={() => {
              redirect(RecebimentosRoutesPaths.base);
            }}
          >
            <ChevronLeft />
          </IconButton>
          <TitleWrapper>
            <AttachMoneyIcon />{" "}
            <Title> Saldo a receber em até {periodo} dias </Title>
          </TitleWrapper>
        </TitleContainer>

        <Filtros
          setFiltro={setFiltro}
          periodoSelecionado={periodo}
          listaPeriodos={[15, 30, 60]}
        />
      </Wrapper>

      <HeaderDivider />
    </>
  );
};

export default Header;
