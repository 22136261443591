import { Action } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from "redux-persist";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { AuthTypes } from "store/auth/auth";
import { RecebedorState } from "store/recebedor/interfaces";
import { initialState } from "store/recebedor";
import { appReducer } from "store";
import { AppState } from "store/interfaces";
import Environment from "api/Environment";

const { version } = Environment.getConfigs();

export const rootReducer = (state: AppState | undefined, action: Action) =>
  action.type === AuthTypes.LOGOUT
    ? appReducer(undefined, action)
    : appReducer(state, action);

// Persistir só o objeto recebedor
const recebedorBlacklist = createTransform(
  (state: RecebedorState) => {
    const newState = { ...initialState, recebedor: { ...state.recebedor } };
    return newState;
  },
  null,
  { whitelist: ["recebedor"] }
);

const persistedReducer = persistReducer(
  {
    key: `plataforma-pagamentos-v${version}`,
    version: Number(version) ?? 1,
    whitelist: ["auth", "recebedor"],
    storage,
    transforms: [recebedorBlacklist],
  },
  rootReducer
);

export const setStore = (reducer: any, preloadedState = {}) =>
  configureStore({
    preloadedState,
    reducer,
    middleware: getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        // Ignorando as actions do redux-persist pois não são serializáveis
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });

export const store = setStore(persistedReducer);

export const persistor = persistStore(store);
