import styled from 'styled-components'
import {
  ErrorOutline,
  ReplyOutlined,
  ThumbDownAltRounded,
  ThumbUpAltRounded,
} from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

import { Breakpoints, Colors } from 'theme'
import { Card } from 'components'

interface IIconButtonContainer {
  isactive: number
}

export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1400px;
  opacity: 0;
  transform: translateX(-20px);
  animation: WrapperContainer 0.3s forwards;
  @media (max-width: ${Breakpoints.maxMedium}) {
    flex-direction: column;
  }

  @keyframes WrapperContainer {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`

export const CardPainel = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: true,
}))`
  flex-basis: 100%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
`

export const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
`

export const IconError = styled(ErrorOutline)``
export const IconBack = styled(ReplyOutlined)`
  color: ${Colors.unicredGoldish};
  padding-right: 4px;
  border-radius: 50px;

  :hover {
    cursor: pointer;
    background-color: ${Colors.lighterGrey};
    padding: 1px;
  }
`

export const ContainerResposta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  text-align: left;
  padding: 10px;
`

export const Resposta = styled.span`
  color: ${Colors.lightGrey};
`

export const ContainerLike = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  padding: 10px;
  position: relative;
`

export const ContainerItemLike = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
`

export const TituloLike = styled.span`
  padding: 2px;
  color: ${Colors.lightGrey};
`

export const ContainerButtonsLike = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 100px;
  max-width: 100px;
`
export const IconLike = styled(ThumbUpAltRounded)``
export const IconDeslike = styled(ThumbDownAltRounded)``
export const IconButtonContainerLike = styled(IconButton)<IIconButtonContainer>`
  :disabled {
    background-color: ${(props) =>
      props.isactive === 1 ? Colors.lighterGrey : 'transparent'};
    color: ${(props) =>
      props.isactive === 1 ? Colors.unicredGoldish : Colors.lightGrey};
  }
`

export const IconButtonContainerDeslike = styled(IconButton)<
  IIconButtonContainer
>`
  :disabled {
    background-color: ${(props) =>
      props.isactive === 1 ? Colors.lighterGrey : 'transparent'};
    color: ${(props) =>
      props.isactive === 1 ? Colors.unicredGoldish : Colors.lightGrey};
  }
`
