import Keycloak  from 'keycloak-js';
class KeycloakService {
    private _kc: any = {};

    constructor() {

        this._kc = Keycloak({
            url: process.env.REACT_APP_AUTH_URL, 
            realm: process.env.REACT_APP_AUTH_REALM as string, 
            clientId: process.env.REACT_APP_AUTH_CLIENTID as string
        });
    }

    async initKeycloak(callback: any) {
        await this._kc.init({checkLoginIframe: false, responseMode: 'query'})

        callback();
    }

    async login() {
        await this._kc.login();
    }

    isAuthenticated(): boolean {
        return this._kc.authenticated;
    }

    getToken(): string {
        return this._kc.token;
    }

    getUserAttributes(): any {
        return {
            cooperativa: this._kc.tokenParsed.attributes.cooperativa,
            agencia: this._kc.tokenParsed.attributes.agencia,
            usuario: this._kc.tokenParsed.attributes.usuario,
            conta: this._kc.tokenParsed.attributes.conta,
        }
    }

    async logout() {
        await this._kc.logout();
    }
}

export default new KeycloakService();