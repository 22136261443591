import React from 'react'

import { Switch, Route } from 'react-router-dom'
import TransacoesList from './TransacoesList'
import DetalhesTransacao from './DetalhesTransacao'

export default function Transacoes() {
  return (
    <>
      <Switch>
        <Route
          path={TransacoesRoutesPaths.base}
          component={TransacoesList}
          exact
        />
        <Route
          path={`${TransacoesRoutesPaths.detalhes}/:id`}
          component={DetalhesTransacao}
        />
      </Switch>
    </>
  )
}

export class TransacoesRoutesPaths {
  static base = '/movimentacao'
  static detalhes = `${TransacoesRoutesPaths.base}/detalhes`
}
