import React from "react";
import styled, { css } from "styled-components";
import { Menu, Button, ButtonProps, ListItemIcon } from "@material-ui/core";

interface UserButtonProps extends ButtonProps {
  active: boolean;
}

export const UserButton = styled(({ active, ...rest }: UserButtonProps) => (
  <Button {...rest} />
))`
  width: 13.8rem;
  color: #fff;

  & .MuiButton-startIcon {
    .MuiSvgIcon-root {
      font-size: 30px;
    }
  }

  ${(props) =>
    props.active &&
    css`
      border-radius: 4px 4px 0px 0px;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    `}
`;

export const UserMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 13.8rem;
    border-radius: 0px 0px 4px 4px;
  }
`;

export const UserName = styled.span`
  flex: 1;
  text-align: left;
`;

export const ListIcon = styled(ListItemIcon)`
  min-width: 40px;
`;
