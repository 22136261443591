import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Radio } from '@material-ui/core'
import { Replay } from '@material-ui/icons'
import { PagamentoActions } from 'store/pagamento/pagamento';
import { EstornoMessages } from 'shared/messages';
import { DadosEstorno } from 'components/ModalEstorno/interfaces'
import { useTypedSelector } from 'hooks';
import { isLoading } from 'utils/isLoading';
import { UnicredPayLogo } from 'shared/styles';
import { Message, MotivosWrapper, ConfirmacaoWrapper, Motivo, BotaoConfirmar, ContentOverlay } from './styles';

interface ContentProps {
  dadosEstorno: DadosEstorno
}

export default function Content({ dadosEstorno }: ContentProps) {
  const dispatch = useDispatch();
  const cancelamento = useTypedSelector(state => state.pagamento.efetuarCancelamento);
  const getMotivos = useTypedSelector(state => state.pagamento.getMotivosCancelamento);
  const [motivoId, setMotivoId] = useState('');

  const cancelar = () => {
    dispatch(PagamentoActions.efetuarCancelamento(dadosEstorno.pagamentoId, { motivoId: motivoId }));
  }

  useEffect(() => {
    dispatch(PagamentoActions.getMotivosCancelamento());
  }, [dispatch])

  return (
    <>
      <UnicredPayLogo />
      <Message>
        {EstornoMessages.motivoCancelamento}
      </Message>
      <MotivosWrapper
        aria-label="motivos-estorno"
        name="motivos-estorno"
        value={motivoId}
        onChange={(event) => setMotivoId(event?.target?.value)}
      >
        {getMotivos.data && getMotivos.data.map((motivo) => (
          <Motivo key={motivo.id} value={motivo.id} control={<Radio />} label={motivo.descricao} color={"primary"} />
        ))}
      </MotivosWrapper>
      <ConfirmacaoWrapper>
        {EstornoMessages.confirmacaoCancelamento}
      </ConfirmacaoWrapper>
      <BotaoConfirmar
        id="botao-confirmar-estorno"
        disabled={motivoId ? false : true}
        startIcon={<Replay />}
        onClick={() => cancelar()}
        loading={isLoading(cancelamento.state)}
        fullWidth
      >
        Confirmar
      </BotaoConfirmar>
      {isLoading(getMotivos.state) && (
        <ContentOverlay>
          <CircularProgress />
        </ContentOverlay>
      )}
    </>
  );
}
