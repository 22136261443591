import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ListItemText, MenuItem } from "@material-ui/core";
import {
  AccountCircle,
  ExpandLess,
  ExpandMore,
  ExitToApp,
  Tune,
  DescriptionOutlined,
} from "@material-ui/icons";
import { useRedirect, useTypedSelector } from "hooks";
import { RoutesPaths } from "routes";
import { AuthActions } from "store/auth/auth";
import { UserButton, UserMenu, UserName, ListIcon } from "./styles";
import KeycloakService from "KeycloakService";

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const user = useTypedSelector((state) => state.auth.user);
  const exit = useTypedSelector((state) => state.ui.exit);
  const { toogle_rav: toggleRav } = useTypedSelector((state) => state.config);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const exitIsVisible = exit.isVisible;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    closeMenu();
  };

  const handleRedirect = (to: string) => {
    closeMenu();
    redirect(to);
  };

  return (
    <>
      <UserButton
        active={open}
        onClick={(event) => handleClick(event)}
        startIcon={<AccountCircle fontSize="large" />}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        <UserName> {user?.nome} </UserName>
      </UserButton>
      <UserMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toggleRav && (
          <MenuItem onClick={() => handleRedirect(RoutesPaths.configuracoes)}>
            <ListIcon>
              <Tune fontSize="small" />
            </ListIcon>
            <ListItemText primary="Configurações" />
          </MenuItem>
        )}
        <MenuItem onClick={() => handleRedirect(RoutesPaths.termos)}>
          <ListIcon>
            <DescriptionOutlined fontSize="small" />
          </ListIcon>
          <ListItemText primary="Termos e Condições" />
        </MenuItem>
        {exitIsVisible && (
          <MenuItem onClick={() => dispatch(AuthActions.logout())}>
            <ListIcon>
              <ExitToApp fontSize="small" />
            </ListIcon>
            <ListItemText primary="Sair" />
          </MenuItem>
        )}
      </UserMenu>
    </>
  );
};

export default ProfileMenu;
