import React, { useEffect, useState, memo } from "react";
import { Button, Modal } from "components";
import { useMediaQuery } from "@material-ui/core";
import { differenceInHours } from "date-fns";
import { Container, Info, Title } from "./styles";
import theme from "theme";
import { useLocalForage } from "hooks/useLocalForage";

export function LembreteInformativo() {
  const [aparecerModal, setAparecerModal] = useState(false);
	const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const [lembreteInformativo, setLembreteInformativo, removerLembrete, carregando] = useLocalForage('lembretePortadorCartao', {ultimaVisualizacao: '', visualizacoes: 0});

  useEffect(() => {
    if (carregando || (lembreteInformativo && lembreteInformativo.visualizacoes >= 2)) return;

    const dataUltimaVisualizacao = new Date(lembreteInformativo?.ultimaVisualizacao as string);

    if (
      lembreteInformativo?.ultimaVisualizacao !== "" ||
      (typeof dataUltimaVisualizacao === "number" && 
      dataUltimaVisualizacao !== Number.isNaN(dataUltimaVisualizacao))
    ) {
      const agora = new Date();
      const diff = differenceInHours(agora, dataUltimaVisualizacao);

      if (diff >= 24) {
        setAparecerModal(true);
      }

    } else {
      setAparecerModal(true);
    }
    
  }, [carregando]);

  function fecharModal() {
    setAparecerModal(false);

    if (lembreteInformativo) {
      setLembreteInformativo({ ultimaVisualizacao: (new Date()).toString(), visualizacoes: lembreteInformativo.visualizacoes + 1 });
    }
  }

  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      onClose={fecharModal}
      open={aparecerModal}
      noPadding
			fullScreen={mediaQuery}
    >
      <Container>
        <Info>I</Info>
        <Title>Dicas para evitar chargeback.</Title>
        <p>
					Para garantir a segurança da transação, consulte o campo "Portador do Cartão" e verifique se o seu cliente é o portador 
					do cartão de crédito. Caso não seja, recomendamos que solicite um documento com foto ao portador.
				</p>

        <Button data-testeid="fechar-modal" onClick={fecharModal}>OK</Button>
      </Container>
    </Modal>
  );
}

const Lembrete  = memo(LembreteInformativo);

export default Lembrete;
