import React, { useState } from 'react';
import { TransacoesRoutesPaths } from '../Transacoes';
import Header from "../Header";
import { isLoading } from 'utils'
import MainContent from './MainContent';
import { CircularProgress } from '@material-ui/core'
import { Wrapper, ContentLoading, MenuEstaticoMobile } from './styles'
import { useMount, useRedirect, useTypedSelector } from 'hooks'
import { ContentWrapperInfo, DetalhesButton } from './styles';
import { useParams } from 'react-router-dom';
import { ParamPagamentoRecebedor } from 'store/pagamento/interfaces';
import { IconBack } from 'pages/Ajuda/Questoes/Lista/styles';
import { Button } from 'components';
import { PagamentoActions } from 'store/pagamento/pagamento';
import { useDispatch } from 'react-redux';
import ModalEstorno from 'components/ModalEstorno';
import { DadosEstorno } from 'components/ModalEstorno/interfaces';

export default function DetalhesTransacao() {

  const params: ParamPagamentoRecebedor = useParams();
  const detalhePagamento = useTypedSelector((state) => state.pagamento.detalhePagamento);

  const redirect = useRedirect();
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(PagamentoActions.getDetalhePagamento(params.id))
  })

  const dadosEstornoInitial: DadosEstorno = {
    pagamentoId: "",
    metodoId: "",
  };

  const [openCancelamento, setOpenCancelamento] = useState(false);
  const [dadosEstorno, setDadosEstorno] = useState<DadosEstorno>({
    ...dadosEstornoInitial,
  });

  const onCloseCancelamento = () => {
    setOpenCancelamento(false);
    setDadosEstorno({ ...dadosEstornoInitial });
    dispatch(PagamentoActions.getDetalhePagamento(params.id))
  };

  return (
    <>
      {isLoading(detalhePagamento.state) ? (
        <ContentLoading>
          <CircularProgress />
        </ContentLoading>
      ) : (
        <>
          <Wrapper>
            <ContentWrapperInfo>
              <Header showIcon={true}
                Icon={<IconBack onClick={() => redirect(`${TransacoesRoutesPaths.base}`)} />}
                title={"Detalhes"} />
            </ContentWrapperInfo>
          </Wrapper>
          <Wrapper>
            <MainContent idPagamento={params.id} pagamento={detalhePagamento.data} />
            <MenuEstaticoMobile container justify='flex-end' style={{ marginTop: "16px" }}>
              <Button
                variant="outlined"
                onClick={() => redirect(`${TransacoesRoutesPaths.base}`)}
              >
                Voltar
              </Button>
              {detalhePagamento.data?.modalidade !== "Boleto" && (
                <DetalhesButton
                  onClick={() => {
                    setOpenCancelamento(true)
                    setDadosEstorno({ pagamentoId: params.id, metodoId: detalhePagamento.data ? detalhePagamento.data.modalidade : ""})
                  }}
                  disabled={detalhePagamento.data?.status !== 'Pago'}
                >
                  {detalhePagamento.data?.modalidade === "PIX" ? "Devolver Pix" : "Estornar Pagamento"}
                </DetalhesButton>)
              }
            </MenuEstaticoMobile>
            {openCancelamento && (
              <ModalEstorno
                open={openCancelamento}
                dadosEstorno={dadosEstorno}
                onClose={onCloseCancelamento}
              />
            )}
          </Wrapper>
        </>
      )}
    </>
  )
}
