import styled from 'styled-components';
import { Colors, Breakpoints } from 'theme';

interface ModalTitleProps {
  center?: boolean;
}

export default styled.h1<ModalTitleProps>`
  margin: 1rem 0;
  color: ${Colors.primary};
  text-align: ${props => (props.center ? 'center' : 'unset')};

  @media (max-width: ${Breakpoints.maxMedium}) {
    text-align: center;
  }
`;
