export enum OperationModalLink {
  DELETE = 'DELETE',
  SUCESS = 'SUCESS',
  INITIAL_DATA = 'INITIAL_DATA',
}

export interface PropsModal {
  isOpen: boolean
  onClose: Function
  data?: any
}

export interface DeleteModal {
  title: string
  question: string
  onClose: Function
  onConfirm: Function
  idLink: string
}

export interface ModalState {
  isOpen: boolean
  data?: any
}

export interface SucessModal {
  onClose: Function
  title: string
  message: string
}
