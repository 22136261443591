import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch, RouteProps } from "react-router-dom";
import Loader from "components/CenteredLoader";
import RoutesPaths from "./RoutesPaths";
import PrivateRoutes from "./PrivateRoutes";

const Login = lazy(() => import("pages/Login"));
const Pagamento = lazy(() => import("pages/Pagamento"));
const ConfirmacaoSolicitacaoPixStep = lazy(
  () => import("pages/ReciboSolicitacaoPix")
);

interface RoutesProps {
  isAuth: boolean;
  isRecebedor: boolean;
}

interface RouteWrapperProps extends RouteProps {
  onlyNotAuth?: boolean;
  isAuth: boolean;
}

function RouteWrapper(props: RouteWrapperProps) {
  const { isAuth, onlyNotAuth, ...rest } = props;

  if (onlyNotAuth && isAuth) return <Redirect to={RoutesPaths.dashboard} />;

  return <Route {...rest} />;
}

export default function Routes(props: RoutesProps) {
  const { isAuth } = props;

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <RouteWrapper
          path={RoutesPaths.login}
          component={Login}
          isAuth={isAuth}
          onlyNotAuth
        />

        <RouteWrapper
          path={`${RoutesPaths.pagamento}/confirmacao`}
          component={ConfirmacaoSolicitacaoPixStep}
          isAuth={isAuth}
        />

        <RouteWrapper
          path={`${RoutesPaths.pagamento}/:id`}
          component={Pagamento}
          isAuth={isAuth}
        />
        
        <PrivateRoutes isAuth={isAuth}/>
        
      </Switch>
    </Suspense>
  );
}
