import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';

import {
  Slide,
  Dialog,
  DialogProps,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { IconButton, DialogContent, DialogTitle } from './styles';
import { Close } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface ModalProps extends DialogProps {
  disableCloseIcon?: boolean;
  onClose: any;
  noPadding?: boolean;
  closeIconColor?: 'inherit' | 'primary' | 'secondary' | 'default';
}

export default function Modal(props: ModalProps) {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    open,
    onClose,
    disableBackdropClick,
    disableEscapeKeyDown,
    children,
    fullScreen = mediaQuery,
    fullWidth = true,
    scroll = 'body',
    disableCloseIcon = false,
    noPadding = false,
    closeIconColor = 'inherit',
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      scroll={scroll}
      {...rest}
    >
      {rest.title && <DialogTitle>{rest.title}</DialogTitle>}
      {!disableCloseIcon && (
        <IconButton onClick={onClose} color={closeIconColor}>
          <Close />
        </IconButton>
      )}
      <DialogContent noPadding={noPadding}>{children}</DialogContent>
    </Dialog>
  );
}
