import { Action } from 'store/interfaces'
import querystring from 'querystring'
import {
  LinkPagamentoState,
  ParamsLink,
  ParamsTotaisLink,
  QueryStringLink,
  QueryStringTotaisLink,
} from 'store/linkPagamento/interfaces'
import { StateEnum } from 'shared/enums'
import { Dispatch } from 'redux'
import { axiosInstance, Endpoints } from 'api'
import { RoutesPaths } from 'routes'
import StringUtils from 'utils/StringUtils'
import { createErrorMessage } from 'utils'

export enum LinkPagamentoTypes {
  NOVO_LINK = 'linkPagamento/NOVO_LINK',
  NOVO_LINK_SUCCESS = 'linkPagamento/NOVO_LINK_SUCCESS',
  NOVO_LINK_FAILED = 'linkPagamento/NOVO_LINK_FAILED',
  NOVO_LINK_ERROR_HANDLED = 'linkPagamento/NOVO_LINK_ERROR_HANDLED',
  RESET_NOVO_LINK = 'linkPagamento/RESET_NOVO_LINK',
  GET_ALL_LINK_PAGAMENTO = 'linkPagmaneto/GET_ALL_LINK_PAGAMENTO',
  GET_ALL_LINK_PAGAMENTO_SUCCES = 'linkPagmaneto/GET_ALL_LINK_PAGAMENTO_SUCCES',
  GET_ALL_LINK_PAGAMENTO_FAILED = 'linkPagmaneto/GET_ALL_LINK_PAGAMENTO_FAILED',
  GET_ALL_LINK_PAGAMENTO_ERROR_HANDLED = 'linkPagmaneto/GET_ALL_LINK_PAGAMENTO_ERROR_HANDLED',
  GET_ALL_LINK_PAGAMENTO_RESET = 'linkPagmaneto/GET_ALL_LINK_PAGAMENTO_RESET',
  DELETE_LINK_PAGAMENTO = 'linkPagmaneto/DELETE_LINK_PAGAMENTO',
  DELETE_LINK_PAGAMENTO_SUCCES = 'linkPagmaneto/DELETE_LINK_PAGAMENTO_SUCCES',
  DELETE_LINK_PAGAMENTO_FAILED = 'linkPagmaneto/DELETE_LINK_PAGAMENTO_FAILED',
  DELETE_LINK_PAGAMENTO_ERROR_HANDLED = 'linkPagmaneto/DELETE_LINK_PAGAMENTO_ERROR_HANDLED',
  DELETE_LINK_PAGAMENTO_RESET = 'linkPagmaneto/DELETE_LINK_PAGAMENTO_RESET',
  GET_ONE_LINK_PAGAMENTO = 'linkPagmaneto/GET_ONE_LINK_PAGAMENTO',
  GET_ONE_LINK_PAGAMENTO_SUCCES = 'linkPagmaneto/GET_ONE_LINK_PAGAMENTO_SUCCES',
  GET_ONE_LINK_PAGAMENTO_FAILED = 'linkPagmaneto/GET_ONE_LINK_PAGAMENTO_FAILED',
  GET_ONE_LINK_PAGAMENTO_ERROR_HANDLED = 'linkPagmaneto/GET_ONE_LINK_PAGAMENTO_ERROR_HANDLED',
  GET_ONE_LINK_PAGAMENTO_RESET = 'linkPagmaneto/GET_ONE_LINK_PAGAMENTO_RESET',
  GET_TOTAIS_LINK_RECEBEDOR = 'linkPagmaneto/GET_TOTAIS_LINK_RECEBEDOR',
  GET_TOTAIS_LINK_RECEBEDOR_SUCCES = 'linkPagmaneto/GET_TOTAIS_LINK_RECEBEDOR_SUCCES',
  GET_TOTAIS_LINK_RECEBEDOR_FAILED = 'linkPagmaneto/GET_TOTAIS_LINK_RECEBEDOR_FAILED',
  GET_TOTAIS_LINK_RECEBEDOR_ERROR_HANDLED = 'linkPagmaneto/GET_TOTAIS_LINK_RECEBEDOR_ERROR_HANDLED',
  GET_TOTAIS_LINK_RECEBEDOR_RESET = 'linkPagmaneto/GET_TOTAIS_LINK_RECEBEDOR_RESET',
  DETALHES_LINK_EDIT_VALIDADE_STARTED = 'linkPagamento/DETALHES_LINK_EDIT_VALIDADE_STARTED',
  DETALHES_LINK_EDIT_VALIDADE_COMPLETE = 'linkPagamento/DETALHES_LINK_EDIT_VALIDADE_COMPLETE',
  DETALHES_LINK_EDIT_VALIDADE_FAILED = 'linkPagamento/DETALHES_LINK_EDIT_VALIDADE_FAILED',
  DETALHES_LINK_EDIT_VALIDADE_MODAL_HANDLED = "linkPagamento/DETALHES_LINK_EDIT_VALIDADE_MODAL_HANDLED"
}

const initialState: LinkPagamentoState = { 
  novoLink: {
    state: StateEnum.IDLE,
    address: '',
    error: null,
    data: {},
  },
  links: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      links: [],
      totalElements: 0,
    },
  },
  link: {
    state: StateEnum.IDLE,
    error: null,
    data: {},
    editState: StateEnum.IDLE,
  },
  delete: {
    state: StateEnum.IDLE,
    error: null,
    data: {},
  },
  totaisRecebedor: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      linksPagos: 0,
      pagamentosRecusados: 0,
      parcelamentoMedio: 0,
      totalLinksGerado: 0,
      valorMedioLinksPago: 0,
      valorTotalLinksPago: 0,
      percentuaisModalidade: {
        percentualBoleto: 0,
        percentualPix: 0,
        percentualCartao: 0
      },
      ultimasMovimentacoes: [],
    },
  },
}

export function reducer(
  state = initialState,
  action: Action
): LinkPagamentoState {
  switch (action.type) {    
    case LinkPagamentoTypes.NOVO_LINK:
      return {
        ...state,
        novoLink: {
          ...state.novoLink,
          state: StateEnum.LOADING,
        },
      }
    case LinkPagamentoTypes.NOVO_LINK_SUCCESS:
      return {
        ...state,
        novoLink: {
          ...state.novoLink,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
          address: action.payload.address,
        },
      }
    case LinkPagamentoTypes.NOVO_LINK_FAILED:
      return {
        ...state,
        novoLink: {
          ...state.novoLink,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case LinkPagamentoTypes.NOVO_LINK_ERROR_HANDLED:
      return {
        ...state,
        novoLink: {
          ...state.novoLink,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case LinkPagamentoTypes.RESET_NOVO_LINK:
      return {
        ...state,
        novoLink: initialState.novoLink,
      }
    case LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO:
      return {
        ...state,
        links: {
          ...state.links,
          state: StateEnum.LOADING,
        },
      }
    case LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_SUCCES:
      return {
        ...state,
        links: {
          ...state.links,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_FAILED:
      return {
        ...state,
        links: {
          ...state.links,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_ERROR_HANDLED:
      return {
        ...state,
        links: {
          ...state.links,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_RESET:
      return {
        ...state,
        links: initialState.links,
      }
    case LinkPagamentoTypes.DELETE_LINK_PAGAMENTO:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: StateEnum.LOADING,
        },
      }
    case LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_SUCCES:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_ERROR_HANDLED:
      return {
        ...state,
        delete: {
          ...state.delete,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_RESET:
      return {
        ...state,
        delete: initialState.delete,
      }
    case LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO:
      return {
        ...state,
        link: {
          ...state.link,
          state: StateEnum.LOADING,
        },
      }
    case LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO_SUCCES:
      return {
        ...state,
        link: {
          ...state.link,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO_FAILED:
      return {
        ...state,
        link: {
          ...state.link,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO_ERROR_HANDLED:
      return {
        ...state,
        link: {
          ...state.link,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO_RESET:
      return {
        ...state,
        link: initialState.link,
      }
    case LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR:
      return {
        ...state,
        totaisRecebedor: {
          ...state.totaisRecebedor,
          state: StateEnum.LOADING,
        },
      }
    case LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR_SUCCES:
      return {
        ...state,
        totaisRecebedor: {
          ...state.totaisRecebedor,
          state: StateEnum.COMPLETE,
          data: action.payload.data,
        },
      }
    case LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR_FAILED:
      return {
        ...state,
        totaisRecebedor: {
          ...state.totaisRecebedor,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      }
    case LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR_ERROR_HANDLED:
      return {
        ...state,
        totaisRecebedor: {
          ...state.totaisRecebedor,
          state: StateEnum.IDLE,
          error: null,
        },
      }
    case LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR_RESET:
      return {
        ...state,
        totaisRecebedor: initialState.totaisRecebedor,
      }
    case LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_STARTED: 
      return {
        ...state,
        link: {
          ...state.link,
          editState: StateEnum.LOADING
        }
      }
    case LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_COMPLETE:
      return {
        ...state,
        link: {
          ...state.link,
          editState: StateEnum.COMPLETE,
          data: action.payload
        }
      }
    case LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_FAILED:
      return {
        ...state,
        link: {
          ...state.link,
          editState: StateEnum.ERROR,
          error: action.payload
        }
      }
    case LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_MODAL_HANDLED:
      return {
        ...state,
        link: {
          ...state.link,
          editState: StateEnum.IDLE,
          error: null
        }
      }
    default:
      return state
  }
}

export class LinkPagamentoActions {
  static novoLinkPagamento(data: any): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: LinkPagamentoTypes.NOVO_LINK })

        const response = await axiosInstance.post(Endpoints.link.links(), {      
          ...data,     
          itens: data.itens.map((dt: any) => ({
            valor: parseFloat(dt.valor.replace(',', '.')),
            descricao: dt.descricao,
            quantidade: dt.quantidade,
            valorAcumulado: parseFloat(dt.valor.replace(',', '.')) * dt.quantidade,
          })),        
          formaPagamento: {            
            ...(data.formaPagamento.pix.enabled ? {
              pix: {
                ...data.formaPagamento.pix,
                chave: data.formaPagamento.pix.chave.value
              }
            } : {}),
            ...(data.formaPagamento.cartao.enabled ? {
              cartao: {
                ...data.formaPagamento.cartao,
                quantidadeMaximaParcelas: data.formaPagamento.cartao.quantidadeMaximaParcelas.value
              }
            } : {}),
            ...(data.formaPagamento.boleto.enabled ? {
              boleto: {}
            } : {}),
          }          
        })

        dispatch({
          type: LinkPagamentoTypes.NOVO_LINK_SUCCESS,
          payload: {
            data: response.data,
            address: `${window.location.protocol}//${window.location.host}/p/${response.data.id}`,
          },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: LinkPagamentoTypes.NOVO_LINK_FAILED,
          payload: { error },
        })
      }
    }
  }

  static getAllLinks(query: QueryStringLink): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO })

        const response = await axiosInstance.get(Endpoints.link.links(query))
        response.data.links.forEach((el: any) => {
          el.link = `${window.location.protocol}//${window.location.host}${RoutesPaths.pagamento}/${el.id}`
          el.total = StringUtils.format.formatBRL(el.total)
        })

        dispatch({
          type: LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_SUCCES,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_FAILED,
          payload: { error },
        })
      }
    }
  }

  static getOneLink(param: ParamsLink): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO })

        const response = await axiosInstance.get(
          `${Endpoints.link.links()}/${param.id}`
        )
        response.data.link = `${window.location.protocol}//${window.location.host}${RoutesPaths.pagamento}/${response.data.id}`
        dispatch({
          type: LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO_SUCCES,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: LinkPagamentoTypes.GET_ONE_LINK_PAGAMENTO_FAILED,
          payload: { error },
        })
      }
    }
  }

  static deleteLink(params: ParamsLink): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: LinkPagamentoTypes.DELETE_LINK_PAGAMENTO })

        const response = await axiosInstance.delete(
          `${Endpoints.link.links()}/${params.id}`
        )

        dispatch({
          type: LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_SUCCES,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_FAILED,
          payload: { error },
        })
      }
    }
  }

  static getTotaisLinkRecebedor(
    param: ParamsTotaisLink,
    query: QueryStringTotaisLink
  ): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({ type: LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR })

        const response = await axiosInstance.get(
          `${Endpoints.link.links()}/recebedor/${param.idRecebedor
          }/totais?${querystring.stringify(query)}`,
          {
            'axios-retry': {
              retries: 4,
            },
          }
        )

        dispatch({
          type: LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR_SUCCES,
          payload: { data: response.data },
        })
      } catch (err) {
        const error = createErrorMessage(err)
        dispatch({
          type: LinkPagamentoTypes.GET_TOTAIS_LINK_RECEBEDOR_FAILED,
          payload: { error },
        })
      }
    }
  }

  static resetDeleteLink(): Action {
    return { type: LinkPagamentoTypes.DELETE_LINK_PAGAMENTO_RESET }
  }

  static resetNovoLink(): Action {
    return { type: LinkPagamentoTypes.RESET_NOVO_LINK }
  }

  static resetAllLink(): Action {
    return { type: LinkPagamentoTypes.GET_ALL_LINK_PAGAMENTO_RESET }
  }

  static editarDataValidadeLink(linkId: string, novaValidade: number|string): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({type: LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_STARTED});

        const response = await axiosInstance.post(
          `${Endpoints.link.links()}/${linkId}`, 
          {validade: novaValidade.toString()}
        );

        dispatch({
          type: LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_COMPLETE,
          payload: response.data
        })

      }
      catch(error) {
        const errorMessage = createErrorMessage(error)
        dispatch({
          type: LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_FAILED,
          payload: {error: errorMessage}
        })
      }
    }
  }

  static closeModalValidade(): Action {
    return { type: LinkPagamentoTypes.DETALHES_LINK_EDIT_VALIDADE_MODAL_HANDLED }
  }
}
