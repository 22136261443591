export const muiTableLocalization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'Itens',
    previousTooltip: 'Página anterior',
    previousAriaLabel: 'Página anterior',
    nextTooltip: 'Página seguinte',
    nextAriaLabel: 'Página seguinte',
    firstTooltip: 'Primeira página',
    firstAriaLabel: 'Primeira página',
    lastTooltip: 'Última página',
    lastAriaLabel: 'Última página',
  },
  toolbar: {
    nRowsSelected: '{0} iten(s) selecionados',
    searchTooltip: 'Procurar',
    searchPlaceholder: 'Procurar',
  },
  header: {
    actions: 'Ações',
  },
  body: {
    emptyDataSourceMessage: 'Sem dados a serem exibidos',
    filterRow: {
      filterTooltip: 'Filtrar',
    },
  },
};

export const dateFormat = 'dd/MM/yyyy';
export const hourFormat = 'HH:mm';
export const whatsAppUrl = 'https://wa.me/?text=';
export const prazoMaximoCancelamento = 350;

export const transactionsType = {
  CARD: "Cartão de Crédito",
};

export const transactionsStatus = {
  REFUSED: "Recusado"
}