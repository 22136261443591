import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import copy from "copy-text-to-clipboard";
import { WhatsApp, Link, Delete } from "@material-ui/icons";
import { LinkPagamentoActions } from "store/linkPagamento";
import {
  whatsAppUrl,
} from "shared/constants";
import { useRedirect, useTypedSelector } from "hooks";
import { hasError, isLoading } from "utils";
import { LinksRoutesPaths } from "pages/Links/Links";
import { ModalState, OperationModalLink } from "../ModalLink/Interfaces";
import ModalLink from "../ModalLink";
import Header from "./Header";
import { Wrapper, ContentWrapperInfo } from "./styles";
import { TransacoesTable } from "components";

interface IOptions {
  page: number;
  size: number;
  sort: string | null;
  orderBy: string;
  orderDirection: "asc" | "desc" | undefined;
}

const optionsStateInitial: IOptions = {
  page: 0,
  size: 5,
  sort: "createdDate,desc",
  orderBy: "createdDate",
  orderDirection: "desc",
};
const ModalLinkInitialState: ModalState = {
  isOpen: false,
  data: {},
};

const LABEL_STATUS = {
  true: 'Ativo',
  false: 'Inativo'
};

export default function LinksPagamento() {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const stateLink = useTypedSelector((state) => state.linkPagamento);
  const [options, setOptions] = useState<IOptions>(optionsStateInitial);
  const [dataModalLink, setDataModalLink] = useState<ModalState>(
    ModalLinkInitialState
  );
  const tableData = stateLink.links?.data?.links?.map((item: any) => {
    return { ...item };
  });

  useEffect(() => {
    dispatch(
      LinkPagamentoActions.getAllLinks({
        sort: options.sort,
        page: options.page,
        size: options.size,
      })
    );
  }, [dispatch, options]);

  useEffect(() => {
    if (hasError(stateLink.links.state)) {
      dispatch(LinkPagamentoActions.resetAllLink());
    }
  }, [stateLink.links.state, dispatch]);

  const columns = useMemo(
    () => [
      "nome",
      "labelStatus",
      "quantidadePagamentos",
      "dataCriacao",
      "total",
    ],
    []
  );

  const openModal = (data: any) => {
    setDataModalLink((prev) => ({
      ...prev,
      isOpen: !dataModalLink.isOpen,
      data,
    }));
  };

  const onChangeOrder = (orderBy: number, orderDirection: string) => {
    const columnName = columns[orderBy];
    if (columnName) {
      const helpName =
        columnName === "dataCriacao" ? "createdDate" : columnName;
      setOptions((prev: any) => ({
        ...prev,
        sort: `${helpName}, ${orderDirection}`,
        orderBy: helpName,
        orderDirection,
      }));
    } else {
      setOptions((prev: any) => ({
        ...prev,
        sort: "",
        orderBy: "",
        orderDirection: undefined,
      }));
    }
  };

  const closeModal = () => {
    setDataModalLink(ModalLinkInitialState);
    dispatch(LinkPagamentoActions.resetDeleteLink());
    dispatch(LinkPagamentoActions.getAllLinks(options));
  };

  const getOrderBy = (field: string) => {
    const direction: "asc" | "desc" | undefined =
      options.orderBy === field ? options.orderDirection : undefined;
    return direction;
  };

  return (
    <>
      {dataModalLink.isOpen && (
        <ModalLink
          isOpen={dataModalLink.isOpen}
          data={dataModalLink.data}
          onClose={closeModal}
        />
      )}
      <Wrapper>
        <ContentWrapperInfo>
          <Header showIcon={true} title={"Link de pagamento"} />
            <TransacoesTable
              columns={[
                {
                  title: "Nome",
                  field: "nome",
                  defaultSort: getOrderBy("nome"),
                  cellStyle: {
                    width: 350,
                  },
                },
                {
                  title: "Status",
                  field: "labelStatus",
                  defaultSort: getOrderBy("labelStatus"),
                  render: ({expirado = false, ativo = true}: {expirado: boolean, ativo: boolean}) => {
                    return LABEL_STATUS[(!expirado && ativo).toString()]
                  },
                  sorting: false,
                },
                {
                  title: "Pedidos Pagos",
                  field: "quantidadePagamentos",
                  defaultSort: getOrderBy("quantidadePagamentos"),
                },
                {
                  title: "Data de Criação",
                  field: "dataCriacao",
                  defaultSort: getOrderBy("createdDate"),
                  customSort: () => 0,
                },
                {
                  title: "Valor Total",
                  field: "total",
                  defaultSort: getOrderBy("total"),
                },
              ]}
              data={tableData}
              isLoading={isLoading(stateLink.links.state)}
              onRowClick={(_, rowData: any) =>
                redirect(`${LinksRoutesPaths.detalhes}/${rowData.id}`)
              }
              toolbar={false}
              actions={[
                {
                  icon: () => <Delete />,
                  tooltip: "Deletar Link",
                  onClick: (_: any, rowData: any) =>
                    openModal({
                      operation: OperationModalLink.DELETE,
                      nome: rowData.nome,
                      id: rowData.id,
                    }),
                },
                (rowData: any) => ({
                  icon: () => <Link htmlColor="#5050c8" />,
                  tooltip: `Copiar Link ${rowData.link}`,
                  onClick: (_: any, rowData: any) => copy(rowData.link),
                }),
                {
                  icon: () => <WhatsApp />,
                  tooltip: "Compartilhar",
                  onClick: (_: any, rowData: any) =>
                    window.open(`${whatsAppUrl}${rowData.link}`, "_blank"),
                },
              ]}
              page={options.page}
              totalElements={stateLink.links.data.totalElements}
              onChangePage={(page, pageSize) =>
                setOptions((prev) => ({ ...prev, page: page, size: pageSize }))
              }
              onChangeOrder={(orderBY, orderDirection) =>
                onChangeOrder(orderBY, orderDirection)
              }
            />
        </ContentWrapperInfo>
      </Wrapper>
    </>
  );
}
