import { PagamentosRecebedorItem } from "store/pagamento/interfaces";

export interface FilterOptions {
    page: number;
    size: number;
    sort: string | null;
    orderBy: string;
    orderDirection: "asc" | "desc" | undefined;
    name?: string | null;
    status?: string | string[] | null;
    modalidade?: string | string[] | null;
    initialDate: Date;
    finalDate: Date;
    requestedTime?: string;
    period?: string;
}

export enum SelectInputType {
    mobile = "Mobile",
    typeable = "Typeable",
    uniqueValue = "UniqueValue",
    multipleValue = "MultipleValue"
}

export interface SelectAutoCompleteProps {
    title: string;
    placeholder: string;
    options: string[];
    inputType?: SelectInputType;
    defaultValue: string | string[];
    onChange: (newState: string) => void;
    inputValue?: string;
    onInputChange?: (arg: any) => void;
    loading?: boolean;
}

export interface Pagination {
    pageNumber: number;
    row: number;
}

export interface Column {
    id: 'dataCriacao' | 'tipoMovimentacao' | 'nomePagador' | 'modalidade' | 'valor';
    label: string;
}

export interface TableProps {
    pagamentos: PagamentosRecebedorItem[];
    quantidadePagamentos: number;
    loading: boolean;
    onChange: (rows: Pagination) => void;
}

export interface CalendarioInputProps {
    onChange: (date: Date[]) => void;
}