import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";
import { useRedirect, useTypedSelector } from "hooks";
import StringUtils from "utils/StringUtils";
import { isLoading, hasError } from "utils";
import { dateFormat } from "shared/constants";
import { RecebimentosRoutesPaths } from "pages/Recebimentos";
import {
  Wrapper,
  Subtitle,
  Saldo,
  Data,
  SacarItem,
  Cifra,
  InfoWrapper,
  BotaoSacar,
  ContentOverlay,
  Info,
  BotaoVerSaques,
  Observations,
  GridClick,
} from "./styles";
import { useDispatch } from "react-redux";
import { ExtratoRecebiveisActions } from "store/extratoRecebiveis";

interface ResumoContaProps {
  openSaque: any;
}

export default function ResumoConta({ openSaque }: ResumoContaProps) {
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const { recebedor, getSaldo } = useTypedSelector((state) => state.recebedor);
  const auth = useTypedSelector((state) => state.auth);
  const [saqueEnabled, setSaqueEnabled] = useState(false);
  const [tarifaSaque, setTarifaSaque] = useState(0);

  useEffect(() => {
    if (auth.isRecebedor) {
      setTarifaSaque(auth.recebedor?.splitRule?.saque);
    }
  }, [auth.isRecebedor, auth.recebedor]);

  useEffect(() => {
    if (auth.isRecebedor) {
      const saqueHabilitado = recebedor.saldo >= tarifaSaque;
      setSaqueEnabled(saqueHabilitado);
    }
  }, [auth.isRecebedor, recebedor.saldo, tarifaSaque]);

  const handleClickShowSaldoDisponivel = () => {
    dispatch(ExtratoRecebiveisActions.showSaldoDisponivel());
  };
  const handleClickShowSaldoAReceber = () => {
    dispatch(ExtratoRecebiveisActions.showSaldoAReceber());
  };

  return (
    <>
      <Wrapper>
        <Grid container spacing={1} direction="row">
          <GridClick
            item
            xs={12}
            sm={3}
            onClick={handleClickShowSaldoDisponivel}
          >
            <Subtitle>saldo disponível</Subtitle>
            <InfoWrapper>
              <Cifra> R$ </Cifra>
              <Saldo>{StringUtils.format.formatNumber(recebedor.saldo)}</Saldo>
            </InfoWrapper>
          </GridClick>
          <GridClick item xs={12} sm={3} onClick={handleClickShowSaldoAReceber}>
            <Subtitle>saldo a receber*</Subtitle>
            <InfoWrapper>
              <Cifra> R$ </Cifra>
              <Saldo>
                {StringUtils.format.formatNumber(recebedor.saldoAReceber)}
              </Saldo>
            </InfoWrapper>
          </GridClick>
          <Grid item xs={12} sm={3}>
            <Subtitle>último saque</Subtitle>
            <InfoWrapper>
              <Data>
                {recebedor.ultimoSaque
                  ? format(new Date(recebedor.ultimoSaque), dateFormat)
                  : "-"}
              </Data>
            </InfoWrapper>
          </Grid>
          <SacarItem item xs={12} sm={3}>
            {!saqueEnabled && (
              <Tooltip title="No momento você não possui saldo disponível para recebimento. O Saque estará disponível 30 dias após o pagamento do link.">
                <Info />
              </Tooltip>
            )}
            <BotaoSacar disabled={!saqueEnabled} onClick={() => openSaque()}>
              {" "}
              Sacar{" "}
            </BotaoSacar>
          </SacarItem>
        </Grid>
        {getSaldo && isLoading(getSaldo.state) && (
          <ContentOverlay data-testid="saldo-disponivel-loader">
            <CircularProgress />
          </ContentOverlay>
        )}
        {getSaldo && hasError(getSaldo.state) && (
          <ContentOverlay>
            <Alert severity="error">{getSaldo.error} </Alert>
          </ContentOverlay>
        )}
      </Wrapper>{" "}
      <Grid container item spacing={1} xs={12} sm={12} justify="space-between">
        <Observations>
          * Nas parcelas antecipadas, a taxa de antecipação é aplicada no
          momento da disponibilização do saldo.
        </Observations>
        <BotaoVerSaques
          color="primary"
          variant="text"
          onClick={() => redirect(RecebimentosRoutesPaths.listaSaques)}
        >
          {" "}
          Ver Saques{" "}
        </BotaoVerSaques>
      </Grid>
    </>
  );
}
