import styled from "styled-components";
import { Card, Button } from "components";
import { Grid } from "@material-ui/core";
import { Colors, Breakpoints } from "theme";
import InfoIcon from "@material-ui/icons/Info";

export const Wrapper = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: true,
}))`
  flex-basis: 100%;
  width: 100%;
  min-height: 110px;
  padding: 1.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  position: relative;
`;

export const Subtitle = styled.span`
  color: ${Colors.lightGrey};
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const Saldo = styled.span`
  color: ${Colors.primary};
  font-size: 2rem;
`;

export const Data = styled.span`
  font-size: 2rem;
`;

export const Cifra = styled.span`
  color: ${Colors.lightGrey};
  font-size: 1.3rem;
`;

export const InfoWrapper = styled.div`
  padding-top: 0.2rem;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    margin-bottom: 2rem;
  }
`;

export const SacarItem = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    align-items: center;
    justify-content: center;
  }
`;
export const BotaoSacar = styled(Button)`
  min-width: 150px;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    width: 100%;
  }
`;

export const BotaoVerSaques = styled(Button)`
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
`;

export const Info = styled(InfoIcon)`
  color: ${Colors.alert};
  margin-right: 0.3rem;
`;
export const Observations = styled.span`
  margin-left: 5px;
  color: #999999;
  font-weight: bold;
  font-size: 13px;
`;

export const GridClick = styled(Grid)`
  cursor: pointer;
  :hover {
    background-color: #f4f4f4;
    border-radius: 4px;
  }
`;
