import styled from 'styled-components'
import { Breakpoints, Colors } from 'theme'
import { Button, Card } from 'components'
import { Grid, Link } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.2rem;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: ${Breakpoints.maxMedium}) {
    flex-direction: column;
  }
`
export const WrapperDetalhes = styled.div`
  margin: 1rem 0 2rem 0;  
  width: 100%;  
`

export const Paragraph = styled.p`
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: ${Colors.lightTypography};
`

export const ValorTotalWrapper = styled.div`
  display: flex;
  color: ${Colors.lightGrey};
  font-size: 1rem;
  span {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`

export const WrapperProduto = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
`

export const CardProduto = styled(Card).attrs((props) => ({
  shadowed: false,
  rounded: false,
}))`
  flex: 1 0 456px;
  box-sizing: border-box;
  margin: 1rem 0.25em;

  @media (max-width: ${Breakpoints.maxSmall}) {
    flex: 1 0 260px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
`

export const CardValidade = styled(Card).attrs((props) => ({
  shadowed: false, rounded: false,
}))`
  flex: 1 0 456px;
  box-sizing: border-box;
  margin: 1rem 0.25rem 0 0.25rem;
  padding: 1.4rem;

  @media (max-width: ${Breakpoints.maxSmall}) {
    flex: 1 0 260px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
`

export const SalvarValidade = styled(Button)`
  margin: 1.4rem;

  @media (max-width: ${Breakpoints.maxSmall}) {
    margin: 1.4rem 2.1rem 1.4rem auto;
  }
`;

export const CardProdutoContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardProdutoTitle = styled.span`
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: ${Colors.lightTypography};
  text-transform: capitalize;
`

export const CardProdutoSubTitle = styled.span`
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${Colors.lightTypography};
  text-transform: capitalize;
`
export const AbrirLink = styled(Link)`
  color: #5050c8;
  text-decoration: none; 

  &&:hover {
    text-decoration: none; 
  }

  svg {
    padding-left: 0.2rem;
  }
`

export const CopiarLink = styled(Link)`
  color: #5050c8;
  text-decoration: none;
  margin-left: 1rem; 
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;

  &&:focus {
    outline: none; 
  }

  svg {
    padding-left: 0.2rem;
  }
`

export const LinkWrapper = styled.div`
    padding-top: 0.5rem;
    display: flex;
`

export const DetalheCardWrapper = styled(Grid)`

  @media (max-width: ${Breakpoints.maxSmall}) {
    && {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

export const DetalheCard = styled(Grid)`
  @media (max-width: ${Breakpoints.maxSmall}) {
    && {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;