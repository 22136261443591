import querystring from "querystring";
import { Endpoints } from "api/Endpoints";
import { axiosInstance } from "api";
import { Dispatch } from "redux";

import { Action } from "store/interfaces";
import {
  ExtratoRecebiveisOpened,
  ExtratoRecebiveisState,
  SaldoDisponivelApiResult, 
  IOptions,
  QueryStringExtratoRecebiveis,
  SaldoAReceberApiResult,
} from "./interfaces";
import { createErrorMessage } from "utils";
import { StateEnum } from "shared/enums";

export enum ExtratoRecebiveisTypes {
  SHOW_SALDO_DISPONIVEL = "extratoRecebiveis/SHOW_SALDO_DISPONIVEL",
  SHOW_SALDO_A_RECEBER = "extratoRecebiveis/SHOW_SALDO_A_RECEBER",
  GET_SALDO_A_RECEBER = "extratoRecebiveis/GET_SALDO_A_RECEBER",
  GET_SALDO_A_RECEBER_SUCCESS = "extratoRecebiveis/GET_SALDO_A_RECEBER_SUCCESS",
  GET_SALDO_A_RECEBER_FAILED = "extratoRecebiveis/GET_SALDO_A_RECEBER_FAILED",
  GET_SALDO_A_RECEBER_ERROR_HANDLED = "extratoRecebiveis/GET_LOCALIDADE_ERROR_HANDLED",
  GET_SALDO_DISPONIVEL = "extratoRecebiveis/GET_SALDO_DISPONIVEL",
  GET_SALDO_DISPONIVEL_SUCCESS = "extratoRecebiveis/GET_SALDO_DISPONIVEL_SUCCESS",
  GET_SALDO_DISPONIVEL_FAILED = "extratoRecebiveis/GET_SALDO_DISPONIVEL_FAILED",
  GET_SALDO_DISPONIVEL_ERROR_HANDLED = "extratoRecebiveis/GET_SALDO_DISPONIVEL_ERROR_HANDLED",
}

const initialState: ExtratoRecebiveisState = {
  extratoOpened: ExtratoRecebiveisOpened.SALDO_DISPONIVEL,
  saldoAReceber: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      saldosFuturos: [],
      size: 0,
      sort: {},
      totalElements: 0,
      totalPages: 0,
    },
  },
  saldoDisponivel: {
    state: StateEnum.IDLE,
    error: null,
    data: {
      saldosDisponiveis: [],
      size: 0,
      sort: {},
      totalElements: 0,
      totalPages: 0,
    },
  },
};

export function reducer(
  state = initialState,
  action: Action
): ExtratoRecebiveisState {
  switch (action.type) {
    case ExtratoRecebiveisTypes.SHOW_SALDO_DISPONIVEL:
      return {
        ...state,
        extratoOpened: ExtratoRecebiveisOpened.SALDO_DISPONIVEL,
      };
    case ExtratoRecebiveisTypes.SHOW_SALDO_A_RECEBER:
      return {
        ...state,
        extratoOpened: ExtratoRecebiveisOpened.SALDO_A_RECEBER,
      };

    case ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER:
      return {
        ...state,
        saldoAReceber: { ...state.saldoAReceber, state: StateEnum.LOADING },
      };
    case ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER_SUCCESS:
      return {
        ...state,
        saldoAReceber: {
          ...state.saldoAReceber,
          state: StateEnum.IDLE,
          data: action.payload.saldoAReceber,
        },
      };
    case ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER_FAILED:
      return {
        ...state,
        saldoAReceber: {
          ...state.saldoAReceber,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER_ERROR_HANDLED:
      return {
        ...state,
        saldoAReceber: {
          ...state.saldoAReceber,
          state: StateEnum.IDLE,
          error: null,
        },
      };

    case ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL:
      return {
        ...state,
        saldoDisponivel: { ...state.saldoDisponivel, state: StateEnum.LOADING },
      };
    case ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL_SUCCESS:
      return {
        ...state,
        saldoDisponivel: {
          ...state.saldoDisponivel,
          state: StateEnum.IDLE,
          data: action.payload.saldoDisponivel,
        },
      };
    case ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL_FAILED:
      return {
        ...state,
        saldoDisponivel: {
          ...state.saldoDisponivel,
          state: StateEnum.ERROR,
          error: action.payload.error,
        },
      };
    case ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL_ERROR_HANDLED:
      return {
        ...state,
        saldoDisponivel: {
          ...state.saldoDisponivel,
          state: StateEnum.IDLE,
          error: null,
        },
      };
    default:
      return state;
  }
}

export class ExtratoRecebiveisActions {
  static showSaldoDisponivel(): Action {
    return { type: ExtratoRecebiveisTypes.SHOW_SALDO_DISPONIVEL };
  }
  static showSaldoAReceber(): Action {
    return { type: ExtratoRecebiveisTypes.SHOW_SALDO_A_RECEBER };
  }

  static getSaldoAReceberStarted(): Action {
    return { type: ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER };
  }
  static getSaldoAReceberSuccess(data: SaldoAReceberApiResult): Action {
    return {
      type: ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER_SUCCESS,
      payload: { saldoAReceber: data },
    };
  }
  static getSaldoAReceberFailed(error: string): Action {
    return {
      type: ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER_FAILED,
      payload: { error },
    };
  }
  static handleGetLocalidadeError(): Action {
    return { type: ExtratoRecebiveisTypes.GET_SALDO_A_RECEBER_ERROR_HANDLED };
  }

  static getSaldoAReceber(id: string, options: QueryStringExtratoRecebiveis) {
    return async (dispatch: Dispatch) => {

      const {page, size, sort} = options;
      
      try {
        dispatch(ExtratoRecebiveisActions.getSaldoAReceberStarted());

        const { data } = await axiosInstance.get(
          `${Endpoints.recebedor.recebedor()}/${id}/saldo-a-receber`, {
            params: {page, size, sort}
          }
        );

        dispatch(
          this.getSaldoAReceberSuccess({
            ...data,
          })
        );
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(ExtratoRecebiveisActions.getSaldoAReceberFailed(error));
      }
    };
  }

  static getSaldoDisponivelStarted(): Action {
    return { type: ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL };
  }
  static getSaldoDisponivelSuccess(data: SaldoDisponivelApiResult): Action {
    return {
      type: ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL_SUCCESS,
      payload: { saldoDisponivel: data },
    };
  }
  static getSaldoDisponivelFailed(error: string): Action {
    return {
      type: ExtratoRecebiveisTypes.GET_SALDO_DISPONIVEL_FAILED,
      payload: { error },
    };
  }
  static getSaldoDisponivel(id: string, options:IOptions) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(ExtratoRecebiveisActions.getSaldoDisponivelStarted());

        const { data } = await axiosInstance.get(
            `${Endpoints.recebedor.recebedor()}/${id}/saldo-disponivel?`,
            {params: {page:options.page, size:options.size, sort:options.sort}}
        );

        dispatch(
            this.getSaldoDisponivelSuccess({
              ...data,
            })
        );
      } catch (err) {
        const error = createErrorMessage(err);
        dispatch(ExtratoRecebiveisActions.getSaldoDisponivelFailed(error));
      }
    };
  }
}
