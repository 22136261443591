import { useTypedSelector } from "hooks";
import React from "react";
import { ExtratoRecebiveisOpened } from "store/extratoRecebiveis";
import SaldoAReceber from "./SaldoAReceber";
import SaldoDisponivel from "./SaldoDisponivel";

import { Container } from "./styles";

const ExtratoRecebiveis: React.FC = () => {
  const extratoRecebiveisState = useTypedSelector(
    (state) => state.extratoRecebiveis
  );
  return (
    <Container>
      {extratoRecebiveisState.extratoOpened ===
      ExtratoRecebiveisOpened.SALDO_DISPONIVEL ? (
        <SaldoDisponivel />
      ) : (
        <SaldoAReceber />
      )}
    </Container>
  );
};

export default ExtratoRecebiveis;
