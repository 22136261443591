import { PaymentMethods } from 'pages/Pagamento/interfaces';

const hasCancelamento = (metodoId: string) => {
    return metodoId === PaymentMethods.CARTAO || metodoId === PaymentMethods.PIX
}

export const tooltipCancelamento = (rowData: any) => {
    return rowData.metodoId === PaymentMethods.CARTAO && rowData.isCancelamentoExpired && !rowData.isCancelado ?
        'Pagamento passou do prazo para cancelamento' :
        !rowData.isPago || !hasCancelamento(rowData.metodoId) ? 'Cancelamento indisponível' : 'Cancelar Pagamento';
}

export const disabledCancelamento = (rowData: any) => {
    return !hasCancelamento(rowData.metodoId) ||
        rowData.isCancelamentoExpired ||
        rowData.isCancelado ||
        !rowData.isPago;
}