import styled from 'styled-components';
import { Colors, Breakpoints } from 'theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoints.maxSmall}) {
    min-height: 100vh;
  }

  svg {
    font-size: 3em;
    color: ${Colors.danger};
  }
`;

export const Title = styled.h1`
  color: ${Colors.danger};
  margin: 0.5rem 0;
`;

export const Paragraph = styled.p`
  margin: 0 auto 1.5rem;
  font-size: 1.25rem;
  text-align: center;
`;