import React, { lazy, Suspense, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RecebedorActions } from "store/recebedor";
import { muiTableLocalization as localization } from "shared/constants";
import { useRedirect, useTypedSelector } from "hooks";
import { hasError, isLoading } from "utils";
import tableIcons from "shared/tableIcons";
import StringUtils from "utils/StringUtils";
import { RecebimentosRoutesPaths } from "pages/Recebimentos";
import Loader from "components/CenteredLoader";
import Header from "./Header";
import { PageWrapper } from "shared/styles";
import { ContentWrapperInfo, Subtitle } from "./styles";
const MaterialTable = lazy(() => import("@material-table/core"));

const optionsStateInitial = {
  page: 0,
  size: 5,
  sort: "createdDate,desc",
  periodo: 15,
};

const ExtratoSaldoFuturo = () => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const { periodo }: { periodo?: string } = useParams();
  const auth = useTypedSelector((state) => state.auth);
  const recebedorId = auth.recebedor.id || "";
  const getAllExtratoSaldoFuturo = useTypedSelector(
    (state) => state.recebedor.getAllExtratoSaldoFuturo
  );
  const [options, setOptions] = useState({
    ...optionsStateInitial,
    periodo: Number(periodo),
  });

  const tableData = getAllExtratoSaldoFuturo?.data?.recebiveis?.map(
    (item: any) => {
      return { ...item };
    }
  );

  useEffect(() => {
    dispatch(RecebedorActions.getAllExtratoSaldoFuturo(recebedorId, options));
  }, [dispatch, recebedorId, options, periodo]);

  useEffect(() => {
    if (hasError(getAllExtratoSaldoFuturo.state)) {
      dispatch(RecebedorActions.resetGetAllExtradoSaldoFuturo());
    }
  }, [getAllExtratoSaldoFuturo.state, dispatch]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      periodo: Number(periodo),
    }));
  }, [periodo]);

  return (
    <PageWrapper>
      <ContentWrapperInfo>
        <Header
          periodo={Number(periodo)}
          setFiltro={(dia) => {
            redirect(`${RecebimentosRoutesPaths.extratoSaldoFuturo}/${dia}`);
          }}
        />
        <Suspense fallback={<Loader />}>
          <MaterialTable
            title={"Saldo a receber"}
            icons={tableIcons}
            columns={[
              { title: "Operação", field: "operacaoIdPagamento" },
              { title: "Parcela", field: "parcela" },
              { title: "Nome do link", field: "nomeLink" },
              { title: "Data da Compra", field: "dataHoraCompra" },
              {
                title: "Antecipado",
                field: "ravAutomatica",
                render: (row: any) => (row.ravAutomatica ? "Sim" : "Não"),
              },
              {
                title: "Valor",
                field: "valorParcela",
                render: (row: any) =>
                  StringUtils.format.formatBRL(row.valorParcela),
              },
            ]}
            data={tableData}
            options={{
              draggable: false,
              sorting: false,
              search: false,
              toolbar: false,
              toolbarButtonAlignment: "left",
              showTitle: false,
              headerStyle: {
                fontWeight: "bold",
              },
            }}
            localization={localization}
            isLoading={isLoading(getAllExtratoSaldoFuturo.state)}
            style={{
              boxShadow: "none",
            }}
            page={options.page}
            totalCount={getAllExtratoSaldoFuturo.data.totalElements}
            onChangePage={(page) =>
              setOptions((prev) => ({ ...prev, page: page }))
            }
            onChangeRowsPerPage={(perPage) =>
              setOptions((prev) => ({ ...prev, size: perPage }))
            }
          />
        </Suspense>
        <Subtitle>
          * Nas parcelas antecipadas, a taxa de antecipação é aplicada no
          momento da disponibilização do saldo.
        </Subtitle>
      </ContentWrapperInfo>
    </PageWrapper>
  );
};

export default ExtratoSaldoFuturo;
