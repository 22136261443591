import { ErrorMessages } from 'shared/messages';

export function createErrorMessage(err: any) {
  let errorMessage = ErrorMessages.defaultError;
  if (err.response) {
    const { data } = err.response;
    errorMessage = data.message ? data.message : errorMessage;
  }
  return errorMessage;
}
