import styled from 'styled-components'
import { Breakpoints, Colors } from 'theme'

export const ContentWrapperInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column; 

  @media (max-width: ${Breakpoints.maxMedium}) {
    flex-direction: column;
  }
`

export const ContentLoading = styled.div`
  background-color: rgb(255, 255, 255, 0.1);  
  display: flex;
  justify-content: center;
  align-items: center;  
  position: relative;
  height: 100%;
  width: 100%;
`

export const Saldo = styled.span`
  color: ${Colors.primary};
  font-size: 1.7rem;
`

export const Cifra = styled.span`
  color: ${Colors.lightGrey};
  font-size: 1rem;
`

export const InfoWrapper = styled.div`
  padding-top: 0.2rem;
  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    margin-bottom: 2rem;
  }
`
export const SmallSubtitle = styled.span`
  font-size: 0.7rem;
  margin-bottom: 1.5rem;
`
export const SaldoWrapper = styled.div`
  padding-right: 1rem; 
`