import React from 'react';

import { Wrapper } from './styles';

interface CardProps {
  children: any;
  noPadding?: boolean;
  rounded?: boolean;
  shadowed?: boolean;
  marginTop?: boolean;
}

export default function Card(props: CardProps) {
  const { children, noPadding, rounded = false, shadowed = true, marginTop, ...rest } = props;

  return (
    <Wrapper
      {...rest}
      noPadding={noPadding}
      rounded={rounded}
      shadowed={shadowed}
      marginTop={marginTop}
    >
      {children}
    </Wrapper>
  );
}
