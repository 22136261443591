import styled from "styled-components";
import { Colors, Breakpoints } from "theme";
import { AttachMoney } from "@material-ui/icons";
import { Divider } from "@material-ui/core";

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: ${Breakpoints.maxSmallTablet}) {
    flex-direction: column;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.span`
  color: ${Colors.lightTypography};
  font-size: 1.3rem;
  display: flex;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const AttachMoneyIcon = styled(AttachMoney)`
  margin-right: 0.5rem;
  color: ${Colors.unicredGoldish};
`;

export const HeaderDivider = styled(Divider)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
