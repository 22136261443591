import styled from "styled-components";
import { Colors, Breakpoints } from 'theme';

export const Container = styled.div``;

export const Pagination = styled.td`
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    p {
        padding: 0 0.8rem;
        font-size: 0.8rem;
    }

    @media (max-width: ${Breakpoints.maxMedium}) {
        flex-direction: column;
        align-items: flex-start;
    
        p {
            white-space: normal;
        }
    }

    table {
        align-self: flex-end;
        td {
            border: transparent;
        }
    }
`;
