import { StateEnum } from "shared/enums";
import { Dispatch } from "redux";
import { axiosInstance } from "api";
import { FazerSimulacao, SimulacaoRealizada, SimulacaoState } from "./interface";
import { Action } from "store/interfaces";
import querystring from "querystring";
import axios, { CancelTokenSource } from "axios";

export enum SimulacaoTypes {
  GET_SIMULACAO_SUCCESS = "simulacao/GET_SIMULACAO_SUCCESS",
  GET_SIMULACAO_FAILED = "simulacao/GET_SIMULACAO_FAILED",
  GET_SIMULACAO_LOADING = "simulacao/GET_SIMULACAO_LOADING",
  GET_SIMULACAO_CANCEL_TOKEN = "simulacao/GET_SIMULACAO_CANCEL_TOKEN",
}

const initialState: SimulacaoState = {
  state: StateEnum.IDLE,
  valorAntecipacao: 0,
  valorParcela: 0,
  valorTotal: 0,
  metodo: "Pix",
  parcela: undefined,
};

export function reducer(state = initialState, action: Action): SimulacaoState {
	switch (action.type) {
		case(SimulacaoTypes.GET_SIMULACAO_LOADING):
			return {
				...state,
				state: StateEnum.LOADING
			}
		case(SimulacaoTypes.GET_SIMULACAO_FAILED):
			return {
				...state,
				state: StateEnum.ERROR
			}
		case(SimulacaoTypes.GET_SIMULACAO_SUCCESS):
			return {
				...action.payload,
				state: StateEnum.COMPLETE
			}
		default:
			return state
	}
}


export class SimulacaoActions {
	static iniciarBuscaSimulacao(): Action {
		return { type: SimulacaoTypes.GET_SIMULACAO_LOADING }
	}

	static simulacaoRealizada(data: SimulacaoState): Action {
		return { 
			type: SimulacaoTypes.GET_SIMULACAO_SUCCESS,
			payload: data
		}
	}

	static simulacaoComErro(): Action {
		return { type: SimulacaoTypes.GET_SIMULACAO_FAILED }
	}

	static buscarSimulacao(dadosSimulacao: FazerSimulacao, cancelToken: CancelTokenSource): (dispatch: Dispatch) => {} {
		return async (dispatch: Dispatch) => {
			try {
				const {metodo, parcela, valor} = dadosSimulacao;

				if (dadosSimulacao.valor < 5) {
					dispatch(SimulacaoActions.simulacaoRealizada({
						valorAntecipacao: 0,
						valorParcela: 0,
						valorTotal: 0,
						state: StateEnum.COMPLETE,
						metodo,
						parcela
					}))

					return
				}

				dispatch(SimulacaoActions.iniciarBuscaSimulacao());
				
				const response = await axiosInstance.get<SimulacaoRealizada>(`recebedor/${dadosSimulacao.idRecebedor}/simulador?${querystring.stringify({valor, parcela, metodo})}`, {
					cancelToken: cancelToken.token
				});

				dispatch(SimulacaoActions.simulacaoRealizada({
					...response.data,
					state: StateEnum.COMPLETE,
					metodo,
					parcela,
				}))
			}
			catch (error) {
				if (axios.isCancel(error)) return;
				dispatch({type: SimulacaoTypes.GET_SIMULACAO_FAILED})
			}
		}
	}
}