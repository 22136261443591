import React from "react";
import { Modal, Button } from "components";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "hooks";
import { AuthActions } from "store/auth";

import { Header, Content, InfoIcon } from "./styles";
import { logger } from "utils";

export default function SessionExpiredModal() {
  const dispatch = useDispatch();
  const isExpired = useTypedSelector((state) => state.auth.session.expired);
  const plataforma = useTypedSelector((state) => state.auth.plataforma);
  logger.info("[SessionExpiredModal]: isExpired" + isExpired);
  const backToHomeMobileOrLogoutWeb = () => {
    logger.error("Sair");
    if (plataforma) {
      window["backToHomeMobile"](plataforma);
    } else {
      dispatch(AuthActions.logout());
    }
  };
  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      disableCloseIcon
      onClose={() => null}
      open={isExpired}
      noPadding
    >
      <Header>
        <InfoIcon />
        <h1>Sessão Expirada</h1>
      </Header>
      <Content>
        <p>
          O tempo da sua sessão expirou, por favor entre novamente com seus
          dados para que possamos autenticar seu acesso.
        </p>
        <Button onClick={backToHomeMobileOrLogoutWeb} fullWidth>
          Entendi
        </Button>
      </Content>
    </Modal>
  );
}
