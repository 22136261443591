import React, { useEffect, useState } from 'react'
import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { LinkPagamentoActions, ParamsLink } from 'store/linkPagamento'
import { PagamentoActions } from 'store/pagamento/pagamento'
import { LinksRoutesPaths } from '../Links'
import { useTypedSelector } from 'hooks'
import { isLoading } from 'utils'
import MainContent from './MainContent'
import Info from './Info'
import SubHeader from './SubHeader'
import { Wrapper, ContentLoading } from './styles'
import { dataParaValidade, isDataValida, validadeParaData } from 'utils/validadeLink';

export default function DetalhesLink() {
  const params: ParamsLink = useParams()
  const [showView, setShowView] = useState('detalhe')
  const dispatch = useDispatch()
  const state = useTypedSelector((state) => state.linkPagamento);
  const [dataValidade, setDataValidade] = useState('');
 
  useEffect(() => {
    dispatch(PagamentoActions.resetPagamentoLink())
    dispatch(LinkPagamentoActions.getOneLink(params))
  }, [dispatch, params]);

  useEffect(() => {
    setDataValidade(validadeParaData(state?.link?.data.validade, state?.link?.data?.createdDate) ?? '')

  }, [state.link?.data?.validade, state?.link?.data?.dataCriacao]);

  const salvarNovaData = () => {
    const novaValidade = dataParaValidade(dataValidade, state?.link?.data?.createdDate);
    
    if (!isDataValida(dataValidade) || !dataValidade || (!novaValidade && novaValidade !== 0)) return;

    dispatch(LinkPagamentoActions.editarDataValidadeLink(params.id, novaValidade));
  }

  const viewDetalhe = (view: string) => setShowView(view)

  return (
    <>
      {isLoading(state.link.state) ? (
        <ContentLoading>
          <CircularProgress />
        </ContentLoading>
      ) : (
        <>
          <SubHeader
            backAddress={LinksRoutesPaths.base}
            criadoEm={state.link.data.createdDate ? new Date(parseISO(state.link.data.createdDate)) : new Date()}
            title={state.link.data?.nome}
            onClickDetalhes={() => setShowView('detalhe')}
            onClickPagamentos={() => viewDetalhe('pagamento')}
            active={showView}
          />
          <Wrapper>
            {showView === 'detalhe' ? (
              <Info 
                salvarNovaDataValidade={salvarNovaData}
                editarDataValidade={setDataValidade}
                dataValidade={dataValidade}
              />
            ) : (
              <MainContent linkId={params.id} />
            )}
          </Wrapper>
        </>
      )}
    </>
  )
}
