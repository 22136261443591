import React, {useMemo} from 'react';
import { useLocation } from 'react-router-dom';
import { RoutesPaths } from 'routes';

export default function PaginaNaoEncontrada() {
    const {pathname} = useLocation();

    const paginaExistente = useMemo(() => {
        return Object.values(RoutesPaths).filter((path: any) => {
            const value = typeof path === 'function' ? path?.base : path;

            return value === pathname;
        });
    }, []);

    return paginaExistente.length === 0 ? (
        <h2 style={{textAlign: "center"}}>Pagina Não encontrada</h2>
    ) : null;
}