import React from "react";
import { useDispatch } from "react-redux";
import {
  DialogActions,
  DialogContent,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import LogoUnicredPayHorizontalBranco from "assets/logos/logo_unicredpay_horizontal_branco.svg";
import { Alert } from "@material-ui/lab";
import { FormControlLabel } from "@material-ui/core";
import { AuthActions } from "store/auth";
import { isLoading, hasError } from "utils";
import { Button } from "components";
import { onboardingModalProps } from "../Interfaces";
import OnboardingTaxasTable, { TaxasTable } from "../OnboardingTaxasTable";
import { CHECK_ACEITE, TITLE_TAXAS_TERMO } from "../Constantes";
import OnboardingTermo from "pages/OnBoarding/OnboardingTermo";
import {
  Wrapper,
  Header,
  ContentLoading,
  ContentButnError,
  Brand,
  Title,
  ContentText,
} from "./styles";
import OnboardingTermoCentralAtendimento from "../OnboardingTermoCentralAtendimento";

const OnboardingModal = ({
  isOpen,
  recebedor,
  taxasCooperativas,
  acceptTerm,
  checkAcceptTerm,
  tryAgain,
  checkAccept,
  plataforma,
}: onboardingModalProps) => {
  const dispatch = useDispatch();

  const isLoadingOnboarding = () => {
    return (
      isLoading(recebedor.getTarifasBoleto.state) ||
      isLoading(taxasCooperativas.getCooperativaTaxas.state) ||
      isLoading(taxasCooperativas.getTermo.state)
    );
  };

  const hasErrorOnboarding = () => {
    return (
      hasError(recebedor.getTarifasBoleto.state) ||
      hasError(taxasCooperativas.getCooperativaTaxas.state) ||
      hasError(taxasCooperativas.getTermo.state) ||
      hasError(recebedor.novoRecebedor.state)
    );
  };

  const backToHomeMobileOrLogoutWeb = () => {
    if (plataforma) {
      window["backToHomeMobile"](plataforma);
    } else {
      dispatch(AuthActions.logout());
    }
  };

  return (
    <Wrapper
      open={!isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={true}
      scroll="paper"
      maxWidth="lg"
      style={{ zIndex: 1000 }}
      id="termo_aceite_modal_principal"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Header>
        <Brand src={LogoUnicredPayHorizontalBranco} alt="" draggable={false} />
      </Header>
      <DialogContent dividers={true}>
        {isLoadingOnboarding() ? (
          <ContentLoading>
            <CircularProgress />
          </ContentLoading>
        ) : hasErrorOnboarding() ? (
          <>
            <ContentLoading>
              {hasError(recebedor.novoRecebedor.state) && (
                <Alert severity="error">{recebedor.novoRecebedor.error} </Alert>
              )}
              {hasError(taxasCooperativas.getCooperativaTaxas.state) && (
                <Alert severity="error">
                  {taxasCooperativas.getCooperativaTaxas.error}{" "}
                </Alert>
              )}
              {hasError(recebedor.getTarifasBoleto.state) && (
                <Alert severity="error">
                  {recebedor.getTarifasBoleto.error}{" "}
                </Alert>
              )}
              {hasError(taxasCooperativas.getTermo.state) && (
                <Alert severity="error">
                  {taxasCooperativas.getTermo.error}{" "}
                </Alert>
              )}
              <ContentButnError>
                <Button onClick={() => tryAgain()}>Tentar Novamente</Button>
              </ContentButnError>
            </ContentLoading>
          </>
        ) : (
          <ContentText>
            <OnboardingTermo
              termo={taxasCooperativas.getTermo.data?.unicredPay}
              nome={"unicredPay"}
            />
            <OnboardingTermo
              termo={taxasCooperativas.getTermo.data?.boletoCobranca}
              nome={"boletoCobranca"}
            />

            <Title>{TITLE_TAXAS_TERMO}</Title>
            <OnboardingTaxasTable
              taxas={taxasCooperativas.getCooperativaTaxas.taxas}
            />
            <TaxasTable taxas={recebedor.getTarifasBoleto.data} />

            {/* <OnboardingTermoCentralAtendimento
              termo={taxasCooperativas.getTermo.data?.centralAtendimento}
              nome={"centralAtendimento"}
            /> */}
          </ContentText>
        )}
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "flex-start", marginLeft: "5px" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="termo_aceite_modal_principal_check_aceite"
              color="primary"
              checked={checkAccept}
              onChange={checkAcceptTerm}
              name="termoAceite"
              disabled={
                isLoading(recebedor.novoRecebedor.state) ||
                hasError(recebedor.novoRecebedor.state)
              }
            />
          }
          label={CHECK_ACEITE}
        />
      </DialogActions>
      <DialogActions>
        <Button
          onClick={backToHomeMobileOrLogoutWeb}
          disabled={isLoading(recebedor.novoRecebedor.state)}
          fullWidth
          id="termo_aceite_modal_principal_btn_cancelar"
        >
          Cancelar
        </Button>
        <Button
          id="termo_aceite_modal_principal_btn_aceitar"
          onClick={() => acceptTerm()}
          disabled={
            !checkAccept ||
            isLoading(recebedor.novoRecebedor.state) ||
            hasError(recebedor.novoRecebedor.state)
          }
          loading={isLoading(recebedor.novoRecebedor.state)}
          loadingMessage={"Aguarde.."}
          fullWidth
        >
          Aceitar
        </Button>
      </DialogActions>
    </Wrapper>
  );
};

export default OnboardingModal;
