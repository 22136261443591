import { Action } from 'store/interfaces';
import { UiState } from 'store/ui/interfaces';

export enum UiTypes {
  SHOW_DRAWER = 'ui/SHOW_DRAWER',
  HIDE_DRAWER = 'ui/HIDE_DRAWER',
  SHOW_EXIT = 'ui/SHOW_EXIT',
  HIDE_EXIT = 'ui/HIDE_EXIT',
}

const initialState: UiState = {
  drawer: {
    isOpen: false,
  },
  exit: {
    isVisible: true,
  }
};

export function reducer(state = initialState, action: Action): UiState {
  switch (action.type) {
    case UiTypes.SHOW_DRAWER:
      return { ...state, drawer: { ...state.drawer, isOpen: true } };
    case UiTypes.HIDE_DRAWER:
      return { ...state, drawer: { ...state.drawer, isOpen: false } };
    case UiTypes.SHOW_EXIT:
      return { ...state, exit: { ...state.exit, isVisible: true } };
    case UiTypes.HIDE_EXIT:
      return { ...state, exit: { ...state.exit, isVisible: false } };
    default:
      return state;
  }
}

export class UiActions {
  static showDrawer(): Action {
    return { type: UiTypes.SHOW_DRAWER };
  }

  static hideDrawer(): Action {
    return { type: UiTypes.HIDE_DRAWER };
  }

  static showExit(): Action {
    return { type: UiTypes.SHOW_EXIT };
  }

  static hideExit(): Action {
    return { type: UiTypes.HIDE_EXIT };
  }
}
