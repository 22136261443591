import styled from 'styled-components'
import { Breakpoints } from 'theme'
import { Button } from 'components'
import { FormatAlignJustify, Cancel, Payment } from '@material-ui/icons'
import { IconButton, Tooltip } from '@material-ui/core'

export const ContentPosition = styled.div` 
  position: sticky;
  top: 0;
  z-index: 300;
  width: 100%; 
  align-items: center;
  box-sizing: border-box;  
`

export const DetalhesButton = styled(Button)`
  margin-left: auto;
  margin-right: 2rem;
  margin-left: 0rem;

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 0.8rem;
  }
`

export const MobileButton = styled(IconButton)`
  &:active {
    background: 'black';
  }
`
export const DetalhesIcon = styled(FormatAlignJustify)``
export const CancelIcon = styled(Cancel)``
export const PaymentIcon = styled(Payment)``
export const TooltipUi = styled(Tooltip)``

export const ContentMobile = styled.div`
  display: flex;
`
