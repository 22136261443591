import { QueryString } from "store/cooperativaTaxas";
import { QueryStringLink } from "store/linkPagamento/interfaces";
import querystring from "querystring";
import {
  QueryStringPagamentoLink,
  QueryStringPagamentoRecebedor,
} from "store/pagamento/interfaces";

abstract class AbstractEndpoints {
  protected abstract baseURL: string;
  protected abstract version: string;
  protected rootURL?: string = "";
  protected env: string = "";
  protected plataformaUlr: string = "";

  setVersion(version: string) {
    this.version = version;
    return this;
  }

  setBaseURL(baseURL: string) {
    this.baseURL = baseURL;
    return this;
  }

  getURL(): string {
    return `${this.rootURL ? this.rootURL : ""}${this.baseURL}`;
  }

  getPlataformaUrl(): string {
    this.env = process.env.REACT_APP_ENVIRONMENT?.toLocaleUpperCase() || "";
    this.plataformaUlr = process.env?.[`REACT_APP_BASE_URL_${this.env}`] || "";
    return this.plataformaUlr;
  }
}

class UsuarioEndpoints extends AbstractEndpoints {
  baseURL = "usuario";
  rootURL = "";
  version = "";

  usuarios() {
    return `${this.getURL()}`;
  }
}
class LinkEndpoints extends AbstractEndpoints {
  baseURL = "/links-pagamento";
  rootURL = "";
  version = "";

  links(query?: QueryStringLink) {
    if (query) return `${this.getURL()}?${querystring.stringify(query)}`;

    return `${this.getURL()}`;
  }
}
class PagamentoEndpoints extends AbstractEndpoints {
  baseURL = "/pagamento";
  rootURL = "";
  version = "";
  pagamento(query?: QueryStringPagamentoLink | QueryStringPagamentoRecebedor) {
    if (query) return `${this.getURL()}?${querystring.stringify(query)}`;
    return `${this.getURL()}`;
  }
}

class CooperativaTaxasEndpoints extends AbstractEndpoints {
  baseURL = "/configuracao-cooperativa";
  rootURL = "";
  version = "";
  taxas(query?: QueryString) {
    if (query) return `${this.getURL()}?${querystring.stringify(query)}`;

    return `${this.getURL()}`;
  }
}

class RecebedorEndpoints extends AbstractEndpoints {
  baseURL = "/recebedor";
  rootURL = "";
  version = "";

  recebedor() {
    return `${this.getURL()}`;
  }
}

class AuthEndpoints {
  token() {
    return "api/auth";
  }
  login() {
    
    return `${window.location.origin}/api/auth/login`
  }
}
class LocalidadeEndpoints extends AbstractEndpoints {
  baseURL = "/localidade";
  rootURL = "";
  version = "";

  localidade() {
    return `${this.getURL()}`;
  }
}
class Pagarme extends AbstractEndpoints {
  baseURL = "/pagarme";
  rootURL = "";
  version = "";

  pagarme() {
    return `${this.getURL()}`;
  }
}
class Config extends AbstractEndpoints {
  baseURL = "/config-repo";
  rootURL = "";
  version = "";

  config() {
    return `${this.getURL()}`;
  }
}
class OpenFinance extends AbstractEndpoints {
  baseURL = "/open-finance";
  rootURL = "";
  version = "";

  openFinance() {
    return `${this.getURL()}`;
  }
}

class CcsArquivos extends AbstractEndpoints {
  rootURL = "/ccs-arquivos-uis";
  baseURL = "/arquivo";
  version = "v1";

  gerar(cpfCnpj: string, numeroBacen: string | number) {
    return `${this.getURL()}/${cpfCnpj}/${numeroBacen}/gerar`;
  }
}

export class Endpoints {
  static pagamento = new PagamentoEndpoints();
  static usuario = new UsuarioEndpoints();
  static link = new LinkEndpoints();
  static auth = new AuthEndpoints();
  static ccsArquivos = new CcsArquivos();
  static cooperativaTaxas = new CooperativaTaxasEndpoints();
  static recebedor = new RecebedorEndpoints();
  static localidade = new LocalidadeEndpoints();
  static pagarme = new Pagarme();
  static config = new Config();
  static openFinance = new OpenFinance();
}
