import styled from 'styled-components'

export const Content = styled.section`
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 1rem 0 0 0;
  }
`
