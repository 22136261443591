import styled from 'styled-components';
import { Breakpoints, Colors } from 'theme';
import { InfoOutlined, ReplyOutlined } from '@material-ui/icons';

export const Wrapper = styled.div` 
  padding: 1.2rem;
  @media (max-width: ${Breakpoints.maxSmall}) {  
    padding-bottom: 11rem;
  }
`

export const ContentWrapperInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content:flex-start;
  flex-direction: column;
`;

export const IconBack = styled(ReplyOutlined)`
  color: ${Colors.unicredGoldish};
  padding-right: 4px;
  border-radius: 50px;

  :hover {
    cursor: pointer;
    background-color: ${Colors.lighterGrey};
  }
`;

interface MobileProps {
  isMobile?: string;
}

export const ContentWrapper = styled.div<MobileProps>`
  @media (max-width: ${Breakpoints.maxSmall}) {  
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: space-between;

    & > :nth-child(1){
      flex-basis:100%;
      justify-content: space-between;
    }
    & > :nth-child(2),
    & > :nth-child(3){
      padding-top:10px;
      flex-basis:40%;
    }
  }
  width:${({isMobile}) => isMobile=="true" ? "100%" : "96%" } ;
  padding: ${({isMobile}) => isMobile=="true" ? "0%" : "2%" } ;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  background-color: ${({isMobile}) => isMobile=="true" ? "none" : "white" };
  justify-content: flex-start;
  gap: 4%;
`;

export const DateWrapper = styled.div` 
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const ContentTable = styled.div<MobileProps>`
  background-color: ${({isMobile}) => isMobile=="true" ? "none" : "white" };
  width: 100%;
  border-radius: 0px 0px 15px 15px ;
`;

export const ContentCalendar = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContentDateFilter = styled.div`
  display: flex;
  width: 100%;
  margin:0.4rem 0 0 0;
  justify-content: flex-start;
  gap: 1.5rem;
`;

export const ContentMobile = styled.div`
  display: flex;
  width: 100%;
  justify-content:flex-end;
  align-items:center;
  flex-direction: row;
`;

export const ChipWrapper = styled.div<MobileProps>`
  background-color: ${({isMobile}) => isMobile=="true" ? "none" : "white" };
  margin: ${({isMobile}) => isMobile=="true" ? "5% 0 4% 0" : "none" };
  padding-left: ${({isMobile}) => isMobile=="true" ? "none" : "2%" }; 
  width: 98%;
`;

export const ChipContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap:0.4rem;
`;

export const BlueCard = styled.div`
  padding-top: 1rem;
  display: flex;
  color: #48a9dd;
  justify-content: center;
  align-items:center;
  width:100%;
  height:4rem;
`;

export const BlueCardContent = styled.div`
  border-radius: 4px;
  display: flex;
  color: #48a9dd;
  justify-content: center;
  align-items:center;
  width: 94%;
  background-color: #e6f3fa;
  padding: .4rem 0;

  svg {
    margin-right: .5rem;
  }
`;

export const InfoIcon = styled(InfoOutlined)`
  color: #48a9dd;
  font-size: 18px;
`;