import { createErrorMessage } from "utils";
import { Action } from "store/interfaces";

import { StateEnum } from "shared/enums";
import { IConfigState, GetConfigSuccess } from "./interfaces";
import { Dispatch } from "redux";
import axios from "axios";
import { axiosInstance, Endpoints } from "api";

export enum ConfigTypes {
  CONFIG = "config/CONFIG",
  CONFIG_SUCCESS = "config/CONFIG_SUCCESS",
  CONFIG_FAILED = "config/CONFIG_FAILED",
  CONFIG_ERROR_HANDLED = "config/CONFIG_ERROR_HANDLED",
}

const initialState: IConfigState = {
  toggle_opf: "",
  toogle_rav: "",
  toggle_simulador: "",
  state: StateEnum.IDLE,
  error: null,
};

export function reducer(state = initialState, action: Action): IConfigState {
  switch (action.type) {
    case ConfigTypes.CONFIG:
      return { ...state, state: StateEnum.LOADING };
    case ConfigTypes.CONFIG_SUCCESS:
      return {
        ...state,
        toogle_rav: action.payload.toogle_rav,
        toggle_opf: action.payload.toggle_opf,
        toggle_simulador: action.payload.toggle_simulador,
        state: StateEnum.COMPLETE,
      };
    case ConfigTypes.CONFIG_FAILED:
      return {
        ...state,
        state: StateEnum.ERROR,
        error: action.payload.error,
      };
    case ConfigTypes.CONFIG_ERROR_HANDLED:
      return {
        ...state,
        state: StateEnum.IDLE,
        error: null,
      };
    default:
      return state;
  }
}

export class ConfigActions {
  static getConfigStarted(): Action {
    return { type: ConfigTypes.CONFIG };
  }

  static getConfigFailed(error: string): Action {
    return {
      type: ConfigTypes.CONFIG_FAILED,
      payload: { error },
    };
  }
  static getConfigSuccess(payload: GetConfigSuccess): Action {
    return {
      type: ConfigTypes.CONFIG_SUCCESS,
      payload,
    };
  }
  static getConfig(): any {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(ConfigActions.getConfigStarted());
        const { data } = await axiosInstance.get(Endpoints.config.config());
        dispatch(
          ConfigActions.getConfigSuccess({
            toogle_rav: data.toogle_rav,
            toggle_opf: data.toggle_opf,
            toggle_simulador: data.toggle_simulador
          })
        );
      } catch (err) {
        dispatch(ConfigActions.getConfigFailed(createErrorMessage(err)));
      }
    };
  }
}
