import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

const axiosInstance = axios.create({
  baseURL: '/api',
  timeout: 30000,
  withCredentials: true
});

axiosRetry(axiosInstance, {
  retries: 0,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error => {
    return isNetworkOrIdempotentRequestError(error) ||
      error?.response?.status === 422 ||
      error?.response?.status === 404
  }
});

export default axiosInstance;
