import isNumeric from 'validator/lib/isNumeric'

class FormatUtils {
  private constructor() {}

  static formatBRL(value: number) {
    let parsed: any = ''
    if (value || value === 0)
      parsed = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
    return parsed
  }

  static formatNumber(
    value: number | null,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
  ) {
    let parsed: any = ''
    if (value || value === 0) {
      parsed = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(value)
    }
    return parsed
  }

  static BRLToNumber(value: string) {
    const safeValue = value ? String(value) : ''
    let parsedNumber = 0
    if (safeValue.length)
      parsedNumber = parseFloat(
        safeValue
          .split('R$')
          .join('')
          .split('.')
          .join('')
          .replace(',', '.')
          .trim()
      )
    return parsedNumber
  }

  static stringWODiacritics(str: string) {
    return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
  }

  static normalizeString(str: string) {
    const val = String(str)
    return String(this.stringWODiacritics(val)).toLowerCase().trim()
  }

  static formatCelular(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
  }

  static formatCelularCountry(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '$1 ($2) $3-$4')
  }
  
  static formatCelularComplex(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')
    const parsedString = FormatUtils.extractNumbers(str);

    if (parsedString.length !== 11 && parsedString.length !== 13) return str
    return parsedString.length === 13
      ? FormatUtils.formatCelularCountry(str)
      : FormatUtils.formatCelular(str)
  }

  static formatCep(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str.replace(/(\d{5})(\d{3})/g, '$1-$2')
  }

  static formatCPF(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  }

  static formatCNPJ(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  }

  static formatDocument(str: string): string {
    if (typeof str !== 'string') throw new Error('invalid parameter')
    const parsedString = FormatUtils.extractNumbers(str)

    if (parsedString.length !== 11 && parsedString.length !== 14) return str
    return parsedString.length === 11
      ? FormatUtils.formatCPF(parsedString)
      : FormatUtils.formatCNPJ(parsedString)
  }

  static extractNumbers(str: string) {
    return str.replace(/[^\d]+/g, '')
  }
}

class ValidationUtils {
  private constructor() {}

  static isAlpha(str: string) {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return /^[A-ZÃÁÀÂÄÇÉÊËÍÏÕÓÔÖÚÜ]+$/i.test(str)
  }

  static hasOnlyAlpha(str: string): boolean {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str
      .split(' ')
      .every((word) => ValidationUtils.isAlpha(word) || word === '')
  }

  static hasOnlyNumeric(str: string): boolean {
    if (typeof str !== 'string') throw new Error('invalid parameter')

    return str.split(' ').every((word) => isNumeric(word) || word === '')
  }

  static validateCel(number: string) {
    const regExp = new RegExp('^[1-9]{2}9[6-9]{1}[0-9]{7}$')
    const parsedNumber = FormatUtils.extractNumbers(number)
    return regExp.test(parsedNumber)
  }

  static validateCPF(cpf: string) {
    const parsedCPF = FormatUtils.extractNumbers(cpf)
    if (parsedCPF === '') return false

    if (
      parsedCPF.length !== 11 ||
      parsedCPF === '00000000000' ||
      parsedCPF === '11111111111' ||
      parsedCPF === '22222222222' ||
      parsedCPF === '33333333333' ||
      parsedCPF === '44444444444' ||
      parsedCPF === '55555555555' ||
      parsedCPF === '66666666666' ||
      parsedCPF === '77777777777' ||
      parsedCPF === '88888888888' ||
      parsedCPF === '99999999999'
    )
      return false

    let add = 0
    let rev: number

    for (let i = 0; i < 9; i++) add += parseInt(parsedCPF.charAt(i)) * (10 - i)
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) rev = 0
    if (rev !== parseInt(parsedCPF.charAt(9))) return false
    add = 0

    for (let i = 0; i < 10; i++) add += parseInt(parsedCPF.charAt(i)) * (11 - i)
    rev = 11 - (add % 11)

    if (rev === 10 || rev === 11) rev = 0
    if (rev !== parseInt(parsedCPF.charAt(10))) return false

    return true
  }

  static validateCNPJ(cnpj: string) {
    const parsedCnpj = FormatUtils.extractNumbers(cnpj)

    if (parsedCnpj === '') return false

    if (parsedCnpj.length !== 14) return false

    if (
      parsedCnpj === '00000000000000' ||
      parsedCnpj === '11111111111111' ||
      parsedCnpj === '22222222222222' ||
      parsedCnpj === '33333333333333' ||
      parsedCnpj === '44444444444444' ||
      parsedCnpj === '55555555555555' ||
      parsedCnpj === '66666666666666' ||
      parsedCnpj === '77777777777777' ||
      parsedCnpj === '88888888888888' ||
      parsedCnpj === '99999999999999'
    )
      return false

    let tamanho = parsedCnpj.length - 2
    let numeros = parsedCnpj.substring(0, tamanho)
    let digitos = parsedCnpj.substring(tamanho)
    let soma: number = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--
      if (pos < 2) pos = 9
    }
    let resultado: number = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== parseInt(digitos.charAt(0))) return false

    tamanho = tamanho + 1
    numeros = parsedCnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--
      if (pos < 2) pos = 9
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== parseInt(digitos.charAt(1))) return false

    return true
  }
}

export default class StringUtils {
  static format = FormatUtils
  static validation = ValidationUtils
}
