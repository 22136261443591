import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import 'react-calendar/dist/Calendar.css';
import React, { useEffect, useState } from 'react';
import { CalendarioInputProps } from '../../interfaces';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { 
  ArrowDropMonth,
  ButtonElement,
  ButtonLabel,
  CalendarioLabel, 
  IconButtonStyled, 
  ModalStyled, 
  StyledCalendar, 
  StyledCalendarDiv 
} from './styles';
import { UdsCalendar } from 'components';

export default function CalendarioInput (props: CalendarioInputProps) {
  const [calendarView, setCalendarView] = useState<'month' | 'year'>('month');
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [month, setMonth] = useState<Date>(new Date());

  useEffect(() => {
    if(selectedDates.length === 2){
      props.onChange(selectedDates);
      setSelectedDates([])
      setShowCalendar(false);
    }
  }, [selectedDates])

  const handleDayClick = (date: Date) => {
    if(format(date, "dd/MM/yyyy") != format(month, "dd/MM/yyyy")){
      setMonth(date);
    }
    setSelectedDates([...selectedDates, date])
  };

  const handleMonthClick = (date: Date) => {
    setMonth(date);
    handleToggleView()
  };

  const handleToggleView = () => {
    setCalendarView((prevView) => (prevView === "month" ? "year" : "month"));
  };

  const handleTodayDate = () => {
    setMonth(new Date());
    setCalendarView("month")
    setSelectedDates([new Date(), new Date()])
    setShowCalendar(false);
  }

  return (
    <>
      <IconButtonStyled data-testid="imageIcon" onClick={() => {setShowCalendar(true);}} >
        <UdsCalendar/>
      </IconButtonStyled>

      <ModalStyled 
        disableEscapeKeyDown
        onClose={() => {setShowCalendar(false)}}
        open={showCalendar}
        disableCloseIcon
        noPadding
        PaperProps={{ style: { boxShadow: 'none', height:"230px" } }}
        >
        <StyledCalendarDiv >
          {showCalendar &&
            (
              <>
                <StyledCalendar
                  data-testId="Calendar"
                  onClickDay={handleDayClick}
                  onClickMonth={handleMonthClick}
                  view={calendarView}
                  navigationLabel={({date}: any) => {return <CalendarioLabel>{format(date, 'MMMM yyyy', {locale: ptBR})} <ArrowDropMonth rotated={(calendarView === "year").toString()}/> </CalendarioLabel> }}
                  prevLabel={<ArrowDownward/>}
                  nextLabel={<ArrowUpward/>}
                  onViewChange={({view}: any) => setCalendarView(['year', 'month'].includes(view) ? view : 'month')}
                  locale='pt-BR'
                  value={month}
                  selectRange

                />
                <ButtonLabel>
                  <ButtonElement onClick={handleTodayDate}>Hoje</ButtonElement>
                </ButtonLabel>

              </>
            )
            }
        </StyledCalendarDiv>
      </ModalStyled>
    </>
  );
};