import styled from "styled-components";
import { Breakpoints } from "theme";
import LogoUnicredPayHorizontal from "assets/logos/logo_unicredpay_horizontal_verde.svg";
import LogoUnicredHorizontal from "assets/logos/logo_uni_horizontal_verde.svg";

import { Img } from "components";

export const UnicredPayLogo = styled(Img).attrs((props) => ({
  src: LogoUnicredPayHorizontal,
}))`
  height: auto;
  width: 180px;
  margin: 1rem auto 2rem auto;

  @media (max-width: ${Breakpoints.maxSmall}) {
    width: 160px;
    margin-top: 1rem;
  }
`;

export const UnicredLogoMini = styled(Img).attrs((props) => ({
  src: LogoUnicredHorizontal,
}))`
  height: auto;
  width: 70px;
  margin: 1rem auto 1rem auto;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
  animation: Wrapper 0.5s forwards;
  padding: 1.5rem;

  @media (max-width: ${Breakpoints.maxSmall}) {
    padding-bottom: 10rem;
  }

  @keyframes Wrapper {
    to {
      opacity: 1;
      transform: initial;
    }
  }
`;
