import React, { useState, useEffect, useCallback } from "react";
import localForage from "localforage";

export function useLocalForage<T>(key: string, initialValue: T) {
    const [ dadoArmazenado, setDadoArmazenado ] = useState<T | null>(initialValue);
    const [ carregando, setCarregando ] = useState(true);
  
    useEffect(() => {
        (async function () {
            try {
                const value: T | null = await localForage.getItem(key);
                setDadoArmazenado(value == null ? initialValue : value);
                setCarregando(false);
            } catch ( e ) { }
        })();
    }, [])

    const editarDadoArmazenado = useCallback((value) => {
        async function set(value: any) {
            try {
                setDadoArmazenado(value);
                await localForage.setItem(key, value);
            } catch ( e) {}
        }

        set(value);
    }, [ key ])

    const removerDado = useCallback(() => {
        async function remove() {
            try {
                setDadoArmazenado(null);
                await localForage.removeItem(key);
            } catch ( e ) { }
        }
        remove();
    }, [ key ]);

    return [ dadoArmazenado, editarDadoArmazenado, removerDado, carregando ] as const;
}