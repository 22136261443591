import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Payment, Replay } from '@material-ui/icons'
import { useTypedSelector } from 'hooks'
import { TransacoesTable } from 'components'
import { PagamentoActions } from 'store/pagamento/pagamento'
import { isLoading } from 'utils'
import StringUtils from 'utils/StringUtils'
import { tooltipCancelamento, disabledCancelamento } from 'utils/cancelarPagamento'
import { DadosEstorno } from 'components/ModalEstorno/interfaces'
import ModalEstorno from 'components/ModalEstorno'
import Header from '../Header'
import { Wrapper } from './styles'

interface MainContentProps {
  linkId: string
}

interface IOptions {
  page: number;
  size: number;
  sort: string | null;
  orderBy: string;
  orderDirection: 'asc' | 'desc' | undefined
  linkId: string
}

const optionsStateInitial: IOptions = {
  page: 0,
  size: 5,
  sort: 'data,desc',
  orderBy: 'data',
  orderDirection: 'desc',
  linkId: ''
}

const dadosEstornoInitial: DadosEstorno = {
  pagamentoId: '',
  metodoId: ''
}

const MainContent = (props: MainContentProps) => {
  const { linkId } = props
  const [options, setOptions] = useState<IOptions>({ ...optionsStateInitial, linkId })
  const [openCancelamento, setOpenCancelamento] = useState(false);
  const [dadosEstorno, setDadosEstorno] = useState<DadosEstorno>({ ...dadosEstornoInitial });

  const dispatch = useDispatch()
  const pagamentoLink = useTypedSelector(
    (state) => state.pagamento.getPagamentoLink
  )

  const columns = useMemo(
    () => [
      'status',
      'data',
      'operacaoId',
      'nome',
      'documento',
      'formaPagamento',
      'numeroCartao',
      'valor',
      'valorCapturado',
    ],
    []
  )

  const tableData = pagamentoLink?.data?.dataTreeTable?.map((item: any) => {
    return { ...item }
  });

  const getPagamentos = useCallback(() => {
    dispatch(PagamentoActions.getPagamentoLink({ sort: options.sort, page: options.page, size: options.size, linkId }))
  }, [dispatch, options, linkId])

  useEffect(() => {
    getPagamentos();
  }, [getPagamentos])

  function onChangePage(page: number) {
    setOptions((prev) => ({ ...prev, page }))
  }

  function onChangeRowsPerPage(size: number) {
    setOptions((prev) => ({ ...prev, size }))
  }

  const onChangeOrder = (orderBy: number, orderDirection: string) => {
    const columnName = columns[orderBy]
    if (columnName) {
      const helpName = columnName === 'dataCriacao' ? 'createdDate' : columnName
      setOptions((prev: any) => ({
        ...prev,
        sort: `${helpName}, ${orderDirection}`,
        orderBy: helpName,
        orderDirection
      }))
    } else {
      setOptions((prev: any) => ({
        ...prev,
        sort: '',
        orderBy: '',
        orderDirection: undefined
      }))
    }
  }

  const getOrderBy = (field: string) => {
    const direction: 'asc' | 'desc' | undefined = options.orderBy === field ? options.orderDirection : undefined;
    return direction
  }

  const onCloseCancelamento = () => {
    setOpenCancelamento(false);
    setDadosEstorno({ ...dadosEstornoInitial });
    getPagamentos();
  }

  return (
    <Wrapper>
      <Header showIcon={true} Icon={<Payment />} title={'Pagamentos'} />
      <TransacoesTable
        data={tableData}
        title={'Pagamentos'}
        columns={[{ title: 'Status', field: 'status', defaultSort: getOrderBy('status') },
        { title: 'Data', field: 'data', defaultSort: getOrderBy('data') },
        { title: 'Operação', field: 'operacaoId', defaultSort: getOrderBy('operacaoId') },
        { title: 'Nome', field: 'nome', defaultSort: getOrderBy('nome') },
        { title: 'Documento', field: 'documento', defaultSort: getOrderBy('documento') },
        { title: 'Forma de Pagamento', field: 'formaPagamento', defaultSort: getOrderBy('formaPagamento') },
        { title: 'Número Cartão', field: 'numeroCartao', defaultSort: getOrderBy('numeroCartao') },
        { title: 'Valor', field: 'valor', defaultSort: getOrderBy('valor'), render: (rowData: any) => `${StringUtils.format.formatBRL(rowData.valor)}` },
        { title: 'Valor Capturado', field: 'valorCapturado', defaultSort: getOrderBy('valorCapturado'), render: (rowData: any) => `${StringUtils.format.formatBRL(rowData.valorCapturado)}` }]}
        showTitle={false}
        isLoading={isLoading(pagamentoLink.state)}
        onRowClick={() => null}
        toolbar={false}
        page={options.page}
        totalElements={pagamentoLink?.data?.totalElements}
        onChangePage={(page) => onChangePage(page)}
        onChangeRowsPerPage={(perPage) => onChangeRowsPerPage(perPage)}
        onChangeOrder={onChangeOrder}
        actions={[
          (rowData: any) => ({
            icon: () => <Replay />,
            tooltip: tooltipCancelamento(rowData),
            disabled: disabledCancelamento(rowData),
            onClick: (_: any, rowData: any) => {
              setOpenCancelamento(true);
              setDadosEstorno({
                pagamentoId: rowData.pagamentoId,
                metodoId: rowData.metodoId,
              })
            }
          })
        ]}
      />
      {openCancelamento && <ModalEstorno open={openCancelamento} dadosEstorno={dadosEstorno} onClose={onCloseCancelamento} />}
    </Wrapper>
  )
}

export default MainContent
