import React, { lazy, Suspense } from "react";
import { onboardingTableTaxasProps } from "../Interfaces";
import StringUtils from "utils/StringUtils";
import tableIcons from "shared/tableIcons";
import Loader from "components/CenteredLoader";
import { OnboardingMessages } from "shared/messages";
import { TitleTable } from "./styles";
const MaterialTable = lazy(() => import("@material-table/core"));

const options = {
  search: false,
  paging: false,
  showTitle: false,
  toolbar: false,
  headerStyle: {
    backgroundColor: "#018B6A",
    color: "#f8f9fa",
  },
};

const style = {
  marginBottom: "1.5rem",
};

type column = {
  [key: string]: any;
};

const formatNumber = (val: number, type: string) => {
  switch (type) {
    case "currency":
      return StringUtils.format.formatBRL(val);
    case "percent":
      return `${StringUtils.format.formatNumber(val)} %`;
    case "percent-per-month":
      return `${StringUtils.format.formatNumber(val)} % a.m.`;
    default:
      return "sem custo";
  }
};

const formatData = (data: any, tableType?: string) => {
  const formattedData = [...data];

  return formattedData.map((item: column) => {
    const formattedItem = { ...item };

    Object.entries(formattedItem).forEach(([key, val]) => {
      if (key !== "modalidade" && key !== "descricao") {
        formattedItem[key] = formatNumber(val, tableType || formattedItem.type);
      }
    });

    return formattedItem;
  });
};

export const TaxasTable = ({ taxas }: any) => {
  const { title, columns, data, type } = taxas;

  return (
    <div key={title}>
      <TitleTable>{title}</TitleTable>
      <Suspense fallback={<Loader />}>
        <MaterialTable
          columns={columns}
          data={formatData(data, type)}
          options={options}
          style={style}
          icons={tableIcons}
          localization={{
            body: {
              emptyDataSourceMessage: OnboardingMessages.semDadosTabela,
            },
          }}
        />
      </Suspense>
    </div>
  );
};

export default React.memo(function OnboardingTaxasTable(
  props: onboardingTableTaxasProps
) {
  const { taxas } = props;

  return (
    <>
      {taxas?.map((txs: any) => (
        <TaxasTable taxas={txs} key={txs.title} />
      ))}
    </>
  );
});
