import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  WrapperContainer,
  CardPainel,
  ContainerResposta,
  Resposta,
  ContainerLike,
  ContainerItemLike,
  IconLike,
  IconDeslike,
  ContainerButtonsLike,
  TituloLike,
  IconButtonContainerLike,
  IconButtonContainerDeslike,
  ContentOverlay,
} from "./styles";

import { useRedirect } from "hooks";
import { AjudaRoutesPaths } from "pages/Ajuda/Ajuda";

export interface IPainelrops {
  questao: {
    id: string;
    pergunta: string;
    resposta: string;
    order?: number;
    idCategoria: string;
    descricaoCategoria: string;
    like?: {
      like: boolean;
      recebedor_id: string;
    };
  };
  onChangeLike: (like: boolean, questaoId: string) => void;
  loading?: boolean;
  error?: boolean;
  msgError?: string;
  categoriaId?: string;
}

const Painel: React.FC<IPainelrops> = (props: IPainelrops) => {
  const {
    questao,
    onChangeLike,
    loading = false,
    error = false,
    msgError = "",
    categoriaId = "",
  } = props;
  const redirect = useRedirect();

  const like = questao?.like && questao.like.like ? 1 : 0;
  const deslike = questao?.like && !questao.like.like ? 1 : 0;
  const disabled = like === 1 || deslike === 1;

  const goBack = () => {
    let url = "";
    if (questao.idCategoria || categoriaId) {
      const categoria = questao.idCategoria || categoriaId;
      url = `${AjudaRoutesPaths.base}/detalhes/${categoria}`;
    } else {
      url = AjudaRoutesPaths.base;
    }
    redirect(url);
  };

  return (
    <WrapperContainer id="wrapper_container_questao">
      <CardPainel>
        <ContainerResposta id="painel_resposta">
          <Resposta id="resposta">
            {questao?.resposta?.split(`\n`).map((resposta, idx) => (
              <div dangerouslySetInnerHTML={{ __html: resposta }} key={idx}>
              </div>
            ))}
          </Resposta>
        </ContainerResposta>
        {loading && (
          <ContentOverlay>
            <CircularProgress />
          </ContentOverlay>
        )}
        {error && (
          <ContentOverlay>
            <Alert severity="error">{msgError}</Alert>
          </ContentOverlay>
        )}
      </CardPainel>
      <ContainerLike id="container_btn_like_deslike">
        {!error && (
          <ContainerItemLike id="container_item_btn_like_deslike">
            <TituloLike id="container_btn_like_deslike_titulo">
              A informação foi útil ?
            </TituloLike>
            <ContainerButtonsLike id="container_btn_like">
              <IconButtonContainerLike
                isactive={like}
                disabled={disabled}
                id="container_btn_icon_like"
                onClick={() => onChangeLike(true, questao.id)}
              >
                <IconLike />
              </IconButtonContainerLike>
              <IconButtonContainerDeslike
                isactive={deslike}
                disabled={disabled}
                id="container_btn_icon_deslike"
                onClick={() => onChangeLike(false, questao.id)}
              >
                <IconDeslike />
              </IconButtonContainerDeslike>
            </ContainerButtonsLike>
          </ContainerItemLike>
        )}
        {loading && (
          <ContentOverlay>
            <CircularProgress />
          </ContentOverlay>
        )}
      </ContainerLike>
    </WrapperContainer>
  );
};

export default Painel;
