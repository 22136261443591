import React from 'react'
import copy from 'copy-text-to-clipboard'
import { Assignment, GridOn, AttachMoney, CalendarTodayOutlined } from '@material-ui/icons'
import { Divider, Grid } from '@material-ui/core'
import InfoCard from './InfoCard'
import { Launch, Link as LinkIcon } from '@material-ui/icons'
import { InputV2 as Input } from 'components'

import {
  Wrapper,
  WrapperDetalhes,
  WrapperProduto,
  CardProduto,
  CardProdutoContentRow,
  CardProdutoTitle,
  CardProdutoSubTitle,
  AbrirLink,
  CopiarLink,
  LinkWrapper,
  DetalheCardWrapper,
  DetalheCard,
  CardValidade,
  SalvarValidade,
} from './styles'
import { Item } from 'store/pagamento/interfaces'
import StringUtils from 'utils/StringUtils'
import Header from '../Header'
import { useTypedSelector } from 'hooks'
import { isDataValida, validadeParaData } from 'utils/validadeLink'
import { StateEnum } from 'shared/enums'
import ModalRetornoEdicao from './InfoCard/ModalRetornoEdicao'

interface SideInfoProps {
  dataValidade: string;
  salvarNovaDataValidade: () => void;
  editarDataValidade: (novaData: string) => void;
}
export default function SideInfo({dataValidade, editarDataValidade, salvarNovaDataValidade}: SideInfoProps) {
  const link = useTypedSelector((state) => state.linkPagamento.link);
  
  const editarNovaData = (dataValue: string) => {
    editarDataValidade(dataValue);
  }

  const isDataIgual = validadeParaData(link.data?.validade, link?.data?.createdDate) === dataValidade;

  const LinkContent = () => (
    <LinkWrapper>
      <AbrirLink href={link?.data?.link} target="_blank">
        <Grid container direction="row" alignItems="center">
          Abrir
          <Launch />
        </Grid>
      </AbrirLink>
      <CopiarLink onClick={() => copy(link?.data?.link)} as="button">
        <Grid container direction="row" alignItems="center">
          Copiar
          <LinkIcon />
        </Grid>
      </CopiarLink>
    </LinkWrapper>
  )

  return (
    <Wrapper>
      <Header showIcon={true} Icon={<Assignment />} title={'Detalhes'} />
      <WrapperDetalhes>
        <DetalheCardWrapper container spacing={10} direction="row">
          <DetalheCard item xs={12} sm={6}>
            <InfoCard titulo="valor total" valor={link?.data?.total} cifra={true} casasDecimais={2} />
          </DetalheCard>
          <DetalheCard item xs={12} sm={6}>
            <InfoCard titulo="link" content={<LinkContent />} />
          </DetalheCard>
        </DetalheCardWrapper>
      </WrapperDetalhes>
      <Header showIcon={true} Icon={<AttachMoney />} title={'Formas de pagamento'} />
      <WrapperDetalhes>
        <DetalheCardWrapper container spacing={10} direction="row">
          {link?.data?.formaPagamento?.cartao && (
            <DetalheCard item xs={12} sm={6}>
              <InfoCard titulo="cartão de crédito" valor={link?.data?.formaPagamento?.cartao.quantidadeMaximaParcelas} parcelamento={true} />
            </DetalheCard>
          )}
          {link?.data?.formaPagamento?.pix && (
            <DetalheCard item xs={12} sm={6}>
              <InfoCard titulo="pix" valor={'1'} parcelamento={true} />
            </DetalheCard>
          )}
          {link?.data?.formaPagamento?.boleto && (
            <DetalheCard item xs={12} sm={6}>
              <InfoCard titulo="boleto" valor={'1'} parcelamento={true} />
            </DetalheCard>
          )}
        </DetalheCardWrapper>
      </WrapperDetalhes>
      <Header showIcon Icon={<CalendarTodayOutlined />} title="Validade do link"/>

      <WrapperDetalhes>
        <DetalheCardWrapper container spacing={10} direction={"row"} alignItems='flex-end' justify='space-between'>
          <DetalheCard item xs={12} sm={6}>
            <CardValidade >
            <Input 
              id="detalhesLink_data_validade"
              label="Este link irá expirar em"
              value={dataValidade}
              name="validade_link"
              onChange={(input) => editarNovaData(input.target.value)}
              type="date"
              error={!isDataValida(dataValidade)}
              errorMsg="A data informada precisa ser maior do que data atual."
              />
            </CardValidade>
          </DetalheCard>
          <SalvarValidade
            disabled={StateEnum.LOADING === link.editState || isDataIgual || !isDataValida(dataValidade)}
            loading={StateEnum.LOADING === link.editState}
            onClick={salvarNovaDataValidade}
          >
            SALVAR
          </SalvarValidade>
        </DetalheCardWrapper>
      </WrapperDetalhes>
      <Header showIcon={true} Icon={<GridOn />} title={'Produtos'} />
      <WrapperProduto>
        {link?.data?.itens?.map((item: Item, key: number) => (
          <CardProduto key={`${key}-${item.descricao}`}>
            <CardProdutoTitle>
              <strong>{item.descricao}</strong>
            </CardProdutoTitle>
            <Divider />
            <CardProdutoContentRow>
              <CardProdutoSubTitle>Quantidade</CardProdutoSubTitle>
              <CardProdutoSubTitle>{item.quantidade}</CardProdutoSubTitle>
            </CardProdutoContentRow>

            <CardProdutoContentRow>
              <CardProdutoSubTitle>Valor</CardProdutoSubTitle>
              <CardProdutoSubTitle>
                {StringUtils.format.formatBRL(item.valor)}
              </CardProdutoSubTitle>
            </CardProdutoContentRow>

            <CardProdutoContentRow>
              <CardProdutoSubTitle>Valor Acumulado</CardProdutoSubTitle>
              <CardProdutoSubTitle>
                {StringUtils.format.formatBRL(item.valorAcumulado)}
              </CardProdutoSubTitle>
            </CardProdutoContentRow>
          </CardProduto>
        ))}
      </WrapperProduto>
      <ModalRetornoEdicao/>
    </Wrapper>
  )
}
