import React from 'react'
import {
  Wrapper,
  Title,
  TitleWrapper,
  ChartIcone,
  HeaderDivider,
  IconContent,
} from './styles'

interface HeaderProps {
  showIcon: Boolean
  title: string
  Icon?: any
}

export default function Header(props: HeaderProps) {
  const { title, Icon } = props
  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <IconContent>{Icon}</IconContent>
          <ChartIcone />
          <Title> {title} </Title>
        </TitleWrapper>
      </Wrapper>
      <HeaderDivider />
    </>
  )
}
