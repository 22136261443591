import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useRedirect } from 'hooks'
import { UiActions } from 'store/ui'
import { Wrapper } from './styles'

interface NavItemProps {
    to: string;
    children: any;
}

const NavItem = ({ to, children }: NavItemProps) => {
    const dispatch = useDispatch()
    const redirect = useRedirect()
    const location = useLocation()

    const handleClick = (to: string) => {
        dispatch(UiActions.hideDrawer())
        redirect(to)
    }

    const onPath = (to: string, path: string) : boolean => {
        return (to !== '/' && path.includes(to)) || to === path
    }

    return (
        <Wrapper button onClick={() => handleClick(to)} selected={onPath(to, location.pathname)}>
            {children}
        </Wrapper>
    )
}

export default NavItem;