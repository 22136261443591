import { createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import { StateEnum } from 'shared/enums';
import { BaseState, IGenericError } from './interfaces'

const actions = {
    'started': 'Started',
    'success': 'Success',
    'failed': 'Failed',
    'handled': 'ErrorHandled',
}

const createGenericSlice = <
    State extends BaseState,
    Reducers extends SliceCaseReducers<State>
>({
    name = '',
    initialState,
    reducers
}: {
    name: string
    initialState: State
    reducers: ValidateSliceCaseReducers<State, Reducers>
}) => {    
    const addReducer = (
        reducers: ValidateSliceCaseReducers<State, Reducers>,
        key: keyof typeof reducers,
        reducerName: string,
        value: any
    ) => {
        Object.defineProperty(reducers, key, { enumerable: true, value });
    }

    const getKey = (reducerName: string, action: string): keyof typeof reducers => {
        return `${reducerName}${action}`
    }

    Object.keys(reducers).forEach(key => {
        if (key.endsWith(actions.success)) {
            const reducerName = key.replace(actions.success, '')

            // Started
            addReducer(reducers, getKey(reducerName, actions.started), reducerName, (state: State) => {
                state[reducerName].state = StateEnum.LOADING
            })

            // Failed
            addReducer(reducers, getKey(reducerName, actions.failed), reducerName, (state: State, action: PayloadAction<IGenericError>) => {
                state[reducerName].state = StateEnum.ERROR
                state[reducerName].error = action.payload?.error
            })

            // Error Handled
            addReducer(reducers, getKey(reducerName, actions.handled), reducerName, (state: State) => {
                state[reducerName].state = StateEnum.IDLE
            })
        }
    })

    return createSlice({
        name,
        initialState,
        reducers: {
            ...reducers
        }
    })
}

export default createGenericSlice