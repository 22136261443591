import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import OnboardingModal from './OnboardingModal'
import { useMount, useTypedSelector } from 'hooks'
import { CooperativaTaxasActions } from 'store/cooperativaTaxas'
import { RecebedorActions } from 'store/recebedor'
import { Button, Modal } from 'components'
import { Content, Header, InfoIcon } from './styles'
import { TITLE_MODAL_SUCESS, MESSAGE_MODAL_SUCESS } from './Constantes'
import { hasError } from 'utils'

export default function OnBoarding() {
  const isRecebedor = useTypedSelector((state) => state.auth.isRecebedor)
  const plataforma  = useTypedSelector((state) => state.auth.plataforma)

  const stateTaxasCooperativas = useTypedSelector(
    (state) => state.cooperativaTaxas
  )
  const stateRecebedor = useTypedSelector((state) => state.recebedor)

  const dispatch = useDispatch()
  const [checkAccept, setCheckAccept] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)

  const getOnboardingData = () => {
    dispatch(CooperativaTaxasActions.getTaxas({ formatTable: true }))
    dispatch(CooperativaTaxasActions.getTermo())
    dispatch(RecebedorActions.getTarifasBoleto())
  }

  useMount(() => {
    if (!isRecebedor) {
      getOnboardingData()
    }    
  })

  useEffect(() => {
    if (
      hasError(stateRecebedor.novoRecebedor.state) ||
      hasError(stateTaxasCooperativas.getCooperativaTaxas.state) ||
      hasError(stateTaxasCooperativas.getTermo.state) || 
      hasError(stateRecebedor.getTarifasBoleto.state)
    ) {
      setCheckAccept(false)
    }
  }, [stateRecebedor, stateTaxasCooperativas, checkAccept])

  const acceptTerm = async () => {
    dispatch(RecebedorActions.novoRecebedor())
    setOpenModalSucess(!openModalSucess)
  }
  const checkAcceptTerm = () => setCheckAccept(!checkAccept)
  const tryAgain = () => {
    setCheckAccept(false)
    getOnboardingData()
    dispatch(RecebedorActions.resetRecebedor())
  }

  return (
    <>
      {isRecebedor && openModalSucess ? (
        <Modal
          disableBackdropClick
          disableEscapeKeyDown
          disableCloseIcon
          onClose={() => null}
          open={openModalSucess}
          noPadding
          id="termo_aceite_modal_sucess"
        >
          <Header>
            <InfoIcon />
            <h1>{TITLE_MODAL_SUCESS}</h1>
          </Header>
          <Content>
            <p>
              Sr(a). {stateRecebedor.recebedor.nome},{' '}
              {MESSAGE_MODAL_SUCESS}
            </p>
            <Button
              onClick={() => setOpenModalSucess(!openModalSucess)}
              fullWidth
            >
              Entendi
            </Button>
          </Content>
        </Modal>
      ) : null}
      {!isRecebedor ? (
        <OnboardingModal
          isOpen={isRecebedor}
          recebedor={stateRecebedor}
          taxasCooperativas={stateTaxasCooperativas}
          acceptTerm={acceptTerm}
          checkAcceptTerm={checkAcceptTerm}
          tryAgain={tryAgain}
          checkAccept={checkAccept}
          plataforma={plataforma}
        />
      ) : null}
    </>
  )
}
