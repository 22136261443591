import React from 'react'
import { Button } from 'components'

import { Header, Content, InfoIcon } from './styles'
import { DialogActions, DialogTitle } from '@material-ui/core'

import { SucessModal } from '../Interfaces'

export default function Sucess(props: SucessModal) {
  const { onClose, title, message } = props

  return (
    <>
      <Header>
        <InfoIcon />
        <DialogTitle>{title}</DialogTitle>
      </Header>
      <Content>
        <p>{message}</p>
      </Content>
      <DialogActions>
        <Button onClick={() => onClose()} fullWidth>
          Entendi
        </Button>
      </DialogActions>
    </>
  )
}
