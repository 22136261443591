import Button from 'components/Button';
import styled from 'styled-components';
import { Colors, Breakpoints } from 'theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoints.maxSmall}) {
    min-height: 100vh;
  }

  svg {
    font-size: 3em;
    color: ${Colors.danger};
  }
`;

export const Title = styled.h3`
  color: ${Colors.danger};
  margin: 0.6rem 0;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
`;

export const EntendiButton = styled(Button)`
  margin: 0 0 0.5rem auto;

  @media (max-width: ${Breakpoints.maxSmall}) {
    font-size: 0.8rem;
  }
`