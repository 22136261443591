import React from 'react';
import NumberFormatTP, { NumberFormatValues }  from 'react-number-format';

interface NumberFormatProps {
  inputRef: (instance: NumberFormatTP | null) => void;
  onChange: (event: {
    target: { value: string; name?: string | null ;};
    persist: any;
  }) => void;
  name?: string | null;
  value?: number | string;
  disabled?: boolean;
}

export function TaxesFormat(props:NumberFormatProps){
  const {inputRef, onChange, name, ...other } = props;

  return(
    <NumberFormatTP
    {...other}
    getInputRef={inputRef}
    maxLength={8}
    onValueChange={(values)=>{
      onChange({
        persist: ()=> null,
        target: {
          value: values.formattedValue,
          name,
        }
      })
    }}
    decimalScale={2}
    decimalSeparator='.'
    allowNegative={false}
    
    />
  )
}

export function NumberFormat(props: NumberFormatProps) {
  const { inputRef, onChange, name, ...other } = props;

  const handleChangeValue = (value: string) => onChange({
    persist: () => null,
    target: {
      value,
      name
    }
  })

  const cleanCharacteresAndZerosBeforeNumber = (value: string) => {
    return value.replace('R$ ', '').replace('.', '').replace(',', '').replace(/^0*/, '');
  }

  const formatNumberToReal = (value: string) => {
    return `${value.slice(0, -2)},${value.slice(-2)}`
  }

  const handleValueChange = (event: NumberFormatValues) => {
    const value = cleanCharacteresAndZerosBeforeNumber(event.formattedValue);

    if (value.length === 0) {
      return handleChangeValue('');
    }

    if (value.length === 1) {
      return handleChangeValue(`0,0${value}`);
    }

    if (value.length === 2) {
      return handleChangeValue(`0,${value}`);
    }

    handleChangeValue(formatNumberToReal(value));
  }

  return (
    <NumberFormatTP
      {...other}
      getInputRef={inputRef}
      maxLength={13}
      onValueChange={handleValueChange}
      thousandSeparator='.'
      decimalSeparator=','
      prefix='R$ '
      allowNegative={false}
      decimalScale={other.disabled ? 2 : undefined}
    />
  );
}
