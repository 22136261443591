import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AttachMoney from "@material-ui/icons/AttachMoney";
import { RecebimentoActions } from "store/recebimento";
import { useRedirect, useTypedSelector } from "hooks";
import { hasError, isLoading } from "utils";
import StringUtils from "utils/StringUtils";
import { RecebimentosRoutesPaths } from "../Recebimentos";
import Header from "./Header";
import { PageWrapper } from "shared/styles";
import { ContentWrapperInfo } from "./styles";
import { TransacoesTable } from "components";

const optionsStateInitial = {
  page: 0,
  size: 5,
  sort: "createdDate,desc",
};

export default function RecebimentoList() {
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const auth = useTypedSelector((state) => state.auth);
  const recebedorId = auth.recebedor.id || "";

  const recebimentos = useTypedSelector(
    (state) => state.recebimento.recebimentos
  );
  const [options, setOptions] = useState(optionsStateInitial);

  const tableData = recebimentos?.data?.extratos?.map((item: any) => {
    return { ...item };
  });

  useEffect(() => {
    dispatch(
      RecebimentoActions.getAllRecebimentos({ id: recebedorId }, options)
    );
  }, [dispatch, recebedorId, options]);

  useEffect(() => {
    if (hasError(recebimentos.state)) {
      dispatch(RecebimentoActions.resetAllRecebimento());
    }
  }, [recebimentos.state, dispatch]);

  return (
    <PageWrapper>
      <ContentWrapperInfo>
        <Header icon={<AttachMoney />} title="Saques" />
          <TransacoesTable
            title={"Saques"}
            toolbar={false}
            columns={[
              { title: "ID do Saque", field: "saqueId", width: "100" },
              {
                title: "Valor do Saldo",
                field: "valorSaldo",
                render: (row: any) =>
                  StringUtils.format.formatBRL(row.valorSaldo),
              },
              {
                title: "Valor Tarifa",
                field: "valorTarifa",
                render: (row: any) =>
                  StringUtils.format.formatBRL(row.valorTarifa),
              },
              {
                title: "Valor Líquido",
                field: "valorLiquido",
                render: (row: any) =>
                  StringUtils.format.formatBRL(row.valorLiquido),
              },
              { title: "Data de Solicitação", field: "dataSolicitacao" },
              { title: "Data do crédito em conta", field: "dataCredito" },
            ]}
            data={tableData}
            isLoading={isLoading(recebimentos.state)}
            onRowClick={(_, rowData: any) =>
              redirect(`${RecebimentosRoutesPaths.detalhes}/${rowData.id}`)
            }
            page={options.page}
            totalElements={recebimentos.data.totalElements}
            onChangePage={(page, pageSize) =>
              setOptions((prev) => ({ ...prev, page: page, size: pageSize }))
            }
          />
      </ContentWrapperInfo>
    </PageWrapper>
  );
}
